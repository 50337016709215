import * as React from 'react'
import {SafeTrans, useSafeTranslation} from '@freckle/student-materials/src/helpers/translate'
import Modal from 'react-bootstrap/Modal'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalBody from 'react-bootstrap/ModalBody'
import Button from '@freckle/student-materials/src/components/button'
import {Container} from '@freckle/student-materials/src/components/container'
import {H2} from '@freckle/student-materials/src/components/typography'
import {PATHS} from '@freckle/student-materials/src/helpers/paths'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import {faCheck} from '@fortawesome/free-solid-svg-icons'

import {
  headerStyle,
  plus,
  wrapper,
  btnWrapper,
  logo,
  modalHeader
} from './star-integration-linked-modal.module.scss'

type Props = {
  show: boolean
  onHideModal: () => void
}

export function StarIntegrationLinkedModal(props: Props): React.ReactElement {
  const {show, onHideModal} = props
  const {t} = useSafeTranslation()

  const freckleLogo = 'company-logos/freckle-by-renaissance/colored_jt.png'
  const starLogo = 'student-web/star-integration/star-assessments-logo.svg'

  return (
    <Modal bsSize="sm" show={show} onHide={onHideModal}>
      {/* @ts-ignore bsClass isn't in the TS types... */}
      <ModalHeader bsClass={`modal-header ${modalHeader}`} closeButton>
        <H2 addClass={headerStyle}>{t('STAR_INTEGRATION_LINKED_MODAL_HEADER')}</H2>
      </ModalHeader>
      <ModalBody>
        <Container hideSlice>
          <div className={wrapper}>
            <img
              src={`${PATHS.imageAssetsUrl}/${freckleLogo}`}
              alt={t('STAR_INTEGRATION_LINKED_MODAL_FRECKLE_IMAGE_ALT')}
              title={t('STAR_INTEGRATION_LINKED_MODAL_FRECKLE_IMAGE_ALT')}
              className={logo}
            />
            <FontAwesomeIcon icon={faPlus} className={plus} />
            <img
              src={`${PATHS.imageAssetsUrl}/${starLogo}`}
              alt={t('STAR_INTEGRATION_LINKED_MODAL_STAR_MATH_IMAGE_ALT')}
              title={t('STAR_INTEGRATION_LINKED_MODAL_STAR_MATH_IMAGE_ALT')}
              className={logo}
            />
          </div>
        </Container>
        <div className={btnWrapper}>
          <Button onClick={onHideModal}>
            <SafeTrans
              i18nKey="STAR_INTEGRATION_LINKED_MODAL_BUTTON_TEXT"
              components={{checkIcon: <FontAwesomeIcon icon={faCheck} />}}
            />
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}
