import reduce from 'lodash/reduce'

import {type AnalyticsTool, type AnalyticsEvent} from './types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let gtag: (a: any, b: any, c?: any) => void

export type CustomDimensionKey =
  | 'userId'
  | 'rosteredGradeLevel'
  | 'mathStandardSetId'
  | 'domainName'
  | 'standardName'
  | 'numIncorrectAttempts'
  | 'questionStemId'
  | 'step'
  | 'answer'
  | 'questionId'
  // Not setup in GA dashboard (we dont care to look this data in GA)
  | 'workedExampleId'
  | 'skillId'
  | 'standardSetId'
  | 'textSequencingSplit'
  | 'stemId'
  | 'session_id'
  | 'skill_id'
  | 'split_tutorials'
  | 'student_proficiency'

// Data for a custom dimension needs to consistently be sent to the right index
// Adding a new dimension requires some setup in GA
const CustomDimensionsMap = new Map<CustomDimensionKey, string>([
  ['userId', 'dimension1'],
  ['rosteredGradeLevel', 'dimension2'],
  ['mathStandardSetId', 'dimension3'],
  ['domainName', 'dimension4'],
  ['standardName', 'dimension5'],
  ['numIncorrectAttempts', 'dimension6'],
  ['questionStemId', 'dimension7'],
  ['questionId', 'dimension8'],
  ['step', 'dimension9'],
  ['answer', 'dimension10'],
  // IGNORED custom dimensions (these are not really setup in GA
  // because we dont care to look this data in GA)
  ['workedExampleId', 'IGNORED_FOR_GA'],
  ['skillId', 'IGNORED_FOR_GA'],
  ['standardSetId', 'IGNORED_FOR_GA'],
  ['textSequencingSplit', 'IGNORED_FOR_GA'],
  ['stemId', 'IGNORED_FOR_GA'],
  ['session_id', 'IGNORED_FOR_GA'],
  ['skill_id', 'IGNORED_FOR_GA'],
  ['split_tutorials', 'IGNORED_FOR_GA'],
  ['student_proficiency', 'IGNORED_FOR_GA']
])

export const GoogleAnalyticsInternal: AnalyticsTool = {
  setup: (userId: number): Promise<void> => {
    const strUserId = userId.toString()
    // set user id for GA
    // set user id custom dimension for GA
    gtag('config', 'G-4SRCG75GC6', {
      user_id: strUserId,
      dimension1: strUserId
    })
    // Send the userId to Google Tag Manager
    /* eslint-disable @typescript-eslint/no-explicit-any */
    if (
      (window as any).dataLayer !== undefined &&
      (window as any).dataLayer !== null &&
      Array.isArray((window as any).dataLayer)
    ) {
      ;(window as any).dataLayer.push({userId: strUserId})
    }
    /* eslint-enable */
    return Promise.resolve(undefined)
  },

  trackEvent: (event: AnalyticsEvent): Promise<void> => {
    const commonProperties = {
      hitType: 'event',
      eventCategory: 'Product'
    }

    const metadata = buildDimensions('metadata' in event ? event.metadata : {})
    gtag('event', event.tag, {
      ...commonProperties,
      ...metadata
    })

    return Promise.resolve(undefined)
  },

  setProperties: (
    _userId: number,
    mCustomProperties?: Partial<{[k in CustomDimensionKey]: string | number}>
  ): Promise<void> => {
    const properties = mCustomProperties ?? {}
    const dimensions = buildDimensions(properties)
    gtag('config', 'G-4SRCG75GC6', dimensions)
    return Promise.resolve(undefined)
  },

  trackRouteChange: (fragment: string): Promise<void> => {
    try {
      const cleansedFragment = cleanseFragment(fragment)
      gtag('event', 'page_view', {
        page_location: cleansedFragment
      })

      return Promise.resolve(undefined)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  logout: (): Promise<void> => {
    return Promise.resolve(undefined)
  }
}

function cleanseFragment(gaFragment: string): string {
  let cleansedFragment = gaFragment

  // Removing course id, assessment id or student id to gather information in GA
  const regex = /\/(courses|students|assessments|assessment|lessons|usage|reports|schools)\/\d+\/?/
  if (regex.test(cleansedFragment)) {
    cleansedFragment = cleansedFragment.replace(regex, '/$1/id/')
  }

  // Remove standard id and standard code in standard and sub standard Page
  // eg /standards/foo
  // eg /standards/foo/standard/K.CC.1
  const regexStandardSubPage = /\/(standards|standard)\/[a-zA-Z0-9.]+/
  if (regexStandardSubPage.test(cleansedFragment)) {
    cleansedFragment = cleansedFragment.replace(regexStandardSubPage, '/$1/standardId')
  }

  // Remove articleUuid
  const regexArticleUuid = /\/(articles|social-studies-units|science-units)\/[a-zA-Z0-9-]+/
  if (regexArticleUuid.test(cleansedFragment)) {
    cleansedFragment = cleansedFragment.replace(regexArticleUuid, '/$1/articleUuid')
  }

  //Remove hashes
  const hashlessFragment = cleansedFragment.replace(/#/g, '')

  return hashlessFragment
}

export const buildDimensions = (
  mCustomProperties?: Partial<{[k in CustomDimensionKey]: string | number | null | boolean}>
): Record<string, string | number | boolean> => {
  const properties = mCustomProperties ?? {}
  const dimensions = reduce(
    Array.from(CustomDimensionsMap.keys()),
    (acc: {[key: string]: string | number | boolean}, key) => {
      const dimensionName = CustomDimensionsMap.get(key)
      const dimensionValue = properties[key]
      if (
        dimensionValue !== null &&
        dimensionValue !== undefined &&
        dimensionName !== null &&
        dimensionName !== undefined
      ) {
        acc[dimensionName] = dimensionValue
      }

      return acc
    },
    {}
  )
  return dimensions
}
