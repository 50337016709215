import * as React from 'react'

import {type ResponsiveBreakpointKey} from './../helpers/responsive-helper'
import {useResponsiveInfo} from './../hooks/use-responsive-info'

type Props = {
  children: React.ReactNode
}

/**
 * The DEFAULT_BREAKPOINT is only used when a component does not have a matching
 * Provider above it in the tree. This can be helpful for testing components in isolation
 * without wrapping them.
 */

const DEFAULT_BREAKPOINT: ResponsiveBreakpointKey = 'desktop'

export const BreakpointContext = React.createContext<ResponsiveBreakpointKey>(DEFAULT_BREAKPOINT)
BreakpointContext.displayName = 'BreakpointContext'

export function BreakpointProvider(props: Props): React.ReactElement<Props> {
  const breakpoint = useResponsiveInfo()

  return (
    <BreakpointContext.Provider value={breakpoint}>{props.children}</BreakpointContext.Provider>
  )
}
