import {type Client} from '@bugsnag/js'
import {mmap} from '@freckle/maybe'
import BugsnagHelper, {
  type ErrorContext
} from '@freckle/student-entities/ts/common/helpers/exception-handlers/bugsnag-helper'
import {maybeResourceData} from '@freckle/resource-status'
import {type StoreState} from '@freckle/student/ts/reducers/types'

let _getState: null | (() => StoreState) = null

export function setGetState(getState: () => StoreState) {
  _getState = getState
}

const bugsnagClient: Client = BugsnagHelper.init((): ErrorContext => {
  if (_getState !== null) {
    const {selfStudent, currentCourse, currentTeacher} = _getState()
    // Student may have not yet loaded prior to crashing condition
    const user = mmap(
      ({id, mathStandardSet, elaStandardSet: mElaStandardSet}) => ({
        id,
        mathStandardSetId: mathStandardSet.id,
        elaStandardSetId: mmap(({id: elaStandardSetId}) => elaStandardSetId, mElaStandardSet)
      }),
      maybeResourceData(selfStudent)
    )
    const course = mmap(
      ({id, name, teacherId, code}) => ({id, name, teacherId, code}),
      maybeResourceData(currentCourse)
    )
    const teacher = mmap(
      ({id, schoolId, mathStandardSetId, elaStandardSetId}) => ({
        id,
        schoolId,
        mathStandardSetId,
        elaStandardSetId
      }),
      maybeResourceData(currentTeacher)
    )

    return {user, course, teacher}
  }
  return {user: null, course: null, teacher: null}
})

export function logError(err: Error) {
  BugsnagHelper.logError(err)
}

export function logErrorAsUnhandled(err: Error) {
  BugsnagHelper.logErrorAsUnhandled(err)
}

export default bugsnagClient
