import identity from 'lodash/identity'
import partial from 'lodash/partial'
import moment, {type Moment} from 'moment-timezone'
import {PATHS} from '@freckle/student-materials/src/helpers/paths'
import CApiHelper from '@freckle/student-entities/ts/common/helpers/common-api-helper'
import {type TeacherAttrs} from '@freckle/student-entities/ts/users/models/teacher'
import {ajaxJsonCall, ajaxCall, sendBeacon} from '@freckle/ajax'
import {
  ajaxSettingsWithEcho,
  type EchoError
} from '@freckle/student-entities/ts/common/helpers/api-helper'
import {ContentAreas} from '@freckle/student-entities/ts/common/helpers/content-area'
import {Products} from '@freckle/student-entities/ts/common/helpers/products'
import {type WritingSelectedAnswerT} from '@freckle/student/ts/common/logic/articles/writing'
import {
  type CoinsStatusExtendedT,
  type CoinsStatusT
} from '@freckle/student-entities/ts/common/models/coins-status'
import {type ReadingSelectedAnswerT} from '@freckle/student/ts/common/logic/articles/reading'
import {resetAfterSignout} from '@freckle/student/ts/common/routers/init'
import {type StoreItemOwnershipApiPayloadT} from '@freckle/student/ts/common/logic/types'
import {type SightWordsPayloadT} from '@freckle/student/ts/ela/sight-words/components/types'
import {
  type ElaReadingPretestPayloadT,
  type ElaReadingLevelUpdateT
} from '@freckle/student/ts/ela/diagnostic/components/types'
import {type WordStudyDiagnosticPayloadT} from '@freckle/student/ts/ela/word-study/diagnostic/components/types'
import {type ApiTargetedAnswerPayload} from '@freckle/student/ts/math/targeted/logic/targeted-payload'
import {array, Parser, record, string, type ParserT} from '@freckle/parser'
import {api, fetch, getUrl} from '@freckle/student-entities/ts/common/helpers/api-client'
import type {TargetedPracticeTypeT} from '@freckle/student-entities/ts/math/common/models/targeted-practice-type'
import {exhaustive} from '@freckle/exhaustive'
import {getProduct} from '@freckle/student-entities/ts/math/common/models/targeted-practice-type/self-assigned-targeted-practice-type'
import {FetchError} from '@freckle/fancy-api'
import {HandledError} from '@freckle/student-entities/ts/common/exceptions/handled-error'

import {logErrorAsUnhandled} from './exception-handlers/bugsnag-client'

type ElaAssessmentAssignmentAnswerTag =
  | 'ElaAssessmentAssignmentAnswerMAQuestion'
  | 'ElaAssessmentAssignmentAnswerWritingQuestion'

export type ElaAssignmentAnswerPayload = {
  answers: ElaAssignmentAnswerPayloadAnswers
  durationSeconds: number
}

type ElaAssignmentAnswerPayloadAnswer =
  | Inexact<{
      contents: ReadingSelectedAnswerT
      tag: string
    }>
  | Inexact<{
      contents: WritingSelectedAnswerT
      tag: ElaAssessmentAssignmentAnswerTag
    }>

export type ElaAssignmentAnswerPayloadAnswers = Array<ElaAssignmentAnswerPayloadAnswer>

export type WritingAssignmentAnswersPayloadT = {
  answer: WritingSelectedAnswerT
  durationSeconds: number
}

type ResumableSessionAnswerT = {
  answerIndexes: Array<number>
  questionUuid: string
  durationSeconds: number
  freckleTextLevel: string
}

type FocusSkillProgressT = {
  progress: number
}

export type DecodableQuestionAnswerPayloadT = {
  questionId: string
  answerIndex: number
  correctness: number
  durationSeconds: number
}

type ResumableQuestionStatusT =
  | {
      tag: 'can_retry'
    }
  | {
      tag: 'question_answered'
    }
  | {
      tag: 'session_complete'
      accuracy: number
    }

export type ResumableSessionResponseT = {
  coinsTotal: number
  coinsToday: number
  coinsEarned: number
  coinsSessionBalance: number
  correctness: number
  streakCoins: number
  status: ResumableQuestionStatusT
}

export type EarlyExitPayloadT = {
  exitDuration: number
}

export const onEchoError = (echoError: EchoError) => {
  logErrorAsUnhandled(echoError)
  deleteSessionCookie().then(() => {
    resetAfterSignout()
  })
}

// Set $.ajax defaults
export function setupAjax(sides?: string | null) {
  $.ajaxSetup(ajaxSettingsWithEcho(sides, onEchoError))
}

export const checkAudioDescription = async (path: string): Promise<boolean> => {
  try {
    await ajaxCall({
      url: `${path}_desc.mp4`,
      method: 'HEAD',
      contentType: 'text/plain',
      dataType: 'text'
    })
    return true
  } catch (e) {
    return false
  }
}

export function getStudentCookie(
  firstName: string,
  lastName: string,
  code: string,
  password?: string | null
): Promise<void> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.students.sessions(),
    method: 'POST',
    data: JSON.stringify({firstName, lastName, code, password})
  })
}

export function getStudentCookieQr(
  studentId: number,
  code: string,
  password?: string | null
): Promise<void> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.students.sessions(),
    method: 'POST',
    data: JSON.stringify({studentId, code, password})
  })
}

export function deleteSessionCookie(): Promise<{
  redirectUrl?: string
}> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.students.sessions(),
    method: 'DELETE'
  })
}

export function saveTargetedAnswer(
  sessionId: number,
  mathStandardSetId: string,
  practiceType: TargetedPracticeTypeT,
  payload: ApiTargetedAnswerPayload
): Promise<CoinsStatusExtendedT> {
  const product = (() => {
    switch (practiceType.tag) {
      case 'teacher-assigned':
        return Products.MathTargeted
      case 'self-assigned':
        return getProduct(practiceType.contents)
      default:
        return exhaustive(practiceType)
    }
  })()

  const PATHS = Products.getPaths(product)

  const baseUrl =
    CApiHelper.fancyPaths.v3.subjects.subject.products.product.subject_product_sessions.subject_product_session.subject_product_session_answers._(
      PATHS.subjectPath,
      PATHS.productPath,
      sessionId
    )

  return ajaxJsonCall({
    url: `${baseUrl}?math-standard-set-id=${mathStandardSetId}`,
    method: 'POST',
    data: JSON.stringify(payload)
  })
}

export function saveAvatarItems(items: Array<StoreItemOwnershipApiPayloadT>): Promise<void> {
  return fetch(api.patchItemOwnershipsR, identity, items)
}

export function saveElaReadingAssignmentSingleAnswer(
  assignmentId: number,
  payload: ResumableSessionAnswerT,
  isRetry: boolean
): Promise<ResumableSessionResponseT> {
  const endpoint = isRetry
    ? CApiHelper.fancyPaths.v2.ela_assignments.ela_article_reading_assignments.ela_article_reading_assignment.answers_retries(
        [assignmentId]
      )
    : CApiHelper.fancyPaths.v2.ela_assignments.ela_article_reading_assignments.ela_article_reading_assignment.answers(
        [assignmentId]
      )

  return ajaxJsonCall({
    url: endpoint,
    method: 'POST',
    data: JSON.stringify(payload)
  })
}

export function saveScienceReadingAssignmentSingleAnswer(
  assignmentId: number,
  payload: ResumableSessionAnswerT,
  isRetry: boolean
): Promise<ResumableSessionResponseT> {
  const endpoint = isRetry
    ? CApiHelper.fancyPaths.v2.science_assignments.science_reading_assignments.science_reading_assignment.answers_retries(
        [assignmentId]
      )
    : CApiHelper.fancyPaths.v2.science_assignments.science_reading_assignments.science_reading_assignment.answers(
        [assignmentId]
      )

  return ajaxJsonCall({
    url: endpoint,
    method: 'POST',
    data: JSON.stringify(payload)
  })
}

export function saveSocialStudiesReadingAssignmentSingleAnswer(
  assignmentId: number,
  payload: ResumableSessionAnswerT,
  isRetry: boolean
): Promise<ResumableSessionResponseT> {
  const endpoint = isRetry
    ? CApiHelper.fancyPaths.v2.social_studies_assignments.social_studies_reading_assignments.social_studies_reading_assignment.answers_retries(
        [assignmentId]
      )
    : CApiHelper.fancyPaths.v2.social_studies_assignments.social_studies_reading_assignments.social_studies_reading_assignment.answers(
        [assignmentId]
      )

  return ajaxJsonCall({
    url: endpoint,
    method: 'POST',
    data: JSON.stringify(payload)
  })
}

export function saveElaWritingAssignmentAnswers(
  assignmentId: number,
  payload: WritingAssignmentAnswersPayloadT
): Promise<CoinsStatusT> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.ela_assignments.ela_article_writing_assignments.ela_article_writing_assignment.answers(
      [assignmentId]
    ),
    method: 'POST',
    data: JSON.stringify(payload)
  })
}

export function saveSocialStudiesWritingAssignmentAnswers(
  assignmentId: number,
  payload: WritingAssignmentAnswersPayloadT
): Promise<CoinsStatusT> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.social_studies_assignments.social_studies_writing_assignments.social_studies_writing_assignment.answers(
      [assignmentId]
    ),
    method: 'POST',
    data: JSON.stringify(payload)
  })
}

export function saveScienceWritingAssignmentAnswers(
  assignmentId: number,
  payload: WritingAssignmentAnswersPayloadT
): Promise<CoinsStatusT> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.science_assignments.science_writing_assignments.science_writing_assignment.answers(
      [assignmentId]
    ),
    method: 'POST',
    data: JSON.stringify(payload)
  })
}

export function skipElaPretest(payload: ElaReadingLevelUpdateT): Promise<void> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.students._0(),
    method: 'PATCH',
    data: JSON.stringify({
      ...payload,
      tag: 'StudentElaReadingLevelPatchRequest'
    }),
    timeout: 0
  })
}

export function saveElaPretest(payload: ElaReadingPretestPayloadT): Promise<void> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.students._0(),
    method: 'PATCH',
    data: JSON.stringify({
      ...payload,
      tag: 'StudentElaReadingLevelPatchRequest'
    }),
    timeout: 0
  })
}

export function createSelfElaReadingAssignment(articleUuid: string): Promise<
  Inexact<{
    contents: Inexact<{
      readingAssignmentId: number
    }>
  }>
> {
  const payload = {
    tag: 'student_ela_article_reading',
    contents: {articleUuid}
  }

  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.ela_assignments._(),
    method: 'POST',
    data: JSON.stringify(payload)
  })
}

export function saveWordStudyPretest(payload: WordStudyDiagnosticPayloadT): Promise<void> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.students._0(),
    method: 'PATCH',
    data: JSON.stringify({
      ...payload,
      tag: 'StudentWordStudyLevelPatchRequest'
    }),
    timeout: 0
  })
}

export function saveElaAssessmentAnswers(
  assignmentId: number,
  elaAssignmentAnswerPayload: ElaAssignmentAnswerPayload
): Promise<void> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.ela_assignments.ela_assessment_assignments.ela_assessment_assignment.answers(
      [assignmentId]
    ),
    method: 'POST',
    data: JSON.stringify(elaAssignmentAnswerPayload)
  })
}

export function getMathOrderedQuestionIds(): Promise<string[]> {
  return ajaxJsonCall({
    url: `${PATHS.textAssetsUrl}/math/en/questions.json`,
    method: 'GET'
  }).then(qJson => {
    const qParser: ParserT<{id: string}[]> = array(record({id: string()}))
    const questions = Parser.run(qJson, qParser)
    return questions.map(x => x.id)
  })
}

type SightWordsAnswerResponse = {
  level: number | undefined | null
} & CoinsStatusExtendedT

export function sendSightWordsPayload(
  payload: SightWordsPayloadT
): Promise<SightWordsAnswerResponse> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.sight_words_sessions(),
    method: 'POST',
    data: JSON.stringify(payload)
  })
}

export function createDecodableAssignment(decodableUuid: string): Promise<{
  id: number
}> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.ela_assignments.ela_decodables_assignments._(),
    method: 'POST',
    data: JSON.stringify({decodableUuid})
  })
}

export function saveDecodableAssignmentAnswers(
  assignmentId: number,
  answers: Array<DecodableQuestionAnswerPayloadT>,
  coinsGained: number,
  totalSessionDurationSeconds: number
): Promise<CoinsStatusExtendedT> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.ela_assignments.ela_decodables_assignments.ela_decodables_assignment.answers(
      [assignmentId]
    ),
    method: 'POST',
    data: JSON.stringify({answers, totalSessionDurationSeconds, coinsGained})
  })
}

export function saveStudentGoal(studentId: number, goalId: number): Promise<void> {
  return fetch(partial(api.postStudentsGoalsR, {goalsPStudentId: studentId}), identity, {goalId})
}

export function collectStudentGoalReward(
  studentId: number,
  goalId: number,
  completedAt: Moment
): Promise<CoinsStatusT> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.students.goals.goal._(studentId, goalId),
    method: 'PATCH',
    data: JSON.stringify({completedAt: completedAt.format()})
  })
}

export function sendElaReadingAssignmentSessionExit(
  assignmentId: number,
  payload: EarlyExitPayloadT
): Promise<void> {
  return fetch(
    partial(api.postElaArticleReadingAssignmentExitR, {
      elaArticleReadingAssignmentPNonEmptyElaArticleReadingAssignmentId: [assignmentId]
    }),
    identity,
    payload
  )
}

export async function sendElaReadingAssignmentSessionExitBeacon(
  assignmentId: number,
  payload: EarlyExitPayloadT
) {
  sendBeacon({
    url: await getUrl(
      partial(api.postElaArticleReadingAssignmentExitR, {
        elaArticleReadingAssignmentPNonEmptyElaArticleReadingAssignmentId: [assignmentId]
      })
    ),
    data: payload
  })
}

export function sendSocialStudiesReadingAssignmentSessionExit(
  assignmentId: number,
  payload: EarlyExitPayloadT
): Promise<void> {
  return fetch(
    partial(api.postSocialStudiesReadingAssignmentExitR, {
      socialStudiesReadingAssignmentPNonEmptySocialStudiesReadingAssignmentId: [assignmentId]
    }),
    identity,
    payload
  )
}

export async function sendSocialStudiesReadingAssignmentSessionExitBeacon(
  assignmentId: number,
  payload: EarlyExitPayloadT
) {
  sendBeacon({
    url: await getUrl(
      partial(api.postSocialStudiesReadingAssignmentExitR, {
        socialStudiesReadingAssignmentPNonEmptySocialStudiesReadingAssignmentId: [assignmentId]
      })
    ),
    data: payload
  })
}

export function sendScienceReadingAssignmentSessionExit(
  assignmentId: number,
  payload: EarlyExitPayloadT
): Promise<void> {
  return fetch(
    partial(api.postScienceReadingAssignmentExitR, {
      scienceReadingAssignmentPNonEmptyScienceReadingAssignmentId: [assignmentId]
    }),
    identity,
    payload
  )
}

export async function sendScienceReadingAssignmentSessionExitBeacon(
  assignmentId: number,
  payload: EarlyExitPayloadT
) {
  sendBeacon({
    url: await getUrl(
      partial(api.postScienceReadingAssignmentExitR, {
        scienceReadingAssignmentPNonEmptyScienceReadingAssignmentId: [assignmentId]
      })
    ),
    data: payload
  })
}

export function getStudentTeachers(studentId: number): Promise<Array<TeacherAttrs>> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.students.teachers(studentId),
    method: 'GET'
  })
}

export function sendSessionStartedPresenceEvent(practiceMode: string): Promise<void> {
  return fetch(api.postV3StudentsPresenceEventsR, identity, [
    {
      occurredAt: moment().format(),
      details: {tag: 'session_started', contents: {practiceMode}}
    }
  ])
}

export function getStudentFocusSkillProgress(): Promise<FocusSkillProgressT> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v3.content_area.content_area_skill_proficiencies.progress(
      ContentAreas.math
    ),
    method: 'GET'
  })
}

export type SaveImpressionData = {
  teacherId: number
  split: string
  treatment: string
  label: string
}

export function saveSplitImpression(data: SaveImpressionData): Promise<void> {
  try {
    return fetch(api.postV3StudentImpressionsR, identity, data)
  } catch (e) {
    // Ad-blockers like to block the `/impressions` route. Impressions should be a best effort, so
    // let's wrap this up as a handled error.
    if (e instanceof FetchError) {
      throw new HandledError('Failed to save impression', e)
    }
    console.log(JSON.stringify(e))
    throw e
  }
}
