import * as React from 'react'
import Button from '@freckle/student-materials/src/components/button'

import {
  alert,
  closeButton,
  visibleSmallScreen,
  visiblePotraitScreen
} from './narrow-screen-alert.module.scss'
import {PotraitScreenAlert} from './potrait-screen-alert'
import {SmallScreenAlert} from './small-screen-alert'

export const NarrowScreenAlert = () => {
  const [dismissed, setDismissed] = React.useState(false)

  const onDismiss = () => {
    setDismissed(true)
  }

  const closeButtonText = <>&times;</>

  return dismissed ? null : (
    <React.Fragment>
      <div className={alert}>
        <Button addClass={closeButton} onClick={onDismiss} style="link">
          {closeButtonText}
        </Button>

        <div className={visibleSmallScreen}>
          <SmallScreenAlert />
        </div>

        <div className={visiblePotraitScreen}>
          <PotraitScreenAlert />
        </div>
      </div>
    </React.Fragment>
  )
}
