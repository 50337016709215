import {type ParserT, array, number, record} from '@freckle/parser'

import * as RlDomainStandard from './rl-domain-standard'
import {type RlDomainStandardT} from './rl-domain-standard'

export type MathAssessmentTopicT = {
  schoolGrade: number
  domainStandards: Array<RlDomainStandardT>
}

export const parse: ParserT<MathAssessmentTopicT> = record({
  schoolGrade: number(),
  domainStandards: array(RlDomainStandard.parse)
})
