import {combineReducers} from 'redux'
import {type SettingsActionT} from '@freckle/student/ts/actions/settings'
import {type SelfStudentActionT} from '@freckle/student/ts/actions/self-student'
import {type CurrentCourseActionT} from '@freckle/student/ts/actions/current-course'
import {type CurrentTeacherActionT} from '@freckle/student/ts/actions/current-teacher'
import {type PiggyStoreStatusActionT} from '@freckle/student/ts/actions/piggy-store-status'
import {type LanguagesActionT} from '@freckle/student/ts/actions/languages'
import {type ItemsActionT} from '@freckle/student/ts/actions/items'
import {type ItemOwnershipsActionT} from '@freckle/student/ts/actions/item-ownerships'

import {selfStudentReducer} from './self-student'
import {currentCourseReducer} from './current-course'
import {currentTeacherReducer} from './current-teacher'
import {settingsReducer} from './settings'
import {piggyStoreStatusReducer} from './piggy-store-status'
import {languagesReducer} from './languages'
import {itemsReducer} from './items'
import {itemOwnershipsReducer} from './item-ownerships'

const allReducers = {
  selfStudent: selfStudentReducer,
  currentCourse: currentCourseReducer,
  currentTeacher: currentTeacherReducer,
  settings: settingsReducer,
  piggyStoreStatus: piggyStoreStatusReducer,
  languages: languagesReducer,
  items: itemsReducer,
  itemOwnerships: itemOwnershipsReducer
}

export type AllActionT =
  | SelfStudentActionT
  | CurrentCourseActionT
  | CurrentTeacherActionT
  | SettingsActionT
  | PiggyStoreStatusActionT
  | LanguagesActionT
  | ItemsActionT
  | ItemOwnershipsActionT

export default combineReducers(allReducers)
