import * as React from 'react'
import {SplitFactory} from '@splitsoftware/splitio-react'
import {WithResources} from '@freckle/student/ts/common/components/with-resources'
import {
  getValidatedImpression,
  getSplitIOBrowserSDKAPIKey
} from '@freckle/student-entities/ts/common/helpers/split-io-helper'
import {getEnvironment} from '@freckle/student-entities/ts/config/environment'
import type {SaveImpressionData} from '@freckle/student/ts/common/helpers/api-helper'
import {saveSplitImpression} from '@freckle/student/ts/common/helpers/api-helper'

import {useCurrentTeacher} from '../../components/with-resources/utils'

type Props = {
  children: React.ReactElement
}

export const SplitIOWrapper = ({children}: Props) => {
  const {currentTeacher} = useCurrentTeacher()

  return (
    <WithResources
      resources={{currentTeacher}}
      render={({data: {currentTeacher}}) => (
        <Inner currentTeacherId={currentTeacher.id}>{children}</Inner>
      )}
    />
  )
}

const Inner = ({
  children,
  currentTeacherId
}: {
  currentTeacherId: number
} & Props) => {
  const environment = getEnvironment(window.location.hostname)
  const apiKey = getSplitIOBrowserSDKAPIKey(environment)
  const config = {
    core: {
      authorizationKey: apiKey,
      key: currentTeacherId.toString(),
      trafficType: 'student'
    },
    debug: false,
    impressionListener: {logImpression},
    // Used when Localhost Mode is enabled. See getSplitIOBrowserSDKAPIKey defination above.
    // Not listing any feature below will make Split return `off` treatment for all.
    // https://help.split.io/hc/en-us/articles/360038825091-React-SDK#localhost-mode
    features: {}
  }
  return <SplitFactory config={config}>{children}</SplitFactory>
}

// Global Set to track if we have sent an Impression already
// It's possible that our SplitExperiment can get re-rendered and send a bunch of the same impressions
const _impressionSet = new Set()
function addToImpressionSet(i: SaveImpressionData) {
  _impressionSet.add(JSON.stringify(i))
}
function hasSentImpression(i: SaveImpressionData): boolean {
  return _impressionSet.has(JSON.stringify(i))
}

function logImpression(impressionData: SplitIO.ImpressionData) {
  const impression = getValidatedImpression(impressionData)

  if (impression !== null && impression !== undefined) {
    const {keyName, feature: split, treatment, label} = impression
    const teacherId = parseInt(keyName, 10)
    const saveImpressionData = {teacherId, split, treatment, label}
    if (!hasSentImpression(saveImpressionData)) {
      saveSplitImpression(saveImpressionData)
      addToImpressionSet(saveImpressionData)
    }
  }
}
