import {type AjaxJsonCallOptionsT, ajaxJsonCall} from '@freckle/ajax'
import {ApiError} from '@freckle/student-entities/ts/common/models/api-error'

export const apiFetch = <T>(options: AjaxJsonCallOptionsT): Promise<T> => {
  // Caught error will have new error stack, so preserve the current stack
  const preservedStack = new Error().stack
    ?.split('\n')
    .slice(1) // Remove first line, which is a generic "Error"
    .join('\n')

  // N.B.: async try/catch generates more frames in the event of an error, so use Promise callbacks
  return ajaxJsonCall(options).catch(err => {
    const apiError = new ApiError(err)
    // Append preserved error stack
    // eslint-disable-next-line prefer-template
    apiError.stack += '\n' + preservedStack
    throw apiError
  }) as Promise<T>
}
