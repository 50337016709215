import {type LangT} from '@freckle/student-materials/src/helpers/languages'

export type SettingsActionT = SettingsUpdateLanguageT

export type LanguageSetting = {
  code: LangT
  fromUrl: boolean
}

type SettingsUpdateLanguageT = {
  type: 'SETTINGS_UPDATE_LANGUAGE'
  langSetting: LanguageSetting
}

export function settingsUpdateLanguageAction(
  langSetting: LanguageSetting
): SettingsUpdateLanguageT {
  return {type: 'SETTINGS_UPDATE_LANGUAGE', langSetting}
}
