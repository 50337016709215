import * as React from 'react'
import {useSafeTranslation} from '@freckle/student-materials/src/helpers/translate'
import {useSelector} from 'react-redux'
import {Languages, type LangT} from '@freckle/student-materials/src/helpers/languages'
import {type StoreState} from '@freckle/student/ts/reducers/types'
import {getLanguage} from '@freckle/student/ts/reducers/settings'

export function useSettingsLanguage() {
  const {i18n} = useSafeTranslation()
  const settingsLang = useSelector<StoreState, LangT>(getLanguage)

  React.useEffect(() => {
    if (settingsLang !== null && settingsLang !== undefined && i18n.language !== settingsLang) {
      i18n.changeLanguage(Languages.toString(settingsLang))
    }
  }, [settingsLang, i18n])
}
