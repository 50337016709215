import {
  type ParserT,
  type TagParserT,
  Parser,
  record,
  stringInt,
  firstOf,
  tag,
  string,
  map
} from '@freckle/parser'
import {extractQueryParamsObject} from '@freckle/student-entities/ts/common/helpers/routers/query-params'
import {type RlSkillUspIdT} from '@freckle/student-entities/ts/common/types/rl-skill-usp-id'
import * as RlSkillUspId from '@freckle/student-entities/ts/common/types/rl-skill-usp-id'
import {
  type MathTargetedAssignmentTypeT,
  parseMathTargetedSessionType
} from '@freckle/student-entities/ts/common/models/generic-assignment-session/math'
import {exhaustive} from '@freckle/exhaustive'
import {Products} from '@freckle/student-entities/ts/common/helpers/products'

type TargetedPracticeRegularT = {
  tag: 'regular'
}

type TargetedPracticeRetryT = {
  tag: 'retry'
  originalAssignmentId: number
}

export type TargetedPracticeFocusSkillsT = {
  tag: 'focus-skill-remediation'
  uspId: RlSkillUspIdT
  allSkillsMastered: boolean
}

export type TargetedPracticeSkillSetSkillT = {
  tag: 'skill-set-skill'
  uspId: RlSkillUspIdT
  skillSetId: number
  type: MathTargetedAssignmentTypeT
}

export type SelfAssignedTargetedPracticeTypeT =
  | TargetedPracticeRegularT
  | TargetedPracticeRetryT
  | TargetedPracticeFocusSkillsT
  | TargetedPracticeSkillSetSkillT

export const practiceTypeQueryStringParser: ParserT<SelfAssignedTargetedPracticeTypeT> = firstOf(
  record({
    tag: tag('retry'),
    originalAssignmentId: stringInt()
  }),
  record({
    tag: tag('regular')
  }),
  record({
    tag: tag('focus-skill-remediation') as TagParserT<'focus-skill-remediation'>,
    uspId: RlSkillUspId.parse,
    allSkillsMastered: map(string(), 'parseBoolean', x => x === 'true')
  }),
  record({
    tag: tag('skill-set-skill') as TagParserT<'skill-set-skill'>,
    uspId: RlSkillUspId.parse,
    skillSetId: map(string(), 'parseInt', x => parseInt(x, 10)),
    type: parseMathTargetedSessionType
  })
)

export function parseSelfAssignedTargetedPracticeTypeFromQueryString(
  search: string
): SelfAssignedTargetedPracticeTypeT {
  //If we have any query params try to parse them as a PracticeType
  const params = extractQueryParamsObject(search)
  if (params !== null && params !== undefined) {
    return Parser.run(params, practiceTypeQueryStringParser)
  } else {
    // Fallback to regular practice if we don't have any query params
    return {tag: 'regular'}
  }
}

export const getProduct = (practiceType: SelfAssignedTargetedPracticeTypeT) => {
  switch (practiceType.tag) {
    case 'regular':
    case 'retry':
    case 'skill-set-skill':
      return Products.MathTargeted
    case 'focus-skill-remediation':
      return Products.FocusSkillsPractice
    default:
      return exhaustive(practiceType)
  }
}
