import * as React from 'react'
import {useSafeTranslation} from '@freckle/student-materials/src/helpers/translate'

import {ScreenAlert} from './screen-alert'
import {centered} from './narrow-screen-alert.module.scss'
import IconWhitepig from './icon-whitepig.svg'

type Props = {
  noImage?: boolean
}

export function SmallScreenAlert(props: Props): React.ReactElement {
  const {t} = useSafeTranslation()
  const {noImage} = props
  const imageUrl = noImage ? null : IconWhitepig

  return (
    <div className={centered}>
      <ScreenAlert
        imageSrc={imageUrl}
        headingText={t('Oops! This device size is not supported')}
        bodyText={t('Try logging into Freckle on a larger tablet or desktop')}
      />
    </div>
  )
}
