import * as React from 'react'
import {SafeTrans, useSafeTranslation} from '@freckle/student-materials/src/helpers/translate'
import {PATHS} from '@freckle/student-materials/src/helpers/paths'
import {H1, Text} from '@freckle/student-materials/src/components/typography'
import Image from '@freckle/student-materials/src/components/img'
import Link from '@freckle/student-materials/src/components/link'
import {Flex} from '@freckle/student-materials/src/components/flex'

import {wrapperStyle, imageStyle, textStyle} from './error-page.module.scss'
import PiggyWaving from './piggy-waving.svg'

export function ErrorPage(): React.ReactElement {
  return (
    <div className={wrapperStyle}>
      <ErrorContent />
    </div>
  )
}

export function ErrorInline(): React.ReactElement {
  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center">
      <ErrorContent />
    </Flex>
  )
}

function ErrorContent(): React.ReactElement {
  const {t} = useSafeTranslation()
  return (
    <>
      <Image className={imageStyle} src={PiggyWaving} />
      <H1 addClass={textStyle}>{t('ERROR_PAGE_HEADER')}</H1>
      <Text addClass={textStyle}>
        <SafeTrans
          components={{backLink: <Link href={PATHS.studentClientUrl} />}}
          i18nKey="ERROR_PAGE_INSTRUCTIONS"
        />
      </Text>
      <Text addClass={textStyle}>{t('ERROR_PAGE_STILL_NOT_WORKING')}</Text>
    </>
  )
}
