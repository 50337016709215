import find from 'lodash/find'
import {fromJust} from '@freckle/maybe'

export type ContentAreaT = 'math' | 'ela'

export const ContentAreas = {
  math: 'math' as ContentAreaT,
  ela: 'ela' as ContentAreaT,

  all(): Array<ContentAreaT> {
    return [ContentAreas.math, ContentAreas.ela]
  },

  parse(text: string): ContentAreaT | undefined | null {
    return find(ContentAreas.all(), value => value === text)
  },

  toPath: (contentArea: ContentAreaT): string => contentArea,

  parseThrows(text: string): ContentAreaT {
    return fromJust(ContentAreas.parse(text), `Invalid practice mode ${text}`)
  }
}
