import * as React from 'react'
import {useLocation} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import find from 'lodash/find'
import {Languages} from '@freckle/student-materials/src/helpers/languages'
import {mmap, mEffect} from '@freckle/maybe'
import {extractQueryParamsTuples} from '@freckle/student-entities/ts/common/helpers/routers/query-params'
import {settingsUpdateLanguageAction} from '@freckle/student/ts/actions/settings'
import {useSettingsLanguage} from '@freckle/student/ts/hooks/use-settings-language'

type Props = {
  children?: React.ReactElement
}

function UseLanguageComponent(props: Props): React.ReactElement {
  const {children} = props
  const location = useLocation()
  useSettingsLanguage()
  const dispatch = useDispatch()

  React.useEffect(() => {
    const mLanguageTuple = find(extractQueryParamsTuples(location.search), ([key]) => {
      return key === 'language'
    })

    const mLanguage = mmap(tuple => Languages.parse(tuple[1]), mLanguageTuple)

    mEffect(mLanguage, lang =>
      dispatch(
        settingsUpdateLanguageAction({
          code: lang,
          fromUrl: true
        })
      )
    )
  }, [location.search, dispatch])

  return children ?? <></>
}

export const UseLanguage = UseLanguageComponent
