/* eslint-disable react-refresh/only-export-components */
import React from 'react'
import {ErrorPage} from '@freckle/student/ts/common/components/common/error-page'
import {LoggedRoute} from '@freckle/student/ts/common/routers/logged-route'

import type {PathData} from './path-data'
import type {TimeSpanQueryParamsT} from '../../components/navigation/common/logic/time-span'
import type {TabT} from '../../components/navigation/start-page-assignments/types/tabs'

const StartPageHomeHandler = React.lazy(
  () => import('@freckle/student/ts/common/components/navigation/start-page-home')
)
const SelectLoginPageHandler = React.lazy(
  () => import('@freckle/student/ts/common/components/navigation/select-login')
)
const StartPageAssignmentsHandler = React.lazy(
  () => import('@freckle/student/ts/common/components/navigation/start-page-assignments')
)
const StorePageHandler = React.lazy(
  () => import('@freckle/student/ts/common/components/navigation/store/index')
)
const StorePageDebugHandler = React.lazy(
  () => import('@freckle/student/ts/common/components/navigation/store/debug')
)
const StoreClosedPageHandler = React.lazy(
  () => import('@freckle/student/ts/common/components/navigation/store/store-closed')
)
const StoreOpenPageHandler = React.lazy(
  () => import('@freckle/student/ts/common/components/navigation/store/store-open')
)
const ChangeThemeHandler = React.lazy(
  () => import('@freckle/student/ts/common/components/navigation/change-theme/index')
)
const DebugDepthOfKnowledgeHandler = React.lazy(
  () => import('@freckle/student/ts/math/depth-of-knowledge/components/debugger')
)
const AssignmentNotFoundHandler = React.lazy(
  () => import('@freckle/student/ts/common/components/common/assignment-not-found')
)
const DebuggersPageHandler = React.lazy(
  () => import('@freckle/student/ts/pages/debuggers/components/index')
)
const LoginHandler = React.lazy(
  () => import('@freckle/student/ts/common/components/navigation/login/index')
)
const VideoPlayerHandler = React.lazy(
  () => import('@freckle/student/ts/common/components/video-player')
)
const LoginQrHandler = React.lazy(
  () => import('@freckle/student/ts/common/components/navigation/login/login-qr-handler')
)
const DirectLoginHandler = React.lazy(
  () => import('@freckle/student/ts/common/components/navigation/login/direct-login-handler')
)
const SignoutHandler = React.lazy(
  () => import('@freckle/student/ts/common/components/navigation/signout')
)
const ClassCodeHelpPage = React.lazy(() => import('@freckle/student/ts/pages/class-code-help-page'))
const MathViewerHandler = React.lazy(
  () => import('@freckle/student/ts/math/internal/debuggers/math-viewer')
)
const OnboardingPageHandler = React.lazy(
  () => import('@freckle/student/ts/pages/onboarding/components/index')
)

export const BYPASS_CHECK = 'bypassCheck'

export type CommonRoutesWithQueryParams = {
  '/login': undefined
  '/login-qr': undefined
  '/direct-login': undefined
  '/signout': undefined
  '/error': undefined
  '/class-code-help': undefined
  '/start': {teacher?: boolean}
  '/units/:unit/videos/:videoUrl': undefined
  '/select-class': undefined
  '/depth-of-knowledge/debug/:questionSetUuid': undefined
  '/start/assignments': {
    tab?: TabT
    timeSpan?: TimeSpanQueryParamsT | null
  }
  '/store': {redirect?: string}
  '/store-closed': {redirect?: string}
  '/store-open': undefined
  '/change-theme': undefined
  '/debugStore': undefined
  '/assignments/:subject/not-found': undefined
  '/debuggers': undefined
  '/debuggers/math/question-skills-math-viewer': undefined
  '/onboarding': {[BYPASS_CHECK]?: boolean}
}

export const commonPathData: PathData<CommonRoutesWithQueryParams> = {
  '/login': {isBlockedByFocusMode: false, element: () => <LoginHandler />},
  '/login-qr': {isBlockedByFocusMode: false, element: () => <LoginQrHandler />},
  '/direct-login': {isBlockedByFocusMode: false, element: () => <DirectLoginHandler />},
  '/signout': {isBlockedByFocusMode: false, element: () => <SignoutHandler />},
  '/error': {isBlockedByFocusMode: false, element: () => <ErrorPage />},
  '/class-code-help': {isBlockedByFocusMode: false, element: () => <ClassCodeHelpPage />},
  '/start': {
    isBlockedByFocusMode: false,
    element: path => (
      <LoggedRoute
        component={StartPageHomeHandler}
        wrapperParams={{isResponsive: true}}
        path={path}
      />
    )
  },
  '/units/:unit/videos/:videoUrl': {
    isBlockedByFocusMode: false,
    element: path => (
      <LoggedRoute
        component={VideoPlayerHandler}
        wrapperParams={{isResponsive: true}}
        path={path}
      />
    )
  },
  '/select-class': {
    isBlockedByFocusMode: false,
    element: path => (
      <LoggedRoute
        component={SelectLoginPageHandler}
        wrapperParams={{isFullScreen: true, isResponsive: true}}
        path={path}
      />
    )
  },
  '/depth-of-knowledge/debug/:questionSetUuid': {
    isBlockedByFocusMode: false,
    element: path => (
      <LoggedRoute
        component={DebugDepthOfKnowledgeHandler}
        wrapperParams={{isResponsive: true}}
        path={path}
      />
    )
  },
  '/start/assignments': {
    isBlockedByFocusMode: false,
    element: path => (
      <LoggedRoute
        component={StartPageAssignmentsHandler}
        wrapperParams={{isResponsive: true}}
        path={path}
      />
    )
  },
  '/store': {
    isBlockedByFocusMode: false,
    element: path => (
      <LoggedRoute component={StorePageHandler} wrapperParams={{isFullScreen: true}} path={path} />
    )
  },
  '/store-closed': {
    isBlockedByFocusMode: false,
    element: path => (
      <LoggedRoute
        component={StoreClosedPageHandler}
        wrapperParams={{isResponsive: true}}
        path={path}
      />
    )
  },
  '/store-open': {
    isBlockedByFocusMode: false,
    element: path => (
      <LoggedRoute
        component={StoreOpenPageHandler}
        wrapperParams={{isResponsive: true}}
        path={path}
      />
    )
  },
  '/change-theme': {
    isBlockedByFocusMode: false,
    element: path => (
      <LoggedRoute
        component={ChangeThemeHandler}
        wrapperParams={{isResponsive: true}}
        path={path}
      />
    )
  },
  '/debugStore': {
    isBlockedByFocusMode: false,
    element: path => (
      <LoggedRoute
        component={StorePageDebugHandler}
        wrapperParams={{isResponsive: true}}
        path={path}
      />
    )
  },
  '/assignments/:subject/not-found': {
    isBlockedByFocusMode: false,
    element: path => <LoggedRoute component={AssignmentNotFoundHandler} path={path} />
  },
  '/debuggers': {
    isBlockedByFocusMode: false,
    element: path => <LoggedRoute component={DebuggersPageHandler} path={path} />
  },
  '/debuggers/math/question-skills-math-viewer': {
    isBlockedByFocusMode: false,
    element: path => <LoggedRoute component={MathViewerHandler} path={path} />
  },
  '/onboarding': {
    isBlockedByFocusMode: false,
    element: path => <LoggedRoute component={OnboardingPageHandler} path={path} />
  }
}
