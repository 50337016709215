/** This js-fast-exit-btn link is used by our iOS app to logout
 * previously logged-in students everytime the app is re-launched.
 */

import * as React from 'react'
import Link from '@freckle/student-materials/src/components/link'
import type {SyntheticEvent} from 'react'

export function FastExitButton(): React.ReactElement {
  return (
    <div className="hide">
      <Link addClass="js-fast-exit-btn" href="/" onClick={onClickFastExit} />
    </div>
  )
}

/**
 * This "fast exit" callback is triggered by our iOS app to
 * logout previously logged-in students everytime the app
 * is re-launched.
 */
function onClickFastExit(e: SyntheticEvent) {
  e.preventDefault()
  window.dispatchEvent(new CustomEvent('fast-exit'))
}
