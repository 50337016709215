/* eslint-disable react-refresh/only-export-components */
import React from 'react'
import {LoggedRoute} from '@freckle/student/ts/common/routers/logged-route'

import type {PathData} from './path-data'

const ScienceReadingAssignmentHandler = React.lazy(
  () => import('@freckle/student/ts/units/common/components/science-reading-assignment-handler')
)
const ScienceWritingAssignmentHandler = React.lazy(
  () => import('@freckle/student/ts/units/common/components/science-writing-assignment-handler')
)
const SocialStudiesReadingAssignmentHandler = React.lazy(
  () => import('@freckle/student/ts/units/common/components/social-studies-reading-handler')
)
const SocialStudiesWritingAssignmentHandler = React.lazy(
  () => import('@freckle/student/ts/units/common/components/social-studies-writing-handler')
)

export type UnitRoutesWithQueryParams = {
  '/science/assignments/reading/:assignmentIds': undefined
  '/science/assignments/writing/:assignmentIds': undefined
  '/social-studies/assignments/reading/:assignmentIds': undefined
  '/social-studies/assignments/writing/:assignmentIds': undefined
}

export const unitPathData: PathData<UnitRoutesWithQueryParams> = {
  '/science/assignments/reading/:assignmentIds': {
    isBlockedByFocusMode: false,
    element: path => <LoggedRoute component={ScienceReadingAssignmentHandler} path={path} />
  },
  '/science/assignments/writing/:assignmentIds': {
    isBlockedByFocusMode: false,
    element: path => <LoggedRoute component={ScienceWritingAssignmentHandler} path={path} />
  },
  '/social-studies/assignments/reading/:assignmentIds': {
    isBlockedByFocusMode: false,
    element: path => <LoggedRoute component={SocialStudiesReadingAssignmentHandler} path={path} />
  },
  '/social-studies/assignments/writing/:assignmentIds': {
    isBlockedByFocusMode: false,
    element: path => <LoggedRoute component={SocialStudiesWritingAssignmentHandler} path={path} />
  }
}
