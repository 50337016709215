import {
  phoneVerticalEnd,
  phoneHorizontalEnd,
  tabletVerticalEnd,
  tabletHorizontalEnd,
  largeDesktopStart
} from './responsive-breakpoints.module.scss'

export const PHONE_VERTICAL_END = parseInt(phoneVerticalEnd, 10)
export const PHONE_HORIZONTAL_END = parseInt(phoneHorizontalEnd, 10)
export const TABLET_VERTICAL_END = parseInt(tabletVerticalEnd, 10)
export const TABLET_HORIZONTAL_END = parseInt(tabletHorizontalEnd, 10)
export const LARGE_DESKTOP_START = parseInt(largeDesktopStart, 10)
