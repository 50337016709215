import {mapMaybes} from '@freckle/maybe'
import {ajaxJsonCall} from '@freckle/ajax'
import {PATHS} from '@freckle/student-materials/src/helpers/paths'
import {type LangT, Languages} from '@freckle/student-materials/src/helpers/languages'
import {
  Parser,
  record,
  string,
  number,
  array,
  boolean,
  nullable,
  type ParserT
} from '@freckle/parser'
import {ContentAreas} from '@freckle/student-entities/ts/common/helpers/content-area'
import * as RlStandardId from '@freckle/student-entities/ts/common/types/rl-standard-id'
import * as RlSkillUspId from '@freckle/student-entities/ts/common/types/rl-skill-usp-id'
import {type ContentAreaT} from '@freckle/student-entities/ts/common/helpers/content-area'
import find from 'lodash/find'

import {RlSkillT} from '../types/rl-skill'
import {SkillSetSkillT} from '../types/skill-set'

export const WORD_STUDY_SKILL_SET_ID = 4

// Skill Set Skills

const fromSkillSetSkillT = ({
  grade,
  progressionOrder,
  isFocus,
  standardId,
  ...rest
}: SkillSetSkillT): RlSkillT | null =>
  grade !== null &&
  grade !== undefined &&
  progressionOrder !== null &&
  progressionOrder !== undefined &&
  isFocus !== null &&
  isFocus !== undefined &&
  standardId !== null &&
  standardId !== undefined
    ? {grade, progressionOrder, isFocus, standardId, ...rest}
    : null

export function keepOnlySkillSetSkillsAttachedToStandard(
  skillSetSkills: Array<SkillSetSkillT>
): Array<RlSkillT> {
  return mapMaybes(skillSetSkills, fromSkillSetSkillT)
}

const parseSkillSetSkill: ParserT<SkillSetSkillT> = record({
  uspId: RlSkillUspId.parse,
  name: string(),
  shortName: string(),
  grade: nullable(number()),
  progressionOrder: nullable(number()),
  isFocus: nullable(boolean()),
  standardId: nullable(RlStandardId.parse),
  standardSetId: string()
})

export type SkillSetT = {
  skillSetId: number
  name: string
  shortName: string
  iconUrl: string
  iconLargeUrl: string
  iconNextUpUrl: string
  iconReviewUrl: string
  iconCompletedUrl: string
  bgSwatchUrl: string
  progressionDocUrl: string
  progressionOrder: number
  skills: Array<SkillSetSkillT>
}

const parse: ParserT<SkillSetT> = record({
  skillSetId: number(),
  name: string(),
  shortName: string(),
  iconUrl: string(),
  iconLargeUrl: string(),
  iconNextUpUrl: string(),
  iconReviewUrl: string(),
  iconCompletedUrl: string(),
  bgSwatchUrl: string(),
  progressionDocUrl: string(),
  progressionOrder: number(),
  skills: array(parseSkillSetSkill)
})

export const parseSkillSet = Parser.mkRun<Array<SkillSetT>>(array(parse))

export const fetchSkillSets = (
  contentArea: ContentAreaT,
  standardSetId: string,
  lang: LangT
): Promise<Array<SkillSetT>> => {
  const url = `${PATHS.textAssetsUrl}/${ContentAreas.toPath(
    contentArea
  )}/standard-sets/${standardSetId}/${Languages.toString(lang)}/skill-sets.json`

  return ajaxJsonCall({
    url,
    method: 'GET'
  }).then(parseSkillSet)
}

export const fetchWordStudySkillSet = async (
  elaStandardSetId: string,
  lang: LangT
): Promise<SkillSetT | null | undefined> => {
  const skillSets = await fetchSkillSets(ContentAreas.ela, elaStandardSetId, lang)

  const wordStudySkillSet = find(
    skillSets,
    skillSet => skillSet.skillSetId === WORD_STUDY_SKILL_SET_ID
  )

  return wordStudySkillSet
}

export function enableSkillSetExperience(skillSets: Array<SkillSetT>): boolean {
  return skillSets.length > 0
}
