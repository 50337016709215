import {type ItemOwnershipsStateT} from '@freckle/student/ts/reducers/types'
import {type ItemOwnershipsActionT} from '@freckle/student/ts/actions/item-ownerships'

const defaultItemOwnershipsState: ItemOwnershipsStateT = {
  status: 'idle'
}

export function itemOwnershipsReducer(
  state: ItemOwnershipsStateT = defaultItemOwnershipsState,
  action: ItemOwnershipsActionT
): ItemOwnershipsStateT {
  switch (action.type) {
    case 'ITEM_OWNERSHIPS_GET_REQUEST':
      return state.status === 'complete'
        ? {status: 'updating', data: state.data}
        : {status: 'loading'}
    case 'ITEM_OWNERSHIPS_GET_RESPONSE': {
      const {itemOwnerships} = action
      return {status: 'complete', data: itemOwnerships, hasUpdated: false}
    }
    case 'ITEM_OWNERSHIPS_GET_ERROR':
      return {status: 'error', error: action.error}
    default:
      return state
  }
}
