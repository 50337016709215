import * as React from 'react'
import {Outlet} from 'react-router-dom'
import {Provider} from 'react-redux'
import {DndProvider} from 'react-dnd'
import TouchBackend from 'react-dnd-touch-backend'
import {BreakpointProvider} from '@freckle/student-materials/src/context/breakpoint-provider'
import {setupI18n} from '@freckle/student-entities/ts/common/helpers/translate/i18n/i18n-helper'
import DelayedSpinner from '@freckle/student-entities/ts/common/components/spinner-wrapper/delayed-spinner'
import {fromJust} from '@freckle/maybe'
import {ToastContainer} from 'react-toastify'
import {SWRConfig} from 'swr'

import {ErrorPage} from './common/components/common/error-page'
import {AudioContextProvider} from './context/audio'
import bugsnagClient, {setGetState} from './common/helpers/exception-handlers/bugsnag-client'
import {store} from './store'
import {ConnectivityCheck} from './common/routers/router-v2/connectivity-check'
import {AuthCheck} from './common/routers/router-v2/auth-check'
import {UseLanguage} from './common/routers/router-v2/use-language'
import {swrOptions} from './common/helpers/swr-options'

setupI18n('student')

const ErrorBoundary = fromJust(
  bugsnagClient.getPlugin('react'),
  'We should be able to make an ErrorBoundary'
).createErrorBoundary(React)
setGetState(store.getState)

const hasNative = document && document.elementsFromPoint

// Use a custom handler to support msElementsFromPoint for Edge support
function getDropTargetElementsAtPoint(
  x: number,
  y: number,
  dropTargets: Array<Element>
): Array<Element> {
  // @ts-ignore msElementsFromPoint is the MS Edge equivalent of elementsFromPoint
  if (document.msElementsFromPoint !== null && document.msElementsFromPoint !== undefined) {
    // @ts-ignore same as above
    return document.msElementsFromPoint(x, y)
  }
  return dropTargets.filter(t => {
    const rect = t.getBoundingClientRect()
    return x >= rect.left && x <= rect.right && y <= rect.bottom && y >= rect.top
  })
}

const touchBackendOptions = {
  enableTouchEvents: hasNative,
  enableMouseEvents: true,
  getDropTargetElementsAtPoint: !hasNative && getDropTargetElementsAtPoint
}

export function AppProviders(): React.ReactElement {
  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      {/* React Suspense is used for i18n helper and React.lazy usage in Router */}
      <React.Suspense fallback={<DelayedSpinner />}>
        <Provider store={store}>
          <BreakpointProvider>
            <AudioContextProvider>
              {/* @ts-ignore: Cannot use `children` after React 18 */}
              <DndProvider backend={TouchBackend} options={touchBackendOptions}>
                <ConnectivityCheck />
                <AuthCheck />
                <UseLanguage />
                <ToastContainer />
                <SWRConfig value={swrOptions}>
                  <Outlet />
                </SWRConfig>
              </DndProvider>
            </AudioContextProvider>
          </BreakpointProvider>
        </Provider>
      </React.Suspense>
    </ErrorBoundary>
  )
}
