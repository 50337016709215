import * as React from 'react'
import map from 'lodash/map'
import {exhaustive} from '@freckle/exhaustive'
import {asHTMLAttributeValue} from '@freckle/maybe'
import {addMaybeClassName} from '@freckle/student-materials/src/helpers/classnames'

import {
  body1Style,
  body2Style,
  body3Style,
  subtitle1Style,
  subtitle2Style,
  subtitle3Style,
  label1Style,
  label2Style,
  label3Style,
  caption1Style,
  caption2Style,
  subheaderStyle,
  practiceSmStyle,
  practiceMdStyle,
  practiceLgStyle,
  practiceXlgStyle,
  bold,
  italic,
  muted,
  centered,
  noBottomMargin,
  inheritColor
} from './text.module.scss'
import {h1, h2, h3, h4} from './typography.module.scss'

export type HeadingStyleT = 'heading-1' | 'heading-2' | 'heading-3' | 'heading-4'

export type StyleT =
  | 'body-1'
  | 'body-2'
  | 'body-3'
  | 'subheader'
  | 'subtitle-1'
  | 'subtitle-2'
  | 'subtitle-3'
  | 'label-1'
  | 'label-2'
  | 'label-3'
  | 'caption-1'
  | 'caption-2'
  | 'practice-sm'
  | 'practice-md'
  | 'practice-lg'
  | 'practice-xlg'
  | HeadingStyleT

export type ModifierT =
  | 'bold'
  | 'italic'
  | 'muted'
  | 'centered'
  | 'no-bottom-margin'
  | 'inherit-color'

type Props = {
  id?: string
  inFocus?: boolean
  children?: React.ReactNode
  style?: StyleT
  modifiers?: Array<ModifierT>
  htmlElement?: 'div' | 'p' | 'span' | 'h1' | 'h2' | 'h3' | 'h4' | 'header'
  addClass?: string | null
  dataTest?: string | null
  role?: React.AriaRole
  dangerouslySetInnerHTML?: {
    __html: string
  }
}

function Text(props: Props): React.ReactElement<Props> {
  const {
    id,
    children,
    inFocus,
    style = 'body-1',
    addClass,
    dataTest,
    modifiers = [],
    htmlElement = 'p',
    role,
    dangerouslySetInnerHTML
  } = props
  const baseClassName = getBaseClassname(style, modifiers)
  const className = addMaybeClassName(baseClassName, addClass)
  const dataTestObj = {'data-test': asHTMLAttributeValue(dataTest)}
  const textRef = React.useRef<HTMLElement>(null)
  const refProp = inFocus ? {ref: textRef} : {}
  const dangerouslySetInnerHTMLObj =
    dangerouslySetInnerHTML !== null && dangerouslySetInnerHTML !== undefined
      ? {dangerouslySetInnerHTML}
      : {}
  React.useEffect(() => {
    if (inFocus && textRef.current) {
      textRef.current.tabIndex = -1
      textRef.current.focus()
    }
  }, [inFocus])
  switch (style) {
    case 'body-1':
    case 'body-2':
    case 'body-3':
    case 'subtitle-1':
    case 'subtitle-2':
    case 'subtitle-3':
    case 'label-1':
    case 'label-2':
    case 'label-3':
    case 'subheader':
    case 'practice-sm':
    case 'practice-md':
    case 'practice-lg':
    case 'practice-xlg':
    case 'heading-1':
    case 'heading-2':
    case 'heading-3':
    case 'heading-4':
      return React.createElement(
        htmlElement,
        {
          className,
          id,
          role,
          ...refProp,
          ...dataTestObj,
          ...dangerouslySetInnerHTMLObj
        },
        children
      )
    case 'caption-1':
    case 'caption-2':
      switch (htmlElement) {
        case 'h1':
        case 'h2':
        case 'h3':
        case 'h4':
        case 'header':
          return React.createElement(
            htmlElement,
            {
              className,
              id,
              role,
              ...refProp,
              ...dataTestObj,
              ...dangerouslySetInnerHTMLObj
            },
            children
          )
        case 'div':
        case 'span':
        case 'p':
          return (
            <figcaption
              className={className}
              id={id}
              role={role}
              {...dataTestObj}
              {...dangerouslySetInnerHTMLObj}
            >
              {children}
            </figcaption>
          )
        default:
          return exhaustive(htmlElement)
      }
    default:
      return exhaustive(style)
  }
}

export {Text}

function getBaseClassname(style: StyleT, modifiers: Array<ModifierT>): string {
  const modifierClass = getModifiersClass(modifiers)
  switch (style) {
    case 'body-1':
      return addMaybeClassName(body1Style, modifierClass)
    case 'body-2':
      return addMaybeClassName(body2Style, modifierClass)
    case 'body-3':
      return addMaybeClassName(body3Style, modifierClass)
    case 'subtitle-1':
      return addMaybeClassName(subtitle1Style, modifierClass)
    case 'subtitle-2':
      return addMaybeClassName(subtitle2Style, modifierClass)
    case 'subtitle-3':
      return addMaybeClassName(subtitle3Style, modifierClass)
    case 'label-1':
      return addMaybeClassName(label1Style, modifierClass)
    case 'label-2':
      return addMaybeClassName(label2Style, modifierClass)
    case 'label-3':
      return addMaybeClassName(label3Style, modifierClass)
    case 'caption-1':
      return addMaybeClassName(caption1Style, modifierClass)
    case 'caption-2':
      return addMaybeClassName(caption2Style, modifierClass)
    case 'subheader':
      return addMaybeClassName(subheaderStyle, modifierClass)
    case 'practice-sm':
      return addMaybeClassName(practiceSmStyle, modifierClass)
    case 'practice-md':
      return addMaybeClassName(practiceMdStyle, modifierClass)
    case 'practice-lg':
      return addMaybeClassName(practiceLgStyle, modifierClass)
    case 'practice-xlg':
      return addMaybeClassName(practiceXlgStyle, modifierClass)
    case 'heading-1':
      return addMaybeClassName(h1, modifierClass)
    case 'heading-2':
      return addMaybeClassName(h2, modifierClass)
    case 'heading-3':
      return addMaybeClassName(h3, modifierClass)
    case 'heading-4':
      return addMaybeClassName(h4, modifierClass)
    default:
      return exhaustive(style)
  }
}

function getModifiersClass(modifiers: Array<ModifierT>): string | undefined | null {
  return modifiers.length === 0 ? null : map(modifiers, getModifierClass).join(' ')
}

function getModifierClass(modifier: ModifierT): string {
  switch (modifier) {
    case 'bold':
      return bold
    case 'italic':
      return italic
    case 'muted':
      return muted
    case 'centered':
      return centered
    case 'no-bottom-margin':
      return noBottomMargin
    case 'inherit-color':
      return inheritColor
    default:
      return exhaustive(modifier, 'ModifierT')
  }
}
