import mapValues from 'lodash/mapValues'
import * as React from 'react'
import {type ParserT, string, mapStatic} from '@freckle/parser'
import {useSafeTranslation} from '@freckle/student-materials/src/helpers/translate'
import {exhaustive} from '@freckle/exhaustive'

export type HTMLText = {tag: 'html-text'; value: string}

export type Props = {htmlText: HTMLText}

export const DangerouslyRenderHTMLText = ({htmlText}: Props): React.ReactElement => (
  <span dangerouslySetInnerHTML={{__html: htmlText.value}} />
)

export function fromString(value: string): HTMLText {
  return {tag: 'html-text', value}
}

export const htmlTextParser: ParserT<HTMLText> = mapStatic(string(), value => ({
  tag: 'html-text',
  value
}))

export const useHtmlText = (): ((
  msg: string,
  vars: Record<string, string | number | boolean | HTMLText>
) => HTMLText) => {
  const {t} = useSafeTranslation()
  return (msg, vars) => {
    const unHTMLText: Record<string, string | number | boolean> = mapValues(vars, v => {
      switch (typeof v) {
        case 'string':
        case 'boolean':
        case 'number':
          return v
        case 'object':
          return v.value
        default:
          return exhaustive(v)
      }
    })
    return {tag: 'html-text', value: t(msg, unHTMLText)}
  }
}
