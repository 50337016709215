import * as React from 'react'
import {createRoot} from 'react-dom/client'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from 'react-router-dom'
import 'url-polyfill'
import 'whatwg-fetch'
import '@freckle/student-entities/ts/common/helpers/event-polyfill'
import '@freckle/student-entities/ts/common/helpers/queueMicrotask-polyfill'
import {fromJust} from '@freckle/maybe'
import {initializeApi} from '@freckle/student-entities/ts/common/helpers/api-client'

// Important: Keep CSS import first to correctly order global CSS and CSS Modules
import './../css/frontrow.scss'
import {onEchoError, setupAjax} from './common/helpers/api-helper'
import {initializeGlobalEventListeners} from './common/routers/init-global-event-listeners'
import {AppProviders} from './app'
import {ErrorRedirect} from './common/routers/error-redirect'
import {RouterV2} from './common/routers/router-v2'

// Take the sides querystring param and pass it along to the ajax helper so that we can test
// random 503s on the backend
const sides =
  typeof URL === 'function' && typeof URLSearchParams !== 'undefined'
    ? new URL(window.location.href).searchParams.get('sides')
    : null

setupAjax(sides)
initializeGlobalEventListeners()
initializeApi(onEchoError)

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AppProviders />} errorElement={<ErrorRedirect />}>
      <Route path="*" element={<RouterV2 />} />
    </Route>
  )
)

const container = fromJust(document.getElementById('freckleapp'), 'Container not found')
const root = createRoot(container)
root.render(<RouterProvider router={router} />)
