import * as React from 'react'
import StorageHelper from '@freckle/student-entities/ts/common/helpers/storage-helper'
import {type SelfStudentAttrs} from '@freckle/student/ts/users/models/self-student/self-student'
import {isStarLinkedGeneric} from '@freckle/student-entities/ts/users/models/student'
import {WithResources} from '@freckle/student/ts/common/components/with-resources'
import {StarIntegrationLinkedModal} from '@freckle/student/ts/common/components/common/star-integration-linked-modal'
import {TeacherLogin} from '@freckle/student/ts/common/components/teacher-login'

import {FastExitButton} from './fast-exit-button'
import {fullSize, dashboardContent, teacherLogin} from './dashboard.module.scss'
import {useSelfStudentDepricated} from '../with-resources/utils'

type DashboardModal = 'star-integration-linked'

type Props = {
  isFullScreen: boolean
  children?: React.ReactNode
}

export const linkingStarIntegrationSessionStorageProperty = 'linking-star-integration'

export function Dashboard(props: Props): React.ReactElement {
  const {selfStudent} = useSelfStudentDepricated()

  return (
    <WithResources resources={{selfStudent}} render={({data}) => <Inner {...data} {...props} />} />
  )
}
const Inner = ({
  selfStudent,
  isFullScreen,
  children
}: {
  selfStudent: SelfStudentAttrs
  isFullScreen: boolean
  children?: React.ReactNode
}): React.ReactElement => {
  const [modal, setModal] = React.useState<DashboardModal | undefined | null>(null)

  React.useEffect(() => {
    if (StorageHelper.getSessionStorageProperty(linkingStarIntegrationSessionStorageProperty)) {
      StorageHelper.clearSessionStorageProperty(linkingStarIntegrationSessionStorageProperty)

      //Only show the success modal if we have an RP Identifier
      if (
        isStarLinkedGeneric({
          renaissanceRPIdentifier: selfStudent.renaissanceRPIdentifier,
          renaissanceManualRPIdentifier: selfStudent.renaissanceManualRPIdentifier
        })
      ) {
        setModal('star-integration-linked')
      }
    }
  }, [selfStudent.renaissanceManualRPIdentifier, selfStudent.renaissanceRPIdentifier])

  const hideModal = () => setModal(null)

  const renderTeacherLogin = (selfStudent: SelfStudentAttrs): React.ReactNode => {
    return selfStudent.isTeacher ? <TeacherLogin /> : null
  }

  const renderChildOnly = (): React.ReactElement => {
    const teacherLoginElement = renderTeacherLogin(selfStudent)
    return (
      <div className="full-height">
        {teacherLoginElement}
        <div className={fullSize}>{children}</div>
      </div>
    )
  }

  const renderEverything = (): React.ReactElement => {
    const teacherLoginElement = renderTeacherLogin(selfStudent)

    const teacherLoginClass = selfStudent.isTeacher ? teacherLogin : null
    const contentClass = [dashboardContent, teacherLoginClass].join(' ')

    return (
      <>
        <div id="dashboard" className="full-height">
          {teacherLoginElement}

          <div id="dashboard-content" className={contentClass}>
            {children}
          </div>
        </div>

        <FastExitButton />

        <StarIntegrationLinkedModal
          show={modal === 'star-integration-linked'}
          onHideModal={hideModal}
        />
      </>
    )
  }

  return isFullScreen === true ? renderChildOnly() : renderEverything()
}
