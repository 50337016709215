import {fromJust} from '@freckle/maybe'
import {type ParserT, Parser} from '@freckle/parser'

export type LegacyLoginError =
  | {
      errorType:
        | 'no-course'
        | 'no-student'
        | 'student-name-swapped'
        | 'archived'
        | 'teacher-deleted'
        | 'password-invalid'
    }
  | {errorType: 'similar-course'; errorData: {code: string}}
  | {errorType: 'similar-first-name' | 'similar-last-name'; errorData: {name: string}}

const parseLegacyLoginError = (
  errorType: string,
  mData: string | null | undefined
): LegacyLoginError => {
  switch (errorType) {
    case 'no-course':
    case 'no-student':
    case 'student-name-swapped':
    case 'archived':
    case 'teacher-deleted':
    case 'password-invalid':
      return {errorType}
    case 'similar-course':
      const code = fromJust(mData, 'No similar course sent in response')
      return {
        errorType,
        errorData: {code}
      }
    case 'similar-first-name':
    case 'similar-last-name':
      const name = fromJust(mData, 'No similar name sent in response')
      return {
        errorType,
        errorData: {name}
      }
    default:
      throw new Error(`Unknown error type: ${errorType}`)
  }
}

export function legacyLoginErrorParser(): ParserT<LegacyLoginError> {
  const expected = 'legacyLoginError()'
  return {
    type: 'parser',
    expected,
    parse: x => {
      try {
        const [mErrorType, mData] = x
        const errorType = fromJust(mErrorType, 'Invalid login error response')
        const parsed = parseLegacyLoginError(errorType, mData)
        return Parser.ok(parsed)
      } catch {
        return Parser.fail({expected, got: x})
      }
    }
  }
}
