import {ajaxJsonCall} from '@freckle/ajax'
import CApiHelper from '@freckle/student-entities/ts/common/helpers/common-api-helper'
import {type ParserT, record, number, Parser} from '@freckle/parser'
import {type UnixTimespanT} from '@freckle/student/ts/common/logic/assignments'

type IncompleteAssignmentCountT = {
  count: number
}

const parse: ParserT<IncompleteAssignmentCountT> = record({
  count: number()
})

const parseIncompleteAssignmentCount = Parser.mkRun<IncompleteAssignmentCountT>(parse)

export async function fetchIncompleteAssignmentCount(
  mathStandardSetId: string,
  timespan?: UnixTimespanT | null
): Promise<IncompleteAssignmentCountT> {
  const data = timespan === null || timespan === undefined ? {} : timespan
  return ajaxJsonCall({
    data,
    url: `${CApiHelper.fancyPaths.v2.students.me_incomplete_assignment_count()}?math-standard-set-id=${mathStandardSetId}`,
    method: 'GET'
  }).then(parseIncompleteAssignmentCount)
}
