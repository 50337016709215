import type {ElaAssignmentTypeForDisplayT} from '@freckle/student-entities/ts/common/logic/shared-assignment-properties'
import {type UnitExerciseT} from '@freckle/student-entities/ts/common/logic/shared-assignment-properties'
import {type SubjectT} from '@freckle/student-entities/ts/common/helpers/subjects'
import {toFragment} from '@freckle/student-entities/ts/common/helpers/routers/query-params'
import {type RlDomainIdT} from '@freckle/student-entities/ts/common/types/rl-domain-id'
import {type RlStandardIdT} from '@freckle/student-entities/ts/common/types/rl-standard-id'
import {type SelfAssignedTargetedPracticeTypeT} from '@freckle/student-entities/ts/math/common/models/targeted-practice-type/self-assigned-targeted-practice-type'
import {type TabT} from '@freckle/student/ts/common/components/navigation/start-page-assignments/types/tabs'
import {type TimeSpanQueryParamsT} from '@freckle/student/ts/common/components/navigation/common/logic/time-span'
import type {LangT} from '@freckle/student-materials/src/helpers/languages'
import type {PathParam} from 'react-router-dom'
import {generatePath} from 'react-router-dom'
import {exhaustive} from '@freckle/exhaustive'

import type {CommonRoutesWithQueryParams} from './common'
import {commonPathData} from './common'
import type {PathData} from './path-data'
import type {ElaRoutesWithQueryParams} from './ela'
import {elaPathData} from './ela'
import type {UnitRoutesWithQueryParams} from './unit'
import {unitPathData} from './unit'
import type {MathRoutesWithQueryParams} from './math'
import {mathPathData} from './math'

export type RoutesWithQueryParams = CommonRoutesWithQueryParams &
  ElaRoutesWithQueryParams &
  UnitRoutesWithQueryParams &
  MathRoutesWithQueryParams

export const pathData: PathData<RoutesWithQueryParams> = {
  ...(commonPathData as PathData<RoutesWithQueryParams>),
  ...(elaPathData as PathData<RoutesWithQueryParams>),
  ...(unitPathData as PathData<RoutesWithQueryParams>),
  ...(mathPathData as PathData<RoutesWithQueryParams>)
}

export function mkUrl<A extends keyof RoutesWithQueryParams>(
  path: A,
  pathParams: {[key in PathParam<A>]: string | null},
  queryParams: RoutesWithQueryParams[A]
): string {
  return toFragment(generatePath(path, pathParams), queryParams)
}

export const Routes = {
  directLogin: () => mkUrl('/direct-login', {}, undefined),
  login: () => mkUrl('/login', {}, undefined),
  loginQr: () => mkUrl('/login-qr', {}, undefined),
  selectClass: () => mkUrl('/select-class', {}, undefined),
  signout: () => mkUrl('/signout', {}, undefined),
  classCodeHelp: () => mkUrl('/class-code-help', {}, undefined),
  start: (teacher?: boolean) => mkUrl('/start', {}, {teacher}),
  startAssignments: (timeSpan?: TimeSpanQueryParamsT | null, tab?: TabT) =>
    mkUrl(
      '/start/assignments',
      {},
      {
        timeSpan,
        tab
      }
    ),
  store: () => mkUrl('/store', {}, {}),
  storeClosed: (redirect?: string) => mkUrl('/store-closed', {}, {redirect}),
  storeOpen: () => mkUrl('/store-open', {}, undefined),
  changeTheme: () => mkUrl('/change-theme', {}, undefined),
  storeRedirect: (redirect: string) => mkUrl('/store', {}, {redirect}),
  debugstore: () => mkUrl('/debugStore', {}, undefined),
  debugDepthOfKnowledge: (questionSetUuid: string) =>
    mkUrl('/depth-of-knowledge/debug/:questionSetUuid', {questionSetUuid}, undefined),
  onboardingBypassCheck: () => mkUrl('/onboarding', {}, {bypassCheck: true}),
  onboarding: () => mkUrl('/onboarding', {}, {}),
  ela: {
    menu: () => mkUrl('/ela/menu', {}, undefined),
    assignmentsTypeAssignmentId: (type: ElaAssignmentTypeForDisplayT, assignmentId: number) => {
      switch (type) {
        case 'writing':
        case 'reading':
        case 'assessment':
        case 'word-study':
        case 'decodable':
          return mkUrl(
            `/ela/assignments/${type}/:assignmentIds`,
            {
              assignmentIds: assignmentId.toString()
            },
            undefined
          )
        case 'adaptive-grammar':
        case 'adaptive-skills':
        case 'targeted-grammar':
          throw new Error(`There is no handler for ${type}`)
        default:
          return exhaustive(type)
      }
    },

    assignmentsSelfAssignedReading: (articleUuid: string) =>
      mkUrl('/ela/assignments/self-assigned-reading/:articleUuid', {articleUuid}, undefined),
    decodableSession: (decodableUuid: string) =>
      mkUrl('/ela/decodables/:decodableUuid', {decodableUuid}, undefined),
    diagnostic: () => mkUrl('/ela/diagnostic', {}, undefined),
    library: () => mkUrl('/ela/library', {}, undefined),
    libraryCategories: (type: string) =>
      mkUrl(
        '/ela/library/:elaLibraryContentType/categories',
        {
          elaLibraryContentType: type
        },
        undefined
      ),
    libraryCategory: (type: string, category: string) =>
      mkUrl(
        '/ela/library/:elaLibraryContentType/categories/:category',
        {
          elaLibraryContentType: type,
          category
        },
        undefined
      ),
    decodables: () => mkUrl('/ela/decodables', {}, undefined),
    sightWords: () => mkUrl('/ela/sight-words', {}, undefined),
    wordStudyBriefing: () => mkUrl('/ela/word-study-briefing', {}, undefined),
    wordStudySession: () => mkUrl('/ela/word-study-session', {}, undefined),
    wordStudyAssignment: (assignmentId: number) =>
      mkUrl(
        '/ela/assignments/word-study/:assignmentIds',
        {assignmentIds: assignmentId.toString()},
        undefined
      ),
    wordStudyDiagnostic: () => mkUrl('/ela/word-study-diagnostic', {}, undefined),
    wordStudyIntroDebug: (skillId: string) =>
      mkUrl('/ela/word-study-debug/intros/:uspId', {uspId: skillId}, undefined),
    wordStudyDebug: (uuid: string) =>
      mkUrl('/ela/word-study-debug/questions/:uuid', {uuid}, undefined),
    skillsPractice: {
      domains: () => mkUrl('/ela/skills-practice/domains', {}, undefined),
      domain: (domainId: string) =>
        mkUrl('/ela/skills-practice/domains/:domainId', {domainId}, undefined),
      domainSession: (domainId: string) =>
        mkUrl('/ela/skills-practice/domains/:domainId/session', {domainId}, undefined),
      session: (assignmentId: number) =>
        mkUrl(
          '/ela/skills-practice/assignments/:assignmentIds',
          {
            assignmentIds: assignmentId.toString()
          },
          undefined
        ),
      debugger: (uuid?: string) =>
        mkUrl(
          '/ela/system/skills-practice/debug/:uuid?',
          {
            uuid: uuid === undefined ? null : uuid
          },
          undefined
        )
    },
    targetedSkillsPractice: {
      session: (assignmentId: number) =>
        mkUrl(
          '/ela/system/targeted-skills-practice/assignments/:assignmentIds',
          {
            assignmentIds: assignmentId.toString()
          },
          undefined
        )
    },
    focusSkillsPractice: {
      home: mkUrl('/ela/focus-skills-practice', {}, undefined),
      session: (assignmentId: number) =>
        mkUrl(
          '/ela/focus-skills-practice/assignments/:assignmentIds',
          {
            assignmentIds: assignmentId.toString()
          },
          undefined
        )
    }
  },
  socialStudies: {
    assignmentsTypeAssignmentId: (type: UnitExerciseT, assignmentId: number) =>
      mkUrl(
        `/social-studies/assignments/${type}/:assignmentIds`,
        {assignmentIds: assignmentId.toString()},
        undefined
      )
  },
  science: {
    assignmentsTypeAssignmentId: (type: UnitExerciseT, assignmentId: number) =>
      mkUrl(
        `/science/assignments/${type}/:assignmentIds`,
        {assignmentIds: assignmentId.toString()},
        undefined
      )
  },
  math: {
    menu: () => mkUrl('/math/menu', {}, undefined),
    domains: () => mkUrl('/domains', {}, undefined),
    adaptivePractice: (rlDomainId: RlDomainIdT) =>
      mkUrl('/questions/:rlDomainId', {rlDomainId}, undefined),
    targetedSelfAssigned: (
      domainId: RlDomainIdT,
      standardId: RlStandardIdT,
      practiceType: SelfAssignedTargetedPracticeTypeT
    ) => mkUrl('/questions/:domainId/:standardId', {domainId, standardId}, practiceType),
    debugQuestions: (shortId: string) =>
      mkUrl('/debugquestions/:questionId?', {questionId: shortId.toString()}, undefined),
    briefingDomainAdaptive: (rlDomainId: RlDomainIdT) =>
      mkUrl('/briefing/:rlDomainId/adaptive', {rlDomainId}, undefined),
    factPractice: () => mkUrl('/fact-practice', {}, undefined),
    numberFacts: () => mkUrl('/math/number-facts', {}, undefined),
    numberBasics: {
      briefing: () => mkUrl('/math/number-basics', {}, undefined),
      session: () => mkUrl('/math/number-basics/session', {}, undefined),
      question: (questionUuid: string) =>
        mkUrl('/math/number-basics/questions/:questionUuid', {questionUuid}, undefined)
    },
    focusSkillsRemediation: () => mkUrl('/math/focus-skills-remediation', {}, undefined),
    numberFactsProgress: () => mkUrl('/math/number-facts/progress', {}, undefined),
    numberFactsAssignmentSession: (assignmentId: number) =>
      mkUrl(
        '/math/number-facts/assignments/:assignmentIds/session',
        {assignmentIds: assignmentId.toString()},
        undefined
      ),
    depthOfKnowledge: (standardId: RlStandardIdT) =>
      mkUrl('/math/depth-of-knowledge/:standardId', {standardId}, undefined),
    targetedDepthOfKnowledge: (assignmentId: number) =>
      mkUrl(
        '/math/depth-of-knowledge/targeted/assignments/:assignmentIds',
        {assignmentIds: assignmentId.toString()},
        undefined
      ),
    highSchoolCourses: () => mkUrl('/math/high-school-courses', {}, undefined),
    courseBriefing: (skillSetId: number) =>
      mkUrl('/math/course/:skillSetId', {skillSetId: skillSetId.toString()}, undefined),
    editor: () => mkUrl('/math/editor', {}, undefined),
    questionSkillsMathViewer: () =>
      mkUrl('/debuggers/math/question-skills-math-viewer', {}, undefined),
    debugMathJax: () => mkUrl('/debuggers/mathjax', {}, undefined),
    debugWorkedExample: (stemId: string, lang?: LangT) =>
      mkUrl('/debuggers/worked-example/:stemId', {stemId}, {lang}),
    hintDebug: () => mkUrl(`/math/practice/hint/debug`, {}, undefined)
  },
  adaptiveAssignmentsSession: (id: number) =>
    mkUrl(
      '/assignments/adaptive/:assignmentIds/session',
      {assignmentIds: id.toString()},
      undefined
    ),
  assessmentAssignmentsSession: (id: number) =>
    mkUrl(
      '/assignments/assessment/:assignmentIds/session',
      {assignmentIds: id.toString()},
      undefined
    ),
  iblAssignmentsSession: (id: number) =>
    mkUrl('/assignments/ibl/:assignmentIds/session', {assignmentIds: id.toString()}, undefined),
  targetedAssignmentsSession: (
    id: number,
    practiceType?: SelfAssignedTargetedPracticeTypeT | null,
    alwaysCollectSentiment?: boolean
  ) =>
    mkUrl(
      '/assignments/targeted/:assignmentIds/session',
      {assignmentIds: id.toString()},
      {
        ...(!practiceType ? {} : practiceType),
        ...(alwaysCollectSentiment === undefined ? {} : {alwaysCollectSentiment})
      }
    ),
  factPracticeAssignmentsSession: (id: number) =>
    mkUrl(
      '/assignments/fact-practice/:assignmentIds/session',
      {assignmentIds: id.toString()},
      undefined
    ),
  assignmentNotFound: (subject: SubjectT) =>
    mkUrl('/assignments/:subject/not-found', {subject}, undefined),
  errorPage: () => mkUrl(`/error`, {}, undefined)
}
