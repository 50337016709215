import filter from 'lodash/filter'
import isString from 'lodash/isString'
import isNumber from 'lodash/isNumber'
import isDate from 'lodash/isDate'
import isBoolean from 'lodash/isBoolean'
import map from 'lodash/map'
import isArray from 'lodash/isArray'
import reduce from 'lodash/reduce'
import {mmap} from '@freckle/maybe'

const ARRAY_VALUE_SPLIT = '|'

export function extractQueryParamsTuples(
  search: string
): Array<[string, string]> | undefined | null {
  const cleanedSearch = decodeURIComponent(search).replace('?', '').trim()

  if (cleanedSearch.length === 0) {
    return null
  }

  const searchValues = cleanedSearch.split('&')

  return map(searchValues, queryItem => {
    const keyVal = queryItem.split('=')
    return [keyVal[0], keyVal[1]]
  })
}

export const extractQueryParamsObject = (
  search: string
):
  | {
      [name: string]: string
    }
  | undefined
  | null =>
  mmap(
    t =>
      reduce<
        [string, string],
        {
          [name: string]: string
        }
      >(
        t,
        (o, [k, v]) => {
          o[k] = v
          return o
        },
        {}
      ),
    extractQueryParamsTuples(search)
  )

export function extractQueryParams(location: {
  search: string
}): Map<string, string> | undefined | null {
  return mmap(t => new Map(t), extractQueryParamsTuples(location.search))
}

export function parseQueryParamArray<T>(pathArray: string, callback: (arg: string) => T): Array<T> {
  const args = pathArray.split(ARRAY_VALUE_SPLIT)
  return map(
    filter(args, arg => arg.trim().length > 0),
    callback
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toFragment(route: string, queryParams?: any | null): string {
  if (queryParams !== null && queryParams !== undefined) {
    const queryParamsStr = isString(queryParams) ? queryParams : toQueryString(queryParams)
    return queryParamsStr !== '' ? appendQueryStringToRoute(route, queryParamsStr) : route
  }
  return route
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function appendQueryStringToRoute(route: string, queryString: string | any): string {
  const divider = route.includes('?') ? '&' : '?'
  if (typeof queryString === 'string') {
    return `${route}${divider}${queryString}`
  } else {
    return `${route}${divider}${toQueryString(queryString)}`
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function toQueryString(params?: any | null, namePrefix: string = ''): string {
  if (params === null || params === undefined) {
    return ''
  }

  const queryParamsStrArr = map(params, (value, key: string) => {
    const name = `${namePrefix}${key}`
    if (value === null || value === undefined) {
      return ''
    } else if (isPrimitive(value)) {
      return `${name}=${encodeSplit(encodeURIComponent(value))}`
    } else if (isArray(value)) {
      const arrStr = arrayToString(value)
      if (arrStr !== '') {
        return `${name}=${arrStr}`
      }
    } else {
      /* This is an object, we dig in */
      const valueNamePrefix = `${name}.`
      return toQueryString(value, valueNamePrefix)
    }
  })

  return queryParamsStrArr.filter(v => v !== '').join('&')
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isPrimitive(value: any): boolean {
  return isString(value) || isNumber(value) || isBoolean(value) || isDate(value)
}

export function arrayToString(arrayParam: Array<string | number | boolean | null | void>): string {
  /* For arrays, we use the ARRAY_VALUE_SPLIT between values
   * This function expects values to be primitives, it throws an error on
   * non-primitives values
   */
  return reduce(
    arrayParam,
    (acc, arrParam) => {
      if (arrParam !== null && arrParam !== undefined) {
        if (isPrimitive(arrParam)) {
          acc += `${ARRAY_VALUE_SPLIT}${encodeSplit(encodeURIComponent(String(arrParam)))}`
        } else {
          throw new Error('Non-primitive values in array')
        }
      }
      return acc
    },
    ''
  )
}

function encodeSplit(value: string): string {
  return String(value).replace(ARRAY_VALUE_SPLIT, encodeURIComponent(ARRAY_VALUE_SPLIT))
}
