import CApiHelper from '@freckle/student-entities/ts/common/helpers/common-api-helper'
import {type TeacherAttrs, parse} from '@freckle/student-entities/ts/users/models/teacher'
import {apiFetch} from '@freckle/student-entities/ts/common/helpers/api-fetch'

export async function fetchTeacher(currentCourseId: number): Promise<TeacherAttrs> {
  const url = CApiHelper.fancyPaths.v2.courses.teacher(currentCourseId)
  const res = await apiFetch({
    url,
    method: 'GET'
  })
  return parse(res)
}
