import {batch} from 'react-redux'
import {mutate} from 'swr'
import type {ThunkAction} from 'redux-thunk'
import {isFetching} from '@freckle/resource-status'
import {type OnboardingRequiredT} from '@freckle/student-entities/ts/users/models/student'
import {
  type SelfStudentAttrs,
  fetchSelfStudent as callFetchSelfStudent
} from '@freckle/student/ts/users/models/self-student/self-student'
import {type StoreState} from '@freckle/student/ts/reducers/types'
import {FETCH_SELF_STUDENT_SWR_CACHE_KEY} from '@freckle/student/ts/users/models/self-student/use-self-student'

export type SelfStudentActionT =
  | SelfStudentGetRequestActionT
  | SelfStudentGetRefetchRequestActionT
  | SelfStudentGetResponseActionT
  | SelfStudentGetErrorActionT
  | SelfStudentUpdateCoinsT
  | SelfStudentUpdateOnboardingRequiredActionT

type SelfStudentUpdateCoinsT = {
  type: 'SELF_STUDENT_UPDATE_COINS'
  coins: CoinsUpdateT
}

export type CoinsUpdateT = {
  coinsTotal: number
  coinsToday: number
}

export function selfStudentUpdateCoinsAction(coins: CoinsUpdateT): SelfStudentUpdateCoinsT {
  // mutate in SWR
  mutate<SelfStudentAttrs>(
    FETCH_SELF_STUDENT_SWR_CACHE_KEY,
    async student => {
      return student
        ? {
            ...student,
            ...coins
          }
        : undefined
    },
    // It is hard to be sure when the coins get updated in DB
    // so we will skip revalidation here.
    {revalidate: false}
  )
  return {type: 'SELF_STUDENT_UPDATE_COINS', coins}
}

type SelfStudentUpdateOnboardingRequiredActionT = {
  type: 'SELF_STUDENT_UPDATE_ONBOARDING_REQUIRED'
  onboardingRequired: OnboardingRequiredT
}

export const selfStudentUpdateOnboardingRequiredAction = (
  onboardingRequired: OnboardingRequiredT
): SelfStudentUpdateOnboardingRequiredActionT => ({
  type: 'SELF_STUDENT_UPDATE_ONBOARDING_REQUIRED',
  onboardingRequired
})

type SelfStudentGetRequestActionT = {
  type: 'SELF_STUDENT_GET_REQUEST'
}

function selfStudentGetRequestAction(): SelfStudentGetRequestActionT {
  return {type: 'SELF_STUDENT_GET_REQUEST'}
}

type SelfStudentGetRefetchRequestActionT = {
  type: 'SELF_STUDENT_GET_REFETCH_REQUEST'
  selfStudent: SelfStudentAttrs
}

function selfStudentGetRefetchRequestAction(
  selfStudent: SelfStudentAttrs
): SelfStudentGetRefetchRequestActionT {
  return {type: 'SELF_STUDENT_GET_REFETCH_REQUEST', selfStudent}
}

type SelfStudentGetResponseActionT = {
  type: 'SELF_STUDENT_GET_RESPONSE'
  selfStudent: SelfStudentAttrs
}

function selfStudentGetResponseAction(
  selfStudent: SelfStudentAttrs
): SelfStudentGetResponseActionT {
  return {
    type: 'SELF_STUDENT_GET_RESPONSE',
    selfStudent
  }
}

type SelfStudentGetErrorActionT = {
  type: 'SELF_STUDENT_GET_ERROR'
  error: unknown
}

function selfStudentGetErrorAction(error: unknown): SelfStudentGetErrorActionT {
  return {type: 'SELF_STUDENT_GET_ERROR', error}
}

export function loadSelfStudent(): ThunkAction<void, StoreState, unknown, SelfStudentActionT> {
  return async (dispatch, getState) => {
    const {selfStudent, settings: _settings} = getState()
    if (isFetching(selfStudent)) {
      return
    }
    if (selfStudent.status === 'complete') {
      dispatch(selfStudentGetRefetchRequestAction(selfStudent.data))
    } else {
      dispatch(selfStudentGetRequestAction())
    }

    // tell SWR to reload
    mutate(FETCH_SELF_STUDENT_SWR_CACHE_KEY)

    try {
      const student = await callFetchSelfStudent()
      batch(() => {
        dispatch(selfStudentGetResponseAction(student))
      })
    } catch (error) {
      dispatch(selfStudentGetErrorAction(error))
    }
  }
}
