import {useCallback, useState, useEffect} from 'react'
import debounce from 'lodash/debounce'

type WindowSizeT = {
  width: number
  height: number
}

export const WINDOW_RESIZE_DEBOUNCE_MS = 200

export function useWindowSize(): WindowSizeT {
  const getSize = useCallback(
    () => ({
      width: window.innerWidth,
      height: window.innerHeight
    }),
    []
  )

  const [windowSize, setWindowSize] = useState<WindowSizeT>(getSize)

  useEffect(() => {
    const handleResize = () => setWindowSize(getSize())
    const debouncedResize = debounce(handleResize, WINDOW_RESIZE_DEBOUNCE_MS)

    window.addEventListener('resize', debouncedResize)
    return () => window.removeEventListener('resize', debouncedResize)
  }, [getSize])

  return windowSize
}
