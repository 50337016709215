import * as React from 'react'
import type {NavigateFunction} from 'react-router-dom'
import {useLocation, useNavigate} from 'react-router-dom'
import identity from 'lodash/identity'
import {
  useSafeCallback,
  useSafeCallbackExtraDeps,
  useSafeEffectExtraDeps
} from '@freckle/react-hooks'
import {api, fetch} from '@freckle/student-entities/ts/common/helpers/api-client'
import {checkStudentCookie} from '@freckle/student-entities/ts/common/helpers/api-helper'
import GeneralHelper from '@freckle/student/ts/common/helpers/general'

import {Routes} from './../routes'

const AUTH_CHECK_RETRY_INTERVAL = 1000 * 60 * 15 // 15 minutes

const MAX_LOGIN_PAGE_RETRIES = 16

const shouldCheckCookie = (pathname: string) =>
  !(
    pathname.includes('login') ||
    pathname.includes('login-qr') ||
    pathname.includes('class-code-help')
  )

export const AuthCheck = (): React.ReactElement => {
  const location = useLocation()
  const navigate = useNavigate()
  const [loginRetries, setLoginRetries] = React.useState(0)

  const checkLoginRetries = useSafeCallback(
    () => () => {
      if (loginRetries < MAX_LOGIN_PAGE_RETRIES) {
        setLoginRetries(loginRetries + 1)
      } else {
        document.location.reload()
      }
    },
    [loginRetries]
  )

  const checkAuth = useSafeCallback(
    () => () => {
      fetch(api.getAuthCheckR, identity).catch(() => {
        document.location.reload()
      })
    },
    []
  )

  const onWindowFocus = useSafeCallbackExtraDeps<
    () => void,
    {
      navigate: NavigateFunction
    }
  >(
    ({navigate}) =>
      () => {
        checkStudentCookie().catch(() => {
          const msg = `You have been disconnected from Freckle. Please login again.`
          GeneralHelper.displayNotification('error', msg, 3, () => {
            navigate(Routes.login(), {replace: true})
            document.location.reload()
          })
        })
      },
    [],
    {navigate: {value: navigate, comparator: () => true}}
  )

  useSafeEffectExtraDeps<{
    checkLoginRetries: () => void
    onWindowFocus: () => void
    checkAuth: () => void
  }>(
    ({checkLoginRetries, onWindowFocus, checkAuth}) => {
      const authCheck = location.pathname === '/login' ? checkLoginRetries : checkAuth
      const timeoutId = setInterval(authCheck, AUTH_CHECK_RETRY_INTERVAL)
      if (shouldCheckCookie(location.pathname)) {
        window.addEventListener('focus', onWindowFocus)
      }

      return () => {
        clearInterval(timeoutId)
        window.removeEventListener('focus', onWindowFocus)
      }
    },
    [location.pathname],
    {checkLoginRetries, onWindowFocus, checkAuth}
  )

  return <></>
}
