import * as React from 'react'
import {checkInternetConnectivity} from '@freckle/student-entities/ts/common/helpers/common-api-helper'
import {api, getUrl} from '@freckle/student-entities/ts/common/helpers/api-client'

import GeneralHelper from './../../helpers/general'

const CONNECTIVITY_CHECK_RETRY_SECONDS = 30
const CONNECTIVITY_CHECK_RETRY_SECONDS_ON_FAIL = 5 // 5 seconds

const checkConnectivity = () => {
  const successCallback = () => {
    // remove fogginess and undisplay overlay when the connection is fine
    GeneralHelper.hideOverlay()
  }

  const failureCallback = () => {
    const failedRetryPeriod = CONNECTIVITY_CHECK_RETRY_SECONDS_ON_FAIL
    const msg = `Experiencing connection issues, retrying in ${failedRetryPeriod}s`
    GeneralHelper.displayNotification('error', msg, failedRetryPeriod)
    GeneralHelper.showOverlay()
  }
  getUrl(api.getStudentPingR).then(pingUrl =>
    checkInternetConnectivity(
      pingUrl,
      CONNECTIVITY_CHECK_RETRY_SECONDS * 1000,
      CONNECTIVITY_CHECK_RETRY_SECONDS_ON_FAIL * 1000,
      successCallback,
      failureCallback
    )
  )
}

export const ConnectivityCheck = (): React.ReactElement => {
  // N.B.: checkConnectivity should only ever be called once during the lifetime of the App
  React.useEffect(() => {
    checkConnectivity()
  })

  return <></>
}
