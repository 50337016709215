import {HandledError} from './handled-error'

type Props = {
  message: string
  redirectUrl: string
}

export class NotFoundRedirect extends HandledError {
  redirectUrl: string

  constructor({message, redirectUrl}: Props) {
    super(message)
    this.name = 'NotFoundRedirect'
    this.redirectUrl = redirectUrl
  }
}
