import find from 'lodash/find'
import {fromJust} from '@freckle/maybe'
import {exhaustive} from '@freckle/exhaustive'

import {type SubjectPathT} from './subjects'
import {Subjects, SubjectPaths} from './subjects'

export type MathAdaptiveT = 'math_adaptive'
export type MathTargetedT = 'math_targeted'
export type FocusSkillsPracticeT = 'focus_skills_practice'
export type MathAssessmentsT = 'math_assessments'
export type MathFactPracticeT = 'math_fact_practice'
export type MathIblsT = 'math_ibls'
export type MathNumberFactsT = 'math_number_facts'
export type MathNumberBasicsT = 'math_number_basics'
export type MathDepthOfKnowledgePracticeT = 'math_depth_of_knowledge_practice'
export type MathTargetedDepthOfKnowledgePracticeT = 'math_targeted_depth_of_knowledge_practice'
export type ElaDecodablesT = 'ela_decodables'
export type ElaTargetedSkillsPracticeT = 'ela_targeted_skills_practice'
export type ElaAdaptiveSkillsPracticeT = 'ela_adaptive_skills_practice'
export type ElaFocusSkillsPracticeT = 'ela_focus_skills_practice'
export type ElaWordStudyT = 'ela_word_study'
export type ElaSightWordsT = 'ela_sight_words'
export type ElaArticlesReadingT = 'ela_articles_reading'
export type ElaArticlesWritingT = 'ela_articles_writing'
export type ElaAssessmentsT = 'ela_assessments'
export type ScienceArticlesReadingT = 'science_articles_reading'
export type ScienceArticlesWritingT = 'science_articles_writing'
export type SocialStudiesArticlesReadingT = 'social_studies_articles_reading'
export type SocialStudiesArticlesWritingT = 'social_studies_articles_writing'

export type ProductT =
  | MathAdaptiveT
  | MathTargetedT
  | FocusSkillsPracticeT
  | MathAssessmentsT
  | MathFactPracticeT
  | MathIblsT
  | MathNumberFactsT
  | MathNumberBasicsT
  | MathDepthOfKnowledgePracticeT
  | MathTargetedDepthOfKnowledgePracticeT
  | ElaDecodablesT
  | ElaTargetedSkillsPracticeT
  | ElaAdaptiveSkillsPracticeT
  | ElaFocusSkillsPracticeT
  | ElaWordStudyT
  | ElaSightWordsT
  | ElaArticlesReadingT
  | ElaArticlesWritingT
  | ElaAssessmentsT
  | ScienceArticlesReadingT
  | ScienceArticlesWritingT
  | SocialStudiesArticlesReadingT
  | SocialStudiesArticlesWritingT

type MathProductPathT =
  | 'adaptive'
  | 'targeted'
  | 'focus-skills-practice'
  | 'assessments'
  | 'fact-practice'
  | 'ibls'
  | 'number-facts'
  | 'number-basics'
  | 'depth-of-knowledge-practice'
  | 'targeted-depth-of-knowledge-practice'
type ElaProductPathT =
  | 'assessments'
  | 'articles-reading'
  | 'articles-writing'
  | 'decodables'
  | 'targeted-skills-practice'
  | 'adaptive-skills-practice'
  | 'ela_focus_skills_practice'
  | 'word-study'
  | 'sight-words'
type ArticlePathT = 'articles-reading' | 'articles-writing'

type ProductPathT = MathProductPathT | ElaProductPathT | ArticlePathT

type ProductPathsT = {
  subjectPath: SubjectPathT
  productPath: ProductPathT
}

export const Products = {
  MathAdaptive: 'math_adaptive' as MathAdaptiveT,
  MathTargeted: 'math_targeted' as MathTargetedT,
  FocusSkillsPractice: 'focus_skills_practice' as FocusSkillsPracticeT,
  MathAssessments: 'math_assessments' as MathAssessmentsT,
  MathFactPractice: 'math_fact_practice' as MathFactPracticeT,
  MathIbls: 'math_ibls' as MathIblsT,
  MathNumberFacts: 'math_number_facts' as MathNumberFactsT,
  MathNumberBasics: 'math_number_basics' as MathNumberBasicsT,
  MathDepthOfKnowledgePractice: 'math_depth_of_knowledge_practice' as MathDepthOfKnowledgePracticeT,
  MathTargetedDepthOfKnowledgePractice:
    'math_targeted_depth_of_knowledge_practice' as MathTargetedDepthOfKnowledgePracticeT,
  ElaDecodables: 'ela_decodables' as ElaDecodablesT,
  ElaTargetedSkillsPractice: 'ela_targeted_skills_practice' as ElaTargetedSkillsPracticeT,
  ElaAdaptiveSkillsPractice: 'ela_adaptive_skills_practice' as ElaAdaptiveSkillsPracticeT,
  ElaFocusSkillsPractice: 'ela_focus_skills_practice' as ElaFocusSkillsPracticeT,
  ElaWordStudy: 'ela_word_study' as ElaWordStudyT,
  ElaSightWords: 'ela_sight_words' as ElaSightWordsT,
  ElaArticlesReading: 'ela_articles_reading' as ElaArticlesReadingT,
  ElaArticlesWriting: 'ela_articles_writing' as ElaArticlesWritingT,
  ElaAssessments: 'ela_assessments' as ElaAssessmentsT,
  ScienceArticlesReading: 'science_articles_reading' as ScienceArticlesReadingT,
  ScienceArticlesWriting: 'science_articles_writing' as ScienceArticlesWritingT,
  SocialStudiesArticlesReading: 'social_studies_articles_reading' as SocialStudiesArticlesReadingT,
  SocialStudiesArticlesWriting: 'social_studies_articles_writing' as SocialStudiesArticlesWritingT,

  parse(text: string): ProductT | undefined | null {
    return find(Products.all(), value => value === text)
  },

  parseThrows(text: string): ProductT {
    return fromJust(Products.parse(text), `Invalid product type ${text}`)
  },

  getMathProducts(): Array<ProductT> {
    return [
      Products.MathAdaptive,
      Products.MathTargeted,
      Products.FocusSkillsPractice,
      Products.MathAssessments,
      Products.MathFactPractice,
      Products.MathIbls,
      Products.MathNumberFacts,
      Products.MathNumberBasics,
      Products.MathDepthOfKnowledgePractice,
      Products.MathTargetedDepthOfKnowledgePractice
    ]
  },

  getElaProducts(): Array<ProductT> {
    return [
      Products.ElaDecodables,
      Products.ElaTargetedSkillsPractice,
      Products.ElaAdaptiveSkillsPractice,
      Products.ElaWordStudy,
      Products.ElaSightWords,
      Products.ElaArticlesReading,
      Products.ElaArticlesWriting,
      Products.ElaAssessments
    ]
  },

  getScienceProducts(): Array<ProductT> {
    return [Products.ScienceArticlesReading, Products.ScienceArticlesWriting]
  },

  getSocialStudiesProducts(): Array<ProductT> {
    return [Products.SocialStudiesArticlesReading, Products.SocialStudiesArticlesWriting]
  },

  all(): Array<ProductT> {
    return [
      ...this.getMathProducts(),
      ...this.getElaProducts(),
      ...this.getScienceProducts(),
      ...this.getSocialStudiesProducts()
    ]
  },

  getPaths(product: ProductT): ProductPathsT {
    const mathSubjectPath = SubjectPaths.getSubjectPath(Subjects.math)
    const elaSubjectPath = SubjectPaths.getSubjectPath(Subjects.ela)
    const scienceSubjectPath = SubjectPaths.getSubjectPath(Subjects.science)
    const socialStudiesSubjectPath = SubjectPaths.getSubjectPath(Subjects.socialStudies)

    switch (product) {
      case 'math_adaptive':
        return {subjectPath: mathSubjectPath, productPath: 'adaptive'}
      case 'math_targeted':
        return {subjectPath: mathSubjectPath, productPath: 'targeted'}
      case 'focus_skills_practice':
        return {subjectPath: mathSubjectPath, productPath: 'focus-skills-practice'}
      case 'math_assessments':
        return {subjectPath: mathSubjectPath, productPath: 'assessments'}
      case 'math_fact_practice':
        return {subjectPath: mathSubjectPath, productPath: 'fact-practice'}
      case 'math_ibls':
        return {subjectPath: mathSubjectPath, productPath: 'ibls'}
      case 'math_number_facts':
        return {subjectPath: mathSubjectPath, productPath: 'number-facts'}
      case 'math_number_basics':
        return {subjectPath: mathSubjectPath, productPath: 'number-basics'}
      case 'math_depth_of_knowledge_practice':
        return {subjectPath: mathSubjectPath, productPath: 'depth-of-knowledge-practice'}
      case 'math_targeted_depth_of_knowledge_practice':
        return {subjectPath: mathSubjectPath, productPath: 'targeted-depth-of-knowledge-practice'}

      case 'ela_decodables':
        return {subjectPath: elaSubjectPath, productPath: 'decodables'}
      case 'ela_targeted_skills_practice':
        return {subjectPath: elaSubjectPath, productPath: 'targeted-skills-practice'}
      case 'ela_adaptive_skills_practice':
        return {subjectPath: elaSubjectPath, productPath: 'adaptive-skills-practice'}
      case 'ela_focus_skills_practice':
        return {subjectPath: elaSubjectPath, productPath: 'focus-skills-practice'}
      case 'ela_word_study':
        return {subjectPath: elaSubjectPath, productPath: 'word-study'}
      case 'ela_sight_words':
        return {subjectPath: elaSubjectPath, productPath: 'sight-words'}
      case 'ela_articles_reading':
        return {subjectPath: elaSubjectPath, productPath: 'articles-reading'}
      case 'ela_articles_writing':
        return {subjectPath: elaSubjectPath, productPath: 'articles-writing'}
      case 'ela_assessments':
        return {subjectPath: elaSubjectPath, productPath: 'assessments'}

      case 'science_articles_reading':
        return {subjectPath: scienceSubjectPath, productPath: 'articles-reading'}
      case 'science_articles_writing':
        return {subjectPath: scienceSubjectPath, productPath: 'articles-writing'}

      case 'social_studies_articles_reading':
        return {subjectPath: socialStudiesSubjectPath, productPath: 'articles-reading'}
      case 'social_studies_articles_writing':
        return {subjectPath: socialStudiesSubjectPath, productPath: 'articles-writing'}

      default:
        return exhaustive(product, 'ProductT')
    }
  },
  getAbbreviatedProduct(product: ProductT): string {
    switch (product) {
      case 'math_adaptive':
        return 'adaptive'
      case 'math_targeted':
        return 'targeted'
      case 'focus_skills_practice':
        return 'focus-skills-practice'
      case 'math_assessments':
        return 'assessments'
      case 'math_fact_practice':
        return 'fact-practice'
      case 'math_ibls':
        return 'ibls'
      case 'math_number_facts':
        return 'number-facts'
      case 'math_number_basics':
        return 'number-basics'
      case 'math_depth_of_knowledge_practice':
        return 'depth-of-knowledge-practice'
      case 'math_targeted_depth_of_knowledge_practice':
        return 'targeted-depth-of-knowledge-practice'

      case 'ela_decodables':
        return 'decodables'
      case 'ela_targeted_skills_practice':
        return 'targeted-skills-practice'
      case 'ela_adaptive_skills_practice':
        return 'adaptive-skills-practice'
      case 'ela_focus_skills_practice':
        return 'focus-skills-practice'
      case 'ela_word_study':
        return 'word-study'
      case 'ela_sight_words':
        return 'sight-words'
      case 'ela_articles_reading':
        return 'articles-reading'
      case 'ela_articles_writing':
        return 'articles-writing'
      case 'ela_assessments':
        return 'assessments'

      case 'science_articles_reading':
        return 'articles-reading'
      case 'science_articles_writing':
        return 'articles-writing'

      case 'social_studies_articles_reading':
        return 'articles-reading'
      case 'social_studies_articles_writing':
        return 'articles-writing'

      default:
        return exhaustive(product, 'ProductT')
    }
  }
}
