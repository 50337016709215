import map from 'lodash/map'
import find from 'lodash/find'
import filter from 'lodash/filter'
import uniqBy from 'lodash/uniqBy'
import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'
import toPairs from 'lodash/toPairs'
import flatMap from 'lodash/flatMap'
import minBy from 'lodash/minBy'
import first from 'lodash/first'
import {fromJust} from '@freckle/maybe'
import {ajaxJsonCall} from '@freckle/ajax'
import {type LangT, Languages} from '@freckle/student-materials/src/helpers/languages'
import {PATHS} from '@freckle/student-materials/src/helpers/paths'
import {
  type ParserT,
  Parser,
  array,
  boolean,
  number,
  string,
  record,
  merge,
  nullable
} from '@freckle/parser'
import {
  type ContentAreaT,
  ContentAreas
} from '@freckle/student-entities/ts/common/helpers/content-area'
import {type RlStandardIdT} from '@freckle/student-entities/ts/common/types/rl-standard-id'
import * as RlStandardId from '@freckle/student-entities/ts/common/types/rl-standard-id'
import {type RlDomainIdT} from '@freckle/student-entities/ts/common/types/rl-domain-id'
import * as RlDomainId from '@freckle/student-entities/ts/common/types/rl-domain-id'
import {exhaustive} from '@freckle/exhaustive'

import {parseDomainAttrs} from './rl-domain'
import {type RlDomainT} from './rl-domain'
import {type HTMLText, htmlTextParser} from './html-text'

type RlStandardShared = {
  id: RlStandardIdT
  name: string // Standard "Code," e.g. "K.CC.1"
  shortName: HTMLText // "Name" for the standard, e.g. "Count to 100"
  description: HTMLText // Longer form, e.g. "Count to 100 by ones and by tens."
  progressionOrder: number
  grade: number
  domainId: RlDomainIdT
  hasElaGrammarQuestions?: boolean | null
  hasElaPathwayContent?: boolean | null
  isStudentVisible: boolean
}

export type RlStandardT = {
  // TODO: remove this nullable type and use RlStandardWithDomainT when appropriate
  // https://app.asana.com/0/149473556304568/1200915504043803/f
  domain?: RlDomainT | null
} & RlStandardShared

export type RlStandardWithDomainT = {
  domain: RlDomainT
} & RlStandardShared

// TODO: This function is here for transitionary purposes only. It can be removed once the `domain` is removed from RlStandardT
// https://app.asana.com/0/149473556304568/1200915504043803/f
export const toRlStandard = (rlStandardWithDomain: RlStandardWithDomainT): RlStandardT => ({
  ...rlStandardWithDomain
})

export type RlStandardsForDomainT = {
  tag: 'standards-for-domain'
  rlStandards: Array<RlStandardT>
}

export function makeRlStandardsForDomain(rlStandards: Array<RlStandardT>): RlStandardsForDomainT {
  return {tag: 'standards-for-domain', rlStandards}
}

export const parseStandardAttrs: ParserT<RlStandardT> = record({
  id: RlStandardId.parse,
  name: string(),
  shortName: htmlTextParser,
  description: htmlTextParser,
  progressionOrder: number(),
  grade: number(),
  domainId: RlDomainId.parse,
  domain: nullable(parseDomainAttrs),
  // standards.json always has the following
  // but other API endpoints might not.
  hasElaGrammarQuestions: nullable(boolean()),
  hasElaPathwayContent: nullable(boolean()),
  isStudentVisible: boolean()
})

export const parseRlStandards = Parser.mkRun<Array<RlStandardT>>(array(parseStandardAttrs))

const parseDomainAttrsObj: ParserT<{
  domain: RlDomainT
}> = record({domain: parseDomainAttrs})

export const parseStandardWithDomainAttrs: ParserT<RlStandardWithDomainT> = merge(
  parseStandardAttrs,
  parseDomainAttrsObj
)

const parseRlStandardWithDomains = Parser.mkRun<Array<RlStandardWithDomainT>>(
  array(parseStandardWithDomainAttrs)
)

/**
 * API
 */

type FetchRlStandardsParamsT = {
  domainId?: RlDomainIdT
}

export type StandardsWithDomainsFilter = {tag: 'no-filter'} | {tag: 'only-is-student-visible'}

export function fetchRlStandardsWithDomain(
  contentArea: ContentAreaT,
  standardSetId: string,
  lang: LangT,
  standardsWithDomainsFilter: StandardsWithDomainsFilter,
  params?: FetchRlStandardsParamsT
): Promise<Array<RlStandardWithDomainT>> {
  const domainId = params?.domainId ?? null
  const url = `${PATHS.textAssetsUrl}/${ContentAreas.toPath(
    contentArea
  )}/standard-sets/${standardSetId}/${Languages.toString(lang)}/standards.json`

  return ajaxJsonCall({
    url,
    method: 'GET'
  }).then(response => {
    const rlStandardWithDomains = parseRlStandardWithDomains(response)
    const filteredRlStandardWithDomains = (() => {
      switch (standardsWithDomainsFilter.tag) {
        case 'no-filter':
          return rlStandardWithDomains
        case 'only-is-student-visible':
          return filter(
            rlStandardWithDomains,
            ({domain, isStudentVisible}) => isStudentVisible && domain.isStudentVisible
          )
        default:
          return exhaustive(standardsWithDomainsFilter)
      }
    })()
    const standards = filter(filteredRlStandardWithDomains, s =>
      domainId === null ? true : s.domainId === domainId
    )
    return sortBy(standards, ['lowestGrade', 'highestGrade', 'domainId', 'progressionOrder'])
  })
}

// TODO: Remove this function as we should be returning a RlStandardWithDomainT because we will always have a domain
// https://app.asana.com/0/149473556304568/1200915504043803/f
export async function fetchRlStandards(
  contentArea: ContentAreaT,
  standardSetId: string,
  lang: LangT,
  standardsWithDomainsFilter: StandardsWithDomainsFilter,
  params?: FetchRlStandardsParamsT
): Promise<Array<RlStandardT>> {
  const standardsWithDomains = await fetchRlStandardsWithDomain(
    contentArea,
    standardSetId,
    lang,
    standardsWithDomainsFilter,
    params
  )
  return map(standardsWithDomains, toRlStandard)
}

/**
 * Grouping rl-standards by domain and name is the temporary workaround we are using
 * to address the following scenario:
 *
 * As part of our data migration from legacy fr-standards to rl-standards,
 * if N FR standards were mapped to a single state standard, preserving
 * behavior-neutral student progression required the creation of N
 * corresponding state standards (even though there really is only 1 state
 * standard).  This resulted in N state standards that look like duplicates
 * (same name and description). To address this for now, we will remove
 * duplicates within a domain and return the first standard in progression order.
 *
 * Note that if a duplicate spans across domains, we are keeping it in.
 *
 */

export function removeRlStandardNameDuplicatesWithinDomains(
  rlStandards: Array<RlStandardT>
): Array<RlStandardT> {
  const pairs: Array<[string, Array<RlStandardT>]> = toPairs(
    groupBy(rlStandards, s => `${RlDomainId.toString(s.domainId)}${s.name}`)
  )
  const standardsToDisplay = flatMap(pairs, ([_groupKey, standards]) => {
    const firstStandard = minBy(standards, s => s.progressionOrder)
    if (standards.length > 1 && firstStandard !== null && firstStandard !== undefined) {
      return [firstStandard]
    } else {
      return standards
    }
  })

  return standardsToDisplay
}

/**
 * Here, we address the problem above by identifying a "part" number for the
 * N state standards that look like duplicates.  UI's can then use this value to
 * possibly render things like "part 1", "part 2", etc.  If part is null, there
 * are no duplicate looking standards.
 *
 * CAUTION: part value is not zero-indexed.  It starts at 1.
 */

type RlStandardWithPartT = {
  standard: RlStandardT
  part: number | undefined | null
}

export function identifyPartsInRlStandards(
  rlStandards: Array<RlStandardT>
): Array<RlStandardWithPartT> {
  const pairs = toPairs(groupBy(rlStandards, s => [s.domainId, s.name]))
  const standardsWithDupeValues = flatMap(
    pairs,
    ([_groupKey, standards]): Array<RlStandardWithPartT> => {
      const firstStandard = first(standards)
      if (standards.length === 1 && firstStandard !== null && firstStandard !== undefined) {
        return [{standard: firstStandard, part: null}]
      }

      const sortedStandards = sortBy(standards, s => s.progressionOrder)
      return map(sortedStandards, (s, i: number) => ({standard: s, part: i + 1}))
    }
  )

  return flatMap(standardsWithDupeValues)
}

// TODO: This function is largely unsafe and should take an Array<RlStandardWithDomainT> to ensure that we actually have the domain data.
// https://app.asana.com/0/149473556304568/1200915504043803/f
export function extractDomains(rlStandards: Array<RlStandardT>): Array<RlDomainT> {
  const domains = map(rlStandards, standard => {
    return fromJust(standard.domain, 'A domain is required to filter rlStandards')
  })

  return sortBy(
    uniqBy(domains, domain => domain.id),
    ['lowestGrade', 'highestGrade']
  )
}

export async function fetchRlDomainsByStandardSetId(
  contentArea: ContentAreaT,
  standardSetId: string,
  lang: LangT,
  standardsWithDomainsFilter: StandardsWithDomainsFilter
): Promise<Array<RlDomainT>> {
  const rlStandards = await fetchRlStandards(
    contentArea,
    standardSetId,
    lang,
    standardsWithDomainsFilter
  )
  return extractDomains(rlStandards)
}

export function getRlDomainFromStandards(
  rlDomainId: RlDomainIdT,
  rlStandards: Array<RlStandardT>
): RlDomainT {
  const domains = extractDomains(rlStandards)
  return fromJust(
    find(domains, d => d.id === rlDomainId),
    `Unable to find rlDomainId ${RlDomainId.toString(rlDomainId)}`
  )
}
