import {
  setupRenaissanceKeepaliveIfNecessary,
  getRenaissanceKeepaliveIntervalId
} from '@freckle/student-entities/ts/common/helpers/renaissance-keepalive'
import {type RenaissanceKeepaliveT} from '@freckle/student-entities/ts/common/helpers/renaissance-keepalive'
import Globals from '@freckle/student/ts/common/models/globals'
import {
  isStaging,
  isTestEnv,
  isLocalhost
} from '@freckle/student-entities/ts/common/helpers/common-api-helper'
import {type DistrictAttrs} from '@freckle/student-entities/ts/roster/models/district'
import {type SelfStudentAttrs} from '@freckle/student/ts/users/models/self-student/self-student'

export function setupRenaissanceKeepalive(renaissanceKeepalive?: RenaissanceKeepaliveT | null) {
  //setupRenaissanceKeepaliveIfNecessary is IIFE based.  Running multiple times will
  //not create multiple intervals.
  setupRenaissanceKeepaliveIfNecessary(renaissanceKeepalive)
}

export function resetAfterSignout() {
  // uncache that the student has been authenticated
  Globals.routing.studentLoggedIn = false

  //Clear keepalive interval
  const intervalId = getRenaissanceKeepaliveIntervalId()
  if (intervalId !== null) {
    clearInterval(intervalId)
  }
}

export function initializePendo({
  selfStudent,
  district
}: {
  selfStudent: SelfStudentAttrs
  district: DistrictAttrs | undefined | null
}) {
  if (isStaging() || isTestEnv() || isLocalhost()) {
    return
  }
  type PendoVisitorIdT = {
    id: string | number
    type: 'global' | 'rgp' | 'local'
  }

  const getPrimaryVisitorIdData = (student: SelfStudentAttrs): PendoVisitorIdT => {
    if (student.gurId !== null && student.gurId !== undefined && student.gurId !== '') {
      return {
        id: student.gurId,
        type: 'global'
      }
    } else if (
      student.renaissanceRPIdentifier !== null &&
      student.renaissanceRPIdentifier !== undefined
    ) {
      return {
        id: student.renaissanceRPIdentifier,
        type: 'rgp'
      }
    } else {
      return {
        id: `${student.id}-student`,
        type: 'local'
      }
    }
  }
  const districtId = selfStudent.districtId
  const districtState = district?.administrativeArea
  const countryCode = district?.countryCode
  window.pendo.initialize({
    visitor: {
      // standard metadata
      id: getPrimaryVisitorIdData(selfStudent).id,
      lang_fr: selfStudent.lang,

      // basic data for Renaissance Pendo
      visitor_id_type: getPrimaryVisitorIdData(selfStudent).type,
      global_user_id: selfStudent.gurId,
      rgp_user_id: selfStudent.renaissanceRPIdentifier,
      local_user_id: selfStudent.id,
      user_type: 'student',

      // custom metadata
      created_at_fr: selfStudent.createdAt.toISOString(),
      district_id_fr: districtId,
      grade_fr: selfStudent.grade,
      is_teacher_fr: selfStudent.isTeacher,
      source_fr: selfStudent.source
    },
    account: {
      // standard metadata
      id: district ? `${district.name}-district-${district.id}` : null,
      // custom metadata
      district_id_fr: districtId,
      administrative_area_fr: districtState,
      country_code_fr: countryCode
    }
  })
}
