import type {ThunkAction} from 'redux-thunk'
import {isFetching} from '@freckle/resource-status'
import {type TeacherAttrs} from '@freckle/student-entities/ts/users/models/teacher'
import {fetchTeacher} from '@freckle/student/ts/users/models/teacher'
import {type StoreState} from '@freckle/student/ts/reducers/types'

export type CurrentTeacherActionT =
  | CurrentTeacherGetRequestActionT
  | CurrentTeacherGetRefetchRequestActionT
  | CurrentTeacherGetResponseActionT
  | CurrentTeacherGetErrorActionT

type CurrentTeacherGetRequestActionT = {
  type: 'CURRENT_TEACHER_GET_REQUEST'
}

function currentTeacherGetRequestAction(): CurrentTeacherGetRequestActionT {
  return {type: 'CURRENT_TEACHER_GET_REQUEST'}
}

type CurrentTeacherGetRefetchRequestActionT = {
  type: 'CURRENT_TEACHER_GET_REFETCH_REQUEST'
  currentTeacher: TeacherAttrs
}

function currentTeacherGetRefetchRequestAction(
  currentTeacher: TeacherAttrs
): CurrentTeacherGetRefetchRequestActionT {
  return {type: 'CURRENT_TEACHER_GET_REFETCH_REQUEST', currentTeacher}
}

type CurrentTeacherGetResponseActionT = {
  type: 'CURRENT_TEACHER_GET_RESPONSE'
  currentTeacher: TeacherAttrs
}

function currentTeacherGetResponseAction(
  currentTeacher: TeacherAttrs
): CurrentTeacherGetResponseActionT {
  return {
    type: 'CURRENT_TEACHER_GET_RESPONSE',
    currentTeacher
  }
}

type CurrentTeacherGetErrorActionT = {
  type: 'CURRENT_TEACHER_GET_ERROR'
  error: unknown
}

function currentTeacherGetErrorAction(error: unknown): CurrentTeacherGetErrorActionT {
  return {type: 'CURRENT_TEACHER_GET_ERROR', error}
}

export const loadCurrentTeacher =
  (currentCourseId: number): ThunkAction<void, StoreState, unknown, CurrentTeacherActionT> =>
  async (dispatch, getState) => {
    const {currentTeacher} = getState()
    if (isFetching(currentTeacher)) {
      return
    }
    if (currentTeacher.status === 'complete') {
      dispatch(currentTeacherGetRefetchRequestAction(currentTeacher.data))
    } else {
      dispatch(currentTeacherGetRequestAction())
    }
    try {
      const currentTeacher = await fetchTeacher(currentCourseId)
      dispatch(currentTeacherGetResponseAction(currentTeacher))
    } catch (error) {
      dispatch(currentTeacherGetErrorAction(error))
      throw error
    }
  }
