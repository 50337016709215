import {maybe, fromMaybe} from '@freckle/maybe'

import {CONTENT_URL} from './env'

// Should match SERVER_PORT in app/vite.config.ts
const VITE_PORT = 5173

export const mkSubdomainUrl = (subdomain: string): string => {
  const protocol = 'https'
  const {hostname} = window.location
  if (hostname === 'localhost') {
    return `${protocol}://${subdomain}.localhost.com`
  } else {
    // everything AFTER student or teacher in the domain name
    const urlRegex =
      /(localhost|((classroom|student|school|console|sso)((.*)\.(freckle|freckletest|localhost).com)))/
    const defaultSuffix = '.localhost.com'
    const fullSuffix = maybe(
      () => defaultSuffix,
      regexMatch => fromMaybe(() => defaultSuffix, regexMatch[4]),
      urlRegex.exec(hostname)
    )
    return `${protocol}://${subdomain}${fullSuffix}`
  }
}

// API requests need to be proxied when running through Vite on a different
// port to handle CORS
export const mkProxiedApiUrl = (apiUrl: string): string => {
  const {hostname, port} = window.location

  if (/student\.localhost\.com/.test(hostname) && parseInt(port, 10) === VITE_PORT) {
    const subdomain = maybe(
      () => '',
      regexMatch => fromMaybe(() => '', regexMatch[1]),
      /https:\/\/(.*)\.(freckle|freckletest|localhost)\.com/.exec(apiUrl)
    )
    return `https://${hostname}:${port}/${subdomain}`
  }
  return apiUrl
}

export const PATHS = {
  unversionedAPIUrl: mkSubdomainUrl('api'),
  imageAssetsUrl: 'https://images.freckle.com',
  prodAssetsUrl: 'https://classroom-assets.freckle.com',
  vendorAssetsUrl: 'https://vendor-assets.freckle.com',
  textAssetsUrl: CONTENT_URL,
  teacherDashUrl: mkSubdomainUrl('classroom'),
  studentClientUrl: mkSubdomainUrl('student'),
  translationsUrl: 'https://translations.freckle.com',
  ttsUrl: mkProxiedApiUrl('https://tts.freckle.com')
}
