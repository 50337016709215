import {Languages, type LangT} from '@freckle/student-materials/src/helpers/languages'
import {exhaustiveReducer} from '@freckle/exhaustive'
import {type SettingsActionT} from '@freckle/student/ts/actions/settings'
import {
  type StoreState,
  type SettingsStateT,
  type SettingsAttrs
} from '@freckle/student/ts/reducers/types'
import {type SelfStudentActionT} from '@freckle/student/ts/actions/self-student'
import {isAutomatedAudioOn} from '@freckle/student-entities/ts/users/models/student'

export const defaultSettingsState: SettingsAttrs = {
  langSetting: {
    code: Languages.English,
    fromUrl: false
  },
  automatedAudio: false
}

export function settingsReducer(
  state: SettingsStateT = defaultSettingsState,
  action: SettingsActionT | SelfStudentActionT
): SettingsStateT {
  switch (action.type) {
    case 'SETTINGS_UPDATE_LANGUAGE':
      return {
        ...state,
        langSetting: action.langSetting
      }
    case 'SELF_STUDENT_GET_RESPONSE':
      // Give preference to language param in the URL
      const langSetting = state.langSetting.fromUrl
        ? null
        : {
            langSetting: {
              code: action.selfStudent.lang,
              fromUrl: false
            }
          }
      return {...state, ...langSetting, automatedAudio: isAutomatedAudioOn(action.selfStudent)}
    case 'SELF_STUDENT_UPDATE_COINS':
      return state
    case 'SELF_STUDENT_UPDATE_ONBOARDING_REQUIRED':
      return state
    case 'SELF_STUDENT_GET_REQUEST':
      return state
    case 'SELF_STUDENT_GET_REFETCH_REQUEST':
      return state
    case 'SELF_STUDENT_GET_ERROR':
      return state
    default:
      return exhaustiveReducer(action, state)
  }
}

export function getLanguage(storeState: StoreState): LangT {
  return storeState.settings.langSetting.code
}

export function getAutomatedAudio(storeState: StoreState): boolean {
  return storeState.settings.automatedAudio
}
