import {type Moment} from 'moment-timezone'
import {Languages, type LangT} from '@freckle/student-materials/src/helpers/languages'
import {
  type ParserT,
  Parser,
  string,
  record,
  number,
  boolean,
  date,
  stringEnum,
  nullable
} from '@freckle/parser'
import {
  RosterSources,
  type RosterSourceT
} from '@freckle/student-entities/ts/users/logic/roster-source'
import {
  ReadAloudSupports,
  type ReadAloudSupportT
} from '@freckle/student-entities/ts/common/models/read-aloud-support'
import {
  type IdentityProviderT,
  parser as identityProviderParser
} from '@freckle/student-entities/ts/roster/identity-provider'

import {AutomatedAudio, AutomatedAudios} from '../../common/models/automated-audio'
import {isEarlyLearner} from '../../common/logic/grades'

export type OnboardingRequiredT = {
  selectAvatar: boolean
  // ^ When true a student hasn't completed the select avatar onboarding step
}

export type StudentAttrs = {
  id: number
  sisId: string | undefined | null
  firstName: string
  lastName: string
  createdBy: number | undefined | null
  coinsTotal: number
  coinsToday: number
  coinsSpent: number
  districtId: number | undefined | null
  grade: number
  createdAt: Moment
  updatedAt: Moment
  lang: LangT
  diagnosticCompletedAt: Moment | undefined | null
  startingFreckleTextLevel: string | undefined | null
  wordStudyDiagnosticLevel: number | undefined | null
  cleverStudentId: string | undefined | null
  classLinkStudentId: string | undefined | null
  password: string
  isTeacher: boolean
  numberFactsLevelId: string | undefined | null
  source: RosterSourceT | undefined | null
  renaissanceRPIdentifier: string | undefined | null
  renaissanceManualRPIdentifier: string | undefined | null
  gurId: string | undefined | null
  onboardingRequired: OnboardingRequiredT | undefined | null
  readAloudSupport: ReadAloudSupportT
  automatedAudio: AutomatedAudio
  identityProvider: IdentityProviderT | undefined | null
  idpManaged: boolean
  storeLimit: number | undefined | null
}

export const parseStudentAttrs: ParserT<StudentAttrs> = record({
  id: number(),
  sisId: nullable(string()),
  firstName: string(),
  lastName: string(),
  createdBy: nullable(number()),
  coinsTotal: number(),
  coinsToday: number(),
  coinsSpent: number(),
  districtId: nullable(number()),
  grade: number(),
  createdAt: date(),
  updatedAt: date(),
  lang: Languages.parseT(),
  diagnosticCompletedAt: nullable(date()),
  startingFreckleTextLevel: nullable(string()),
  wordStudyDiagnosticLevel: nullable(number()),
  cleverStudentId: nullable(string()),
  classLinkStudentId: nullable(string()),
  password: string(),
  isTeacher: boolean(),
  numberFactsLevelId: nullable(string()),
  source: nullable(stringEnum('RosterSourceT', RosterSources.parse)),
  gurId: nullable(string()),
  renaissanceRPIdentifier: nullable(string()),
  renaissanceManualRPIdentifier: nullable(string()),
  onboardingRequired: nullable(
    record({
      selectAvatar: boolean()
    })
  ),
  readAloudSupport: stringEnum('ReadAloudSupportT', ReadAloudSupports.parse),
  automatedAudio: stringEnum('AutomatedAudio', AutomatedAudios.parse),
  identityProvider: nullable(identityProviderParser),
  idpManaged: boolean(),
  storeLimit: nullable(number())
})

export const parseBaseStudent = Parser.mkRun<StudentAttrs>(parseStudentAttrs)

export function formatFullName(firstName: string, lastName: string): string {
  return `${firstName} ${lastName}`
}

export function getFormattedFirstName(student: StudentAttrs): string {
  return formatFirstName(student.firstName)
}

export function formatFirstName(firstName: string): string {
  return `${firstName.charAt(0).toUpperCase()}${firstName.slice(1).toLowerCase()}`
}

export function formatNameWithLastInitial(firstName: string, lastName: string): string {
  return `${formatFirstName(firstName)} ${lastName.charAt(0).toUpperCase()}.`
}

export function calcCoinsRemaining(coinsTotal: number, coinsSpent: number): number {
  return coinsTotal - coinsSpent
}

// N.B. This type uses an open record to allow resusing this function for Student and SelfStudent.
// The refined types expose their own functions, but use this function internally.
type LinkAttrsT = Inexact<{
  renaissanceRPIdentifier: string | undefined | null
  renaissanceManualRPIdentifier: string | undefined | null
}>

export function isStarLinkedGeneric({
  renaissanceRPIdentifier,
  renaissanceManualRPIdentifier
}: LinkAttrsT): boolean {
  return (
    (renaissanceRPIdentifier !== null && renaissanceRPIdentifier !== undefined) ||
    (renaissanceManualRPIdentifier !== null && renaissanceManualRPIdentifier !== undefined)
  )
}

export function isAutomatedAudioOn(student: StudentAttrs): boolean {
  if (isEarlyLearner(student.grade) && student.automatedAudio === AutomatedAudios.Default) {
    return true
  } else {
    return student.automatedAudio === AutomatedAudios.Always
  }
}
