import * as React from 'react'
import {fromMaybe} from '@freckle/maybe'
import {MainWrapper} from '@freckle/student/ts/common/components/layout/main-wrapper'
import {Dashboard} from '@freckle/student/ts/common/components/layout/dashboard'

import {PendoWrapper} from './pendo-wrapper'
import Middleware from './middleware'
import {SplitIOWrapper} from './split-io-wrapper'

export type Props = {
  isFullScreen?: boolean
  isResponsive?: boolean
  children?: React.ReactNode
}

export function Wrapper(props: Props): React.ReactElement {
  const {isFullScreen, isResponsive, children} = props

  return (
    <Middleware>
      <PendoWrapper>
        <SplitIOWrapper>
          <MainWrapper isResponsive={isResponsive}>
            <Dashboard isFullScreen={fromMaybe(() => false, isFullScreen)}>{children}</Dashboard>
          </MainWrapper>
        </SplitIOWrapper>
      </PendoWrapper>
    </Middleware>
  )
}
