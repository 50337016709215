/* eslint-disable react-refresh/only-export-components */
import * as React from 'react'
import {LoggedRoute} from '@freckle/student/ts/common/routers/logged-route'
import {ContentAreas} from '@freckle/student-entities/ts/common/helpers/content-area'

import type {PathData} from './path-data'

const ElaMenuHandler = React.lazy(() => import('@freckle/student/ts/ela/menu/components/ela-menu'))
const WordStudyIntroDebugHandler = React.lazy(
  () => import('@freckle/student/ts/ela/word-study/debug/components/word-study-intro-debug-handler')
)
const WordStudyDebugHandlerComponent = React.lazy(
  () => import('@freckle/student/ts/ela/word-study/debug/components/word-study-debug-handler')
)
const DebugWorkedExampleHandler = React.lazy(
  () => import('@freckle/student/ts/ela/skills-practice/components/debug/worked-example-debug')
)
const ElaArticlesCategoriesHandler = React.lazy(
  () => import('@freckle/student/ts/ela/articles/components/ela-articles-categories')
)
const ElaContentLibraryHandler = React.lazy(
  () => import('@freckle/student/ts/ela/articles/components/ela-content-library')
)
const ElaArticlesCategoryHandler = React.lazy(
  () => import('@freckle/student/ts/ela/articles/components/ela-articles-category')
)
const ElaSkillPracticeDomainsHandler = React.lazy(
  () => import('@freckle/student/ts/ela/skills-practice/components/domains')
)
const ElaSkillPracticeDomainHandler = React.lazy(
  () => import('@freckle/student/ts/ela/skills-practice/components/domain')
)
const ElaSkillsPracticeSessionHandler = React.lazy(
  () => import('@freckle/student/ts/ela/skills-practice/components/session')
)
const ElaTargetedSkillsPracticeSessionHandler = React.lazy(
  () => import('@freckle/student/ts/ela/skills-practice/components/targeted-session')
)
const ElaSkillsPracticeDebugHandler = React.lazy(
  () => import('@freckle/student/ts/ela/skills-practice/components/debug')
)
const ElaWritingAssignmentHandler = React.lazy(
  () => import('@freckle/student/ts/ela/writing/components')
)
const ElaReadingAssignmentResumableContainer = React.lazy(
  () => import('@freckle/student/ts/ela/reading/components/ela-reading-assignment-resumable')
)
const ElaAssessmentSessionHandler = React.lazy(
  () => import('@freckle/student/ts/ela/assessment/components')
)
const ElaSelfReadingAssignmentHandler = React.lazy(
  () => import('@freckle/student/ts/ela/assignments/components/ela-self-assigned-assignment')
)
const WordStudyBriefingHandler = React.lazy(
  () => import('@freckle/student/ts/ela/word-study/briefing/components')
)
const SightWordsHandler = React.lazy(() => import('@freckle/student/ts/ela/sight-words/components'))
const ElaDiagnosticHandler = React.lazy(
  () => import('@freckle/student/ts/ela/diagnostic/components')
)
const DecodablesBriefingHandler = React.lazy(
  () => import('@freckle/student/ts/ela/decodables/components/decodables-briefing')
)
const DecodableHandlerSelf = React.lazy(
  () => import('@freckle/student/ts/ela/decodables/components/decodable/self')
)
const DecodableHandlerTeacher = React.lazy(
  () => import('@freckle/student/ts/ela/decodables/components/decodable/teacher')
)
const WordStudySessionHandler = React.lazy(
  () => import('@freckle/student/ts/ela/word-study/session/components/word-study-session-handler')
)
const WordStudySelfAssignedSessionHandler = React.lazy(
  () =>
    import('@freckle/student/ts/ela/word-study/session/components/self-assigned-session-handler')
)
const WordStudyDiagnosticHandler = React.lazy(
  () => import('@freckle/student/ts/ela/word-study/diagnostic/components/index')
)
const DomainPathwayHandler = React.lazy(
  () => import('@freckle/student/ts/ela/skills-practice/components/pathway')
)
const FocusSkillsPracticeHandler = React.lazy(
  () => import('@freckle/student/ts/focus-skills-practice/components/focus-skills-practice-handler')
)
const ElaFocusSkillsPracticeSessionHandler = React.lazy(
  () => import('@freckle/student/ts/focus-skills-practice/ela/session-handler')
)

export type ElaRoutesWithQueryParams = {
  '/ela/menu': undefined
  '/ela/library': undefined
  '/ela/library/:elaLibraryContentType/categories': undefined
  '/ela/library/:elaLibraryContentType/categories/:category': undefined
  '/ela/word-study-debug/intros/:uspId': undefined
  '/ela/word-study-debug/questions/:uuid': undefined
  '/ela/skills-practice/domains': undefined
  '/ela/skills-practice/domains/:domainId': undefined
  '/ela/skills-practice/domains/:domainId/session': undefined
  '/ela/skills-practice/assignments/:assignmentIds': undefined
  '/ela/system/skills-practice/assignments/:assignmentIds': undefined
  '/ela/system/targeted-skills-practice/assignments/:assignmentIds': undefined
  '/ela/system/skills-practice/debug/:uuid?': undefined
  '/ela/assignments/writing/:assignmentIds': undefined
  '/ela/assignments/reading/:assignmentIds': undefined
  '/ela/assignments/assessment/:assignmentIds': undefined
  '/ela/assignments/self-assigned-reading/:articleUuid': undefined
  '/ela/word-study-briefing': undefined
  '/ela/sight-words': undefined
  '/ela/diagnostic': undefined
  '/ela/decodables': undefined
  '/ela/assignments/decodable/:assignmentIds': undefined
  '/ela/decodables/:decodableUuid': undefined
  '/ela/assignments/word-study/:assignmentIds': undefined
  '/ela/word-study-session': undefined
  '/ela/word-study-diagnostic': undefined
  '/ela/focus-skills-practice': undefined
  '/ela/focus-skills-practice/assignments/:assignmentIds': undefined
  '/ela/debuggers/worked-example': undefined
}

export const elaPathData: PathData<ElaRoutesWithQueryParams> = {
  '/ela/menu': {
    isBlockedByFocusMode: true,
    element: path => (
      <LoggedRoute component={ElaMenuHandler} wrapperParams={{isResponsive: true}} path={path} />
    )
  },
  '/ela/library': {
    isBlockedByFocusMode: true,
    element: path => (
      <LoggedRoute
        component={ElaContentLibraryHandler}
        wrapperParams={{isResponsive: true}}
        path={path}
      />
    )
  },
  '/ela/library/:elaLibraryContentType/categories': {
    isBlockedByFocusMode: true,
    element: path => <LoggedRoute component={ElaArticlesCategoriesHandler} path={path} />
  },
  '/ela/library/:elaLibraryContentType/categories/:category': {
    isBlockedByFocusMode: true,
    element: path => <LoggedRoute component={ElaArticlesCategoryHandler} path={path} />
  },
  '/ela/word-study-debug/intros/:uspId': {
    isBlockedByFocusMode: false,
    element: path => <LoggedRoute component={WordStudyIntroDebugHandler} path={path} />
  },
  '/ela/word-study-debug/questions/:uuid': {
    isBlockedByFocusMode: false,
    element: path => <LoggedRoute component={WordStudyDebugHandlerComponent} path={path} />
  },
  '/ela/skills-practice/domains': {
    isBlockedByFocusMode: true,
    element: path => (
      <LoggedRoute
        component={ElaSkillPracticeDomainsHandler}
        wrapperParams={{isResponsive: true}}
        path={path}
      />
    )
  },
  '/ela/skills-practice/domains/:domainId': {
    isBlockedByFocusMode: true,
    element: path => (
      <LoggedRoute
        component={DomainPathwayHandler}
        wrapperParams={{isResponsive: true}}
        path={path}
      />
    )
  },
  '/ela/skills-practice/domains/:domainId/session': {
    isBlockedByFocusMode: false,
    element: path => (
      <LoggedRoute
        component={ElaSkillPracticeDomainHandler}
        wrapperParams={{isResponsive: true}}
        path={path}
      />
    )
  },
  '/ela/skills-practice/assignments/:assignmentIds': {
    isBlockedByFocusMode: false,
    element: path => <LoggedRoute component={ElaSkillsPracticeSessionHandler} path={path} />
  },
  '/ela/system/skills-practice/assignments/:assignmentIds': {
    isBlockedByFocusMode: false,
    element: path => <LoggedRoute component={ElaSkillsPracticeSessionHandler} path={path} />
  },
  '/ela/system/targeted-skills-practice/assignments/:assignmentIds': {
    isBlockedByFocusMode: false,
    element: path => <LoggedRoute component={ElaTargetedSkillsPracticeSessionHandler} path={path} />
  },
  '/ela/system/skills-practice/debug/:uuid?': {
    isBlockedByFocusMode: false,
    element: path => <LoggedRoute component={ElaSkillsPracticeDebugHandler} path={path} />
  },
  '/ela/assignments/writing/:assignmentIds': {
    isBlockedByFocusMode: false,
    element: path => <LoggedRoute component={ElaWritingAssignmentHandler} path={path} />
  },
  '/ela/assignments/reading/:assignmentIds': {
    isBlockedByFocusMode: false,
    element: path => <LoggedRoute component={ElaReadingAssignmentResumableContainer} path={path} />
  },
  '/ela/assignments/assessment/:assignmentIds': {
    isBlockedByFocusMode: false,
    element: path => <LoggedRoute component={ElaAssessmentSessionHandler} path={path} />
  },
  '/ela/assignments/self-assigned-reading/:articleUuid': {
    isBlockedByFocusMode: false,
    element: path => <LoggedRoute component={ElaSelfReadingAssignmentHandler} path={path} />
  },
  '/ela/word-study-briefing': {
    isBlockedByFocusMode: true,
    element: path => <LoggedRoute component={WordStudyBriefingHandler} path={path} />
  },
  '/ela/sight-words': {
    isBlockedByFocusMode: false,
    element: path => <LoggedRoute component={SightWordsHandler} path={path} />
  },
  '/ela/diagnostic': {
    isBlockedByFocusMode: false,
    element: path => <LoggedRoute component={ElaDiagnosticHandler} path={path} />
  },
  '/ela/decodables': {
    isBlockedByFocusMode: true,
    element: path => <LoggedRoute component={DecodablesBriefingHandler} path={path} />
  },
  '/ela/assignments/decodable/:assignmentIds': {
    isBlockedByFocusMode: false,
    element: path => <LoggedRoute component={DecodableHandlerTeacher} path={path} />
  },
  '/ela/decodables/:decodableUuid': {
    isBlockedByFocusMode: false,
    element: path => <LoggedRoute component={DecodableHandlerSelf} path={path} />
  },
  '/ela/assignments/word-study/:assignmentIds': {
    isBlockedByFocusMode: false,
    element: path => <LoggedRoute component={WordStudySessionHandler} path={path} />
  },
  '/ela/word-study-session': {
    isBlockedByFocusMode: false,
    element: path => <LoggedRoute component={WordStudySelfAssignedSessionHandler} path={path} />
  },
  '/ela/word-study-diagnostic': {
    isBlockedByFocusMode: false,
    element: path => <LoggedRoute component={WordStudyDiagnosticHandler} path={path} />
  },
  '/ela/focus-skills-practice': {
    isBlockedByFocusMode: true,
    element: path => (
      <LoggedRoute
        component={() => <FocusSkillsPracticeHandler contentArea={ContentAreas.ela} />}
        wrapperParams={{isResponsive: true}}
        path={path}
      />
    )
  },
  '/ela/focus-skills-practice/assignments/:assignmentIds': {
    isBlockedByFocusMode: false,
    element: path => (
      <LoggedRoute
        component={ElaFocusSkillsPracticeSessionHandler}
        wrapperParams={{isResponsive: true}}
        path={path}
      />
    )
  },
  '/ela/debuggers/worked-example': {
    isBlockedByFocusMode: false,
    element: path => <LoggedRoute component={DebugWorkedExampleHandler} path={path} />
  }
}
