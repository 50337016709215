import hash from 'stable-hash'
import {mapValues} from 'lodash'
import {isMoment} from 'moment-timezone'

import {NonActionableError} from '../../open-api/helpers/errors/base/non-actionable-error'
import {logErrorAsUnhandled} from './exception-handlers/bugsnag-client'

export const swrOptions = {
  //`swr` natively uses `stable-hash` to do comparison which is broken for `Moment`
  compare: (a: unknown, b: unknown) => hash(fixMomentRecursive(a)) === hash(fixMomentRecursive(b)),
  onError: (error: Error) => {
    if (!(error instanceof NonActionableError)) {
      logErrorAsUnhandled(error)
    }
  }
}

// `hash` does not work for `Moment` as they never hash to the same value
// This converts all `Moments` `toISOString` so that we have something stable to hash on.
function fixMomentRecursive(o: unknown): unknown {
  if (isMoment(o)) {
    return o.toISOString()
  }
  if (typeof o === 'object') {
    return mapValues(o, value => fixMomentRecursive(value))
  }
  return o
}
