import {updateResource} from '@freckle/resource-status'
import {type SelfStudentsStateT} from '@freckle/student/ts/reducers/types'
import {type SelfStudentActionT} from '@freckle/student/ts/actions/self-student'

const defaultSelfStudentsState: SelfStudentsStateT = {
  status: 'idle'
}

export function selfStudentReducer(
  state: SelfStudentsStateT = defaultSelfStudentsState,
  action: SelfStudentActionT
): SelfStudentsStateT {
  switch (action.type) {
    case 'SELF_STUDENT_GET_REQUEST':
      return {status: 'loading'}
    case 'SELF_STUDENT_GET_REFETCH_REQUEST':
      return {status: 'reloading', data: action.selfStudent}
    case 'SELF_STUDENT_GET_RESPONSE': {
      const {selfStudent} = action
      return {status: 'complete', data: selfStudent, hasUpdated: false}
    }
    case 'SELF_STUDENT_GET_ERROR':
      return {status: 'error', error: action.error}
    case 'SELF_STUDENT_UPDATE_COINS':
      return updateResource(state, student => ({
        ...student,
        ...action.coins
      }))
    case 'SELF_STUDENT_UPDATE_ONBOARDING_REQUIRED':
      return updateResource(state, student => ({
        ...student,
        onboardingRequired: action.onboardingRequired
      }))
    default:
      return state
  }
}
