import {type ItemsStateT} from '@freckle/student/ts/reducers/types'
import {type ItemsActionT} from '@freckle/student/ts/actions/items'

const defaultItemsState: ItemsStateT = {
  status: 'idle'
}

export function itemsReducer(
  state: ItemsStateT = defaultItemsState,
  action: ItemsActionT
): ItemsStateT {
  switch (action.type) {
    case 'ITEMS_GET_REQUEST':
      return state.status === 'complete'
        ? {status: 'updating', data: state.data}
        : {status: 'loading'}
    case 'ITEMS_GET_RESPONSE': {
      const {items} = action
      return {status: 'complete', data: items, hasUpdated: false}
    }
    case 'ITEMS_GET_ERROR':
      return {status: 'error', error: action.error}
    default:
      return state
  }
}
