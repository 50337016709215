import moment from 'moment-timezone'
import {
  type RlStandardSetT,
  parseAttrs
} from '@freckle/student-entities/ts/common/models/rl-standard-set'
import {
  type StudentAttrs,
  isStarLinkedGeneric,
  formatFullName,
  formatFirstName,
  calcCoinsRemaining
} from '@freckle/student-entities/ts/users/models/student'
import {type RenaissanceKeepaliveT} from '@freckle/student-entities/ts/common/helpers/renaissance-keepalive'
import {
  type GetSelfStudentR200Response,
  GetSelfStudentR200ResponseFromJSON,
  type GetSelfStudentR200ResponseRenaissanceClientPlatformEnum
} from '@freckle/fancy-api'
import {fetch, api} from '@freckle/student-entities/ts/common/helpers/api-client'

export type RenaissancePlatformT = GetSelfStudentR200ResponseRenaissanceClientPlatformEnum

type SelfStudentMetadataAttrs = {
  mathStandardSet: RlStandardSetT
  elaStandardSet: RlStandardSetT
  starPlacementSyncEnabled: boolean
  renaissanceClientPlatform: RenaissancePlatformT | undefined | null
  renaissanceKeepalive: RenaissanceKeepaliveT | undefined | null
  focusSkillRemediationEnabled: boolean
  elaFocusSkillsPracticeEnabled: boolean
}

export type SelfStudentAttrs = StudentAttrs & SelfStudentMetadataAttrs

// Mapping function is required mostly to convert standardSet api types to domain types (RlStandardSetT)
export const toSelfStudent = (apiType: GetSelfStudentR200Response): SelfStudentAttrs => {
  const {mathStandardSet, elaStandardSet} = apiType
  return {
    ...apiType,
    mathStandardSet: parseAttrs(mathStandardSet),
    elaStandardSet: parseAttrs(elaStandardSet),
    // Since StudentAttrs uses a type alias moment objects need to be constructed
    createdAt: moment(apiType.createdAt),
    updatedAt: moment(apiType.updatedAt),
    diagnosticCompletedAt: moment(apiType.diagnosticCompletedAt),
    // ... and the whole onboardingRequired object needs to be constructed if present
    onboardingRequired: apiType.onboardingRequired?.selectAvatar
      ? {
          selectAvatar: apiType.onboardingRequired.selectAvatar
        }
      : null,
    // Specify props required in StudentAttrs but optional in ApiType
    sisId: apiType.sisId,
    createdBy: apiType.createdBy,
    districtId: apiType.districtId,
    startingFreckleTextLevel: apiType.startingFreckleTextLevel,
    wordStudyDiagnosticLevel: apiType.wordStudyDiagnosticLevel,
    cleverStudentId: apiType.cleverStudentId,
    classLinkStudentId: apiType.classLinkStudentId,
    source: apiType.source,
    renaissanceRPIdentifier: apiType.renaissanceRPIdentifier,
    renaissanceManualRPIdentifier: apiType.renaissanceManualRPIdentifier,
    identityProvider: apiType.identityProvider,
    storeLimit: apiType.storeLimit,
    renaissanceClientPlatform: apiType.renaissanceClientPlatform,
    renaissanceKeepalive: apiType.renaissanceKeepalive,
    gurId: apiType.gurId
  }
}

export const parseSelfStudent = GetSelfStudentR200ResponseFromJSON

/**
 * Utilities
 */

export const fetchSelfStudent = async (): Promise<SelfStudentAttrs> =>
  fetch(api.getSelfStudentR, toSelfStudent)

export function selfStudentFormattedName(selfStudent: SelfStudentAttrs): string {
  return formatFullName(selfStudent.firstName, selfStudent.lastName)
}

export function getFormattedFirstName(selfStudent: SelfStudentAttrs): string {
  return formatFirstName(selfStudent.firstName)
}

export function getCoinsRemaining(selfStudent: SelfStudentAttrs): number {
  return calcCoinsRemaining(selfStudent.coinsTotal, selfStudent.coinsSpent)
}

export function isStarLinked(selfStudent: SelfStudentAttrs): boolean {
  return isStarLinkedGeneric(selfStudent)
}
