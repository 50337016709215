import * as React from 'react'
import {addMaybeClassName} from '@freckle/student-materials/src/helpers/classnames'
import {useSafeEffect} from '@freckle/react-hooks'

import {h3} from './typography.module.scss'
import {type HeadingProps} from './heading-props'

type Props = HeadingProps

export default function H3(props: Props): React.ReactElement<Props> {
  const {children, isFocused = false, id, addClass, dataTest, role, ariaLabel} = props
  const className = addMaybeClassName(h3, addClass)
  const headingRef = React.useRef<HTMLElement>()
  useSafeEffect(() => {
    if (isFocused && headingRef.current) {
      headingRef.current.tabIndex = -1
      headingRef.current.focus()
    }
  }, [isFocused])
  return React.createElement(
    props.as ?? 'h3',
    {
      id,
      className,
      'data-test': dataTest,
      'aria-label': ariaLabel,
      role,
      ref: headingRef
    },

    children
  )
}
