import {createSecureCookieString} from './string-helper'

type Storage = 'localStorage' | 'sessionStorage'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getStorageImplementation(storage: Storage): any {
  const testKey = '__frontrowStorageTestKey__'
  try {
    const store = window[storage]
    store.setItem(testKey, 'true')
    store.removeItem(testKey)
    return store
  } catch (err) {
    return {
      getItem: () => null,
      setItem: () => {},
      removeItem: () => {}
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ValidStoredInformationT = string | any | number | boolean | Array<any>

const StorageHelper = {
  localStorage: getStorageImplementation('localStorage'),
  sessionStorage: getStorageImplementation('sessionStorage'),

  // Generic storage helpers
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  requireStorage: function (storage: Storage): any {
    return StorageHelper[storage]
  },

  setRawStorageProperty: function (storage: Storage, property: string, text: string) {
    StorageHelper.requireStorage(storage).setItem(property, text)
  },

  setStorageProperty: function (
    storage: Storage,
    property: string,
    value: ValidStoredInformationT
  ) {
    StorageHelper.setRawStorageProperty(storage, property, JSON.stringify(value))
  },

  clearStorageProperty: function (storage: Storage, property: string) {
    StorageHelper.requireStorage(storage).removeItem(property)
  },

  getRawStorageProperty: function (storage: Storage, property: string): string | undefined | null {
    return StorageHelper.requireStorage(storage).getItem(property)
  },

  getStorageProperty: <A>(storage: Storage, property: string): A | undefined | null => {
    const text = StorageHelper.getRawStorageProperty(storage, property)
    return text === null || text === undefined ? null : JSON.parse(text)
  },

  getStoragePropertyDefault: <A>(storage: Storage, property: string, defaultValue: A): A => {
    const text = StorageHelper.getRawStorageProperty(storage, property)
    return text === null || text === undefined ? defaultValue : JSON.parse(text)
  },

  replaceMalformedStorageProperty: function (
    storage: Storage,
    property: string,
    value: ValidStoredInformationT
  ): boolean {
    // We've occasionally put un-parseable stuff in local/session/storage
    const text = StorageHelper.getRawStorageProperty(storage, property)
    if (text !== null && text !== undefined) {
      try {
        const _parsed = JSON.parse(text)
      } catch (_err) {
        console.log(`Replacing malformed property ${property}`)
        StorageHelper.setStorageProperty(storage, property, value)
        return true
      }
    }
    return false
  },

  // Local storage helpers

  // Set an item in local Storage
  setLocalStorageProperty: function (property: string, value: ValidStoredInformationT) {
    StorageHelper.setStorageProperty('localStorage', property, value)
  },

  // Clear an item in local Storage
  clearLocalStorageProperty: function (property: string) {
    StorageHelper.clearStorageProperty('localStorage', property)
  },

  // Get an item in local Storage
  getLocalStorageProperty: <A>(property: string): A | undefined | null =>
    StorageHelper.getStorageProperty('localStorage', property),

  // Get an item in local Storage
  getLocalStoragePropertyDefault: <A>(property: string, defaultValue: A): A =>
    StorageHelper.getStoragePropertyDefault('localStorage', property, defaultValue),

  // Return true if existing malformed property was replaced by value
  replaceMalformedLocalStorageProperty: function (
    property: string,
    value: ValidStoredInformationT
  ): boolean {
    return StorageHelper.replaceMalformedStorageProperty('localStorage', property, value)
  },

  // Session Storage Helper

  // Set an item in session Storage
  setSessionStorageProperty: function (property: string, value: ValidStoredInformationT) {
    StorageHelper.setStorageProperty('sessionStorage', property, value)
  },

  // Clear an item in session Storage
  clearSessionStorageProperty: function (property: string) {
    StorageHelper.clearStorageProperty('sessionStorage', property)
  },

  // Get an item in session Storage
  getSessionStorageProperty: <A>(property: string): A | undefined | null =>
    StorageHelper.getStorageProperty('sessionStorage', property),

  // Get an item in session Storage
  getSessionStoragePropertyDefault: <A>(property: string, defaultValue: A): A =>
    StorageHelper.getStoragePropertyDefault('sessionStorage', property, defaultValue),

  // Return true if existing malformed property was replaced by value
  replaceMalformedSessionStorageProperty: function (
    property: string,
    value: ValidStoredInformationT
  ): boolean {
    return StorageHelper.replaceMalformedStorageProperty('sessionStorage', property, value)
  },

  // Cookies

  setSecureCookie(name: string, value: string, expirationDays?: number | null, path?: string) {
    const domain = window.location.hostname
    let expiration = null
    if (expirationDays !== null && expirationDays !== undefined) {
      expiration = new Date()
      expiration.setTime(expiration.getTime() + expirationDays * 24 * 60 * 60 * 1000)
    }
    document.cookie = createSecureCookieString(name, value, domain, expiration, path)
  },

  parseCookies(cookie: string): {
    [name: string]: string
  } {
    return cookie
      .split('; ')
      .filter(x => x !== '')
      .map(x => x.split('='))
      .reduce<{
        [name: string]: string
      }>((m, x) => {
        m[x[0]] = x[1]
        return m
      }, {})
  },

  getCookie(name: string): string | undefined | null {
    return StorageHelper.parseCookies(document.cookie)[name]
  },

  clearSecureCookie(name: string) {
    StorageHelper.setSecureCookie(name, 'v', -1)
  }
}

export default StorageHelper
