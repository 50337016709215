import {fromJust} from '@freckle/maybe'
import {type ParserT, string, map} from '@freckle/parser'
import find from 'lodash/find'

export type LangT = string

export const Languages = {
  English: 'en' as LangT,
  Spanish: 'es' as LangT,
  BritishEnglish: 'en-GB' as LangT,

  all(): Array<LangT> {
    return [Languages.English, Languages.Spanish, Languages.BritishEnglish]
  },

  parseT(): ParserT<LangT> {
    return map(string(), 'LangT', x => x as LangT)
  },

  parse(text: string): LangT | undefined | null {
    // FIXME needs to support language manifest languages.
    return find(Languages.all(), value => value === text)
  },

  parseThrows(text: string): LangT {
    return fromJust(Languages.parse(text), `Unrecognized language ${text}`)
  },

  toString(lang: LangT): string {
    return lang
  },

  format(lang: LangT): string {
    // FIXME should utilize language manifest.
    switch (lang) {
      case 'en':
        return 'English'
      case 'es':
        return 'Spanish'
      case 'en-GB':
        return 'British English'
      default:
        return lang
    }
  },

  isEqual(a: LangT, b: LangT): boolean {
    return a === b
  }
}
