import type {SWRResponse} from 'swr'
import useSWR from 'swr'
import {maybeResourceData} from '@freckle/resource-status'

import {useCurrentTeacher} from '../../components/with-resources/utils'
import {getIsBlockedFromFocusMode} from '../../logic/dashboard'
import {fetchIncompleteAssignmentCount} from '../../models/incomplete-assignment-count'

import type {RoutesWithQueryParams} from '.'
import {pathData} from '.'

const SWR_FETCH_KEY = 'RouteBlockedDueToFocusMode'

export function useRouteBlockedDueToFocusMode(
  path: keyof RoutesWithQueryParams
): SWRResponse<boolean> {
  const isPathBlockedByFocusMode = pathData[path].isBlockedByFocusMode

  const {currentTeacher} = useCurrentTeacher()
  const inFocusMode = maybeResourceData(currentTeacher)?.focusMode
  const standardSetId = maybeResourceData(currentTeacher)?.mathStandardSetId

  const isBlocked = useSWR(
    inFocusMode === undefined || standardSetId === undefined
      ? null
      : [SWR_FETCH_KEY, inFocusMode, standardSetId, isPathBlockedByFocusMode],
    async ([_key, inFocusMode, standardSetId, isPathBlockedByFocusMode]) =>
      isPathBlockedByFocusMode && inFocusMode
        ? getIsBlockedFromFocusMode(
            (await fetchIncompleteAssignmentCount(standardSetId)).count,
            inFocusMode
          )
        : false
  )
  return isBlocked
}
