import type {KeyedMutator} from 'swr'
import useSWR from 'swr'
import {useSafeCallbackExtraDeps, type CallbackFn} from '@freckle/react-hooks'
import type {SelfStudentActionT} from '@freckle/student/ts/actions/self-student'
import {loadSelfStudent} from '@freckle/student/ts/actions/self-student'
import type {Dispatch} from 'react'
import type {StoreState} from '@freckle/student/ts/reducers/types'
import type {ThunkAction} from 'redux-thunk'
import {useAppDispatch} from '@freckle/student/ts/store'

import {fetchSelfStudent, type SelfStudentAttrs} from './self-student'

export const FETCH_SELF_STUDENT_SWR_CACHE_KEY = '/2/students/me'

export function useSelfStudent(): {
  data: SelfStudentAttrs | undefined
  isLoading: boolean
  error: unknown
  mutate: CallbackFn<() => void>
} {
  const dispatch = useAppDispatch()
  const {data, mutate, error, isLoading} = useSWR(
    FETCH_SELF_STUDENT_SWR_CACHE_KEY,
    fetchSelfStudent
  )

  // TODO: remove custom mutate function when we remove
  // self student from Redux
  const mutateInternal = useSafeCallbackExtraDeps<
    () => void,
    {
      dispatch: Dispatch<ThunkAction<void, StoreState, unknown, SelfStudentActionT>>
      mutate: KeyedMutator<SelfStudentAttrs>
    }
  >(
    ({dispatch, mutate}) =>
      () => {
        // Make Redux reload
        dispatch(loadSelfStudent())
        mutate()
      },
    [],
    {
      dispatch: {value: dispatch, comparator: () => true},
      mutate: {value: mutate, comparator: () => true}
    }
  )

  return {data, mutate: mutateInternal, error, isLoading}
}
