import type {ThunkAction} from 'redux-thunk'
import {isFetching} from '@freckle/resource-status'
import {
  type CourseAttrs,
  fetchCurrentCourse
} from '@freckle/student-entities/ts/common/models/course'
import {type StoreState} from '@freckle/student/ts/reducers/types'

export type CurrentCourseActionT =
  | CurrentCourseGetRequestActionT
  | CurrentCourseGetRefetchRequestActionT
  | CurrentCourseGetResponseActionT
  | CurrentCourseGetErrorActionT

type CurrentCourseGetRequestActionT = {
  type: 'CURRENT_COURSE_GET_REQUEST'
}

function currentCourseGetRequestAction(): CurrentCourseGetRequestActionT {
  return {type: 'CURRENT_COURSE_GET_REQUEST'}
}

type CurrentCourseGetRefetchRequestActionT = {
  type: 'CURRENT_COURSE_GET_REFETCH_REQUEST'
  currentCourse: CourseAttrs
}

function currentCourseGetRefetchRequestAction(
  currentCourse: CourseAttrs
): CurrentCourseGetRefetchRequestActionT {
  return {type: 'CURRENT_COURSE_GET_REFETCH_REQUEST', currentCourse}
}

type CurrentCourseGetResponseActionT = {
  type: 'CURRENT_COURSE_GET_RESPONSE'
  currentCourse: CourseAttrs
}

function currentCourseGetResponseAction(
  currentCourse: CourseAttrs
): CurrentCourseGetResponseActionT {
  return {
    type: 'CURRENT_COURSE_GET_RESPONSE',
    currentCourse
  }
}

type CurrentCourseGetErrorActionT = {
  type: 'CURRENT_COURSE_GET_ERROR'
  error: unknown
}

function currentCourseGetErrorAction(error: unknown): CurrentCourseGetErrorActionT {
  return {type: 'CURRENT_COURSE_GET_ERROR', error}
}

export function loadCurrentCourse(): ThunkAction<void, StoreState, unknown, CurrentCourseActionT> {
  return async (dispatch, getState) => {
    const {currentCourse} = getState()
    if (isFetching(currentCourse)) {
      return
    }
    if (currentCourse.status === 'complete') {
      dispatch(currentCourseGetRefetchRequestAction(currentCourse.data))
    } else {
      dispatch(currentCourseGetRequestAction())
    }
    try {
      const currentCourse = await fetchCurrentCourse()
      dispatch(currentCourseGetResponseAction(currentCourse))
    } catch (error) {
      dispatch(currentCourseGetErrorAction(error))
    }
  }
}
