import * as React from 'react'
import {useSafeEffect} from '@freckle/react-hooks'
import {useSafeTranslation} from '@freckle/student-materials/src/helpers/translate'

import {Spinner} from './spinner'

const SPINNER_DEFAULT_DELAY = 300

type Props = {
  text?: string
  inline?: boolean
  delayMs?: number
}

export default function DelayedSpinner({
  text,
  inline,
  delayMs = SPINNER_DEFAULT_DELAY
}: Props): React.ReactElement | null {
  const {t} = useSafeTranslation()
  const [showSpinner, setShowSpinner] = React.useState<boolean>(false)

  useSafeEffect(() => {
    const showSpinnerFunc = () => setShowSpinner(true)
    const timer = setTimeout(showSpinnerFunc, delayMs)

    return () => {
      if (timer !== null && timer !== undefined) {
        clearTimeout(timer)
      }
    }
  }, [delayMs])

  if (showSpinner) {
    return <Spinner text={text} inline={inline} />
  }

  if (inline !== true) {
    // For overlay spinners, block UI even when the spinner is delayed
    return (
      <div
        role="alertdialog"
        data-test="loading-spinner"
        style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 99999}}
        aria-label={text ?? t('Loading...')}
      />
    )
  }

  return null
}
