import * as React from 'react'
import find from 'lodash/find'
import isEqual from 'lodash/isEqual'
import {WithResources} from '@freckle/student/ts/common/components/with-resources'
import {fetchDistricts} from '@freckle/student-entities/ts/roster/models/district'
import {useSafeEffectExtraDeps} from '@freckle/react-hooks'
import {saferStringify} from '@freckle/parser'
import {type SelfStudentAttrs} from '@freckle/student/ts/users/models/self-student/self-student'
import {logError} from '@freckle/student/ts/common/helpers/exception-handlers/bugsnag-client'

import {initializePendo} from './init'
import {useSelfStudentDepricated} from '../components/with-resources/utils'

type WrapperProps = {
  children: React.ReactElement
}

type Props = {
  selfStudent: SelfStudentAttrs
} & WrapperProps

function PendoInner(props: Props): React.ReactElement {
  const {selfStudent} = props

  useSafeEffectExtraDeps<{
    selfStudent: SelfStudentAttrs
  }>(
    ({selfStudent}) => {
      async function setupPendoClient() {
        const districtId = selfStudent.districtId
        try {
          // This fetch fails frequently during network resets, retry is here for resilience.
          const districts = await retry(fetchDistricts, 3)
          const district = find(districts, district => district.id === districtId)

          initializePendo({
            selfStudent,
            district
          })
        } catch (e) {
          //not logging new HttpError here since it can get ignored by Bugsnag if its a 403
          //and thus not expose the fact that Pendo didn't get initialized. Opting for a more specific error instead
          logError(new Error(`Cannot initialize Pendo on Student app. Error: ${saferStringify(e)}`))
        }
      }
      setupPendoClient()
    },
    [],
    {selfStudent: {value: selfStudent, comparator: isEqual}}
  )

  return props.children
}

// Attempt an action `n` times, retrying if it throws.
function retry<A>(f: () => A, attempts: number): A {
  if (attempts <= 1) {
    return f()
  } else {
    try {
      return f()
    } catch (e) {
      return retry(f, attempts - 1)
    }
  }
}

export function PendoWrapper(props: WrapperProps): React.ReactElement {
  const {selfStudent} = useSelfStudentDepricated()
  return (
    <WithResources
      resources={{selfStudent}}
      render={({data: {selfStudent}}) => {
        return <PendoInner selfStudent={selfStudent} {...props} />
      }}
    />
  )
}
