import {exhaustive} from '@freckle/exhaustive'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import forEach from 'lodash/forEach'

type FitT = 'max' | 'crop' | 'scale' | 'clip'

export type ImgReqSizingSearchParams = {
  fit?: FitT
  width?: number
  height?: number
  trim?: FitT
}

export function genImgReqUrlWithSizingSearchParams(
  imgUrl: string, // NOTE: Ideally we'd want to use a branded type that represents non-empty strings
  sizingParams?: ImgReqSizingSearchParams
) {
  const parsedURL = validateURl(imgUrl)

  if (typeof parsedURL === 'string') {
    const errMsg = parsedURL
    throw new Error(errMsg)
  }

  const isSvg = parsedURL.pathname.endsWith('.svg')

  if (!sizingParams || isSvg) {
    return imgUrl
  }

  forEach(sizingParams, (paramValue, paramName) => {
    if (!isNil(paramValue)) {
      const imjaxPropName = toImjaxPropNames(paramName as keyof ImgReqSizingSearchParams)
      parsedURL.searchParams.set(imjaxPropName, paramValue.toString())
    }

    return parsedURL
  })

  return parsedURL.href
}

function toImjaxPropNames(searchParamObjProps: keyof ImgReqSizingSearchParams) {
  switch (searchParamObjProps) {
    case 'fit':
      return 'fit'
    case 'width':
      return 'w'
    case 'height':
      return 'h'
    case 'trim':
      return 'trim'
    default:
      return exhaustive(searchParamObjProps, 'ImgReqSizingSearchParams')
  }
}

function validateURl(url: string): URL | string {
  if (isEmpty(url)) {
    return 'Image URL cannot be empty'
  }

  try {
    const parsedUrl = new URL(url)
    return parsedUrl
  } catch {
    return `Invalid image URL: ${url}`
  }
}
