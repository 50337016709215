import * as React from 'react'
import {Navigate, useLocation} from 'react-router-dom'
import {WithResources} from '@freckle/student/ts/common/components/with-resources'
import Globals from '@freckle/student/ts/common/models/globals'
import {
  setup as setupAnalytics,
  trackStudentRouteChanges,
  setProperties as setAnalyticsProperties
} from '@freckle/student/ts/common/helpers/analytics'
import {type SelfStudentAttrs} from '@freckle/student/ts/users/models/self-student/self-student'
import RouterHelpers from '@freckle/student/ts/common/helpers/router-helpers'
import {checkStudentCookie} from '@freckle/student-entities/ts/common/helpers/api-helper'

import {setupRenaissanceKeepalive} from './init'
import {Routes} from './routes'
import {useSelfStudentDepricated} from '../components/with-resources/utils'

type Props = {
  selfStudent: SelfStudentAttrs
  children: React.ReactElement
}

function Middleware({children, selfStudent}: Props): React.ReactElement {
  const location = useLocation()
  const rlKeepAliveUrl = selfStudent.renaissanceKeepalive?.url
  const rlKeepAliveIntervalMinutes = selfStudent.renaissanceKeepalive?.intervalMinutes

  React.useEffect(() => {
    if (
      rlKeepAliveUrl !== null &&
      rlKeepAliveUrl !== undefined &&
      rlKeepAliveIntervalMinutes !== null &&
      rlKeepAliveIntervalMinutes !== undefined
    ) {
      setupRenaissanceKeepalive({
        url: rlKeepAliveUrl,
        intervalMinutes: rlKeepAliveIntervalMinutes
      })
    }
  }, [rlKeepAliveUrl, rlKeepAliveIntervalMinutes])

  React.useEffect(() => {
    const setup = async () => {
      await setupAnalytics(selfStudent.id)
      await setAnalyticsProperties(selfStudent.id, {
        rosteredGradeLevel: selfStudent.grade,
        mathStandardSetId: selfStudent.mathStandardSet.id
      })
    }
    if (!Globals.hasAnalytics) {
      setup()
    }
  }, [selfStudent.id, selfStudent.grade, selfStudent.mathStandardSet.id])

  React.useEffect(() => {
    trackStudentRouteChanges(location.pathname)
  }, [location.pathname])

  return children
}

function MiddlewareWrapper(props: {children: React.ReactElement}): React.ReactElement {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    async function checkAuth() {
      try {
        await RouterHelpers.checkAuthStatus(Globals.routing.studentLoggedIn, checkStudentCookie)
        setIsLoggedIn(true)
      } catch (e) {
        setIsLoggedIn(false)
      } finally {
        setIsLoading(false)
      }
    }
    checkAuth()
  }, [])

  if (isLoading) {
    return <></>
  }

  const IsLoggedIn = () => {
    const {selfStudent} = useSelfStudentDepricated()
    return (
      <WithResources
        resources={{selfStudent}}
        render={({data: {selfStudent}}) => <Middleware selfStudent={selfStudent} {...props} />}
      />
    )
  }
  return isLoggedIn ? <IsLoggedIn /> : <Navigate to={Routes.login()} />
}

export default MiddlewareWrapper
