import * as React from 'react'
import DelayedSpinner from '@freckle/student-entities/ts/common/components/spinner-wrapper/delayed-spinner'
import {Navigate} from 'react-router-dom'

import {Wrapper} from './wrapper'
import {type Props as WrapperProps} from './wrapper'
import type {RoutesWithQueryParams} from './routes'
import {mkUrl} from './routes'
import {useRouteBlockedDueToFocusMode} from './routes/use-route-blocked-due-to-focus-mode'

type Props<P> = {
  wrapperParams?: WrapperProps
  component: React.ComponentType<P>
  path: keyof RoutesWithQueryParams
}

export function LoggedRoute<P>(props: Props<P>): React.ReactElement {
  const {wrapperParams: mWrapperParams, component, path} = props

  const wrapperParams =
    mWrapperParams !== null && mWrapperParams !== undefined ? mWrapperParams : {}

  return (
    <>
      <Wrapper {...wrapperParams}>
        <ComponentWithMappedProps component={component} path={path} />
      </Wrapper>
    </>
  )
}

// TODO: Below function is any because it passes routeParams while ignoring
// component Props. `withRouterToProps` likely depends on this behavior. We
// should replace with hooks like useNavigate.

type ComponentWithMappedPropsT<P> = {
  component: React.ComponentType<P>
  path: keyof RoutesWithQueryParams
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function ComponentWithMappedProps(props: ComponentWithMappedPropsT<any>): React.ReactElement {
  const {component: Component, path} = props

  const {data: isStudentBlocked} = useRouteBlockedDueToFocusMode(path)
  if (isStudentBlocked === undefined) {
    return <DelayedSpinner />
  }
  if (isStudentBlocked) {
    return <Navigate to={mkUrl('/start/assignments', {}, {})} />
  }
  return <Component />
}
