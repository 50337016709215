import {CONFIG} from '@freckle/student-entities/ts/common/config'
import ApiRoutesFancy from '@freckle/student-entities/ts/common/routers/api-routes-fancy'
import {getEnvironment} from '@freckle/student-entities/ts/config/environment'
import {PATHS} from '@freckle/student-materials/src/helpers/paths'

const fancyPaths = ApiRoutesFancy.createPaths(PATHS.unversionedAPIUrl)

/**
 * A higher-order function that caches the result of an async function.
 *
 * @example
 * // The first call will hit the API:
 * async function _fetchStudents(): Promise<StudentT> { ... }
 * const fetchStudents = legacyWithCache(_fetchStudents)
 *
 * fetchStudents().then(console.log)
 * // [student1]
 *
 * // Subsequent calls will return the cached value if present without hitting the API:
 * fetchStudents().then(console.log)
 * // [student1]
 *
 * // Cache busting is done with the `reload` option:
 * fetchStudents({reload: true}).then(console.log)
 * // [student1, student2]
 */
// Legacy caching helper, fetch into Redux with ResourceStatus instead
export function legacyWithCache<T>(
  f: () => Promise<T>
): (options?: {reload: boolean}) => Promise<T> {
  let cache: T | null = null
  return options => {
    if (cache === null || (options !== null && options !== undefined && options.reload)) {
      return f().then(a => {
        cache = a
        return a
      })
    }

    return Promise.resolve(cache)
  }
}

/**
 * Like `legacyWithCache`, but caches the async result in a dictionary keyed on function arguments.
 *
 * Because the dictionary can be keyed on strings or numbers, the async function supplied should
 * use `CHelper.cacheKeyToNumber` or `CHelper.cacheKeyToString` internally.
 *
 * @example
 * // A teacher resource that depends on the id to fetch
 * async function _fetchTeacherResource(teacherId: number): Promise<ResourceT> { ... }
 * const fetchTeacherResource = legacyWithCacheDictionary(_fetchTeacherResource)
 *
 * const firstTeacherId = 1
 *
 * // First call hits the API
 * fetchTeacherResource(firstTeacherId).then(console.log)
 * // [resource1]
 *
 * // Subsequent calls with the same id will return the cached value:
 * fetchTeacherResource(firstTeacherId).then(console.log)
 * // [resource1]
 *
 * // Returns a different values when the argument changes:
 *
 * const secondTeacherId = 2
 *
 * fetchTeacherResource(secondTeacherId).then(console.log)
 * // [resource2]
 *
 * // Previous cache was saved:
 * fetchTeacherResource(firstTeacherId).then(console.log)
 * // [resource1]
 *
 * // Cache busting is done with the `reload` option:
 * fetchTeacherResource(firstTeacherId, {reload: true}).then(console.log)
 * // [resource1, resource3]
 */
type CacheWithDictionaryT<K, T> = {
  fetchWithCache: (
    key: K,
    options?: {
      reload: boolean
    }
  ) => Promise<T>
  markCacheStale: () => void
}

// Legacy caching helper, fetch into Redux with ResourceStatus instead
export function legacyWithCacheDictionary<K, T>(
  f: (key: K) => Promise<T>
): CacheWithDictionaryT<K, T> {
  const cache = new Map()
  let isStale = false
  const fetchWithCache = (key: K, options?: {reload: boolean}) => {
    const cached = cache.get(key)
    if (
      cached === undefined ||
      (options !== null && options !== undefined && options.reload) ||
      isStale
    ) {
      return f(key).then(value => {
        cache.set(key, value)
        isStale = false
        return value
      })
    }

    return Promise.resolve(cached)
  }

  const markCacheStale = () => {
    isStale = true
  }
  return {fetchWithCache, markCacheStale}
}

const CommonApiHelper = {
  fancyPaths
}

export function checkInternetConnectivity(
  pingUrl: string,
  normalRetryPeriod: number,
  failedRetryPeriod: number,
  successCallback: () => void,
  failureCallback: () => void
) {
  const tryReachability = () => {
    $.ajax({
      url: pingUrl,
      type: 'GET',
      // If we're in the middle of destroying the session, we don't
      // want a concurrent request to resurrect it. This is rare, but
      // it can keep a deleted teacher logged in.
      xhrFields: {
        withCredentials: false
      },
      data: {commitTimestamp: CONFIG.COMMIT_UNIX_TIMESTAMP, commitSHA: CONFIG.COMMIT_SHA}
    })
      .done(() => {
        successCallback()
        setTimeout(tryReachability, normalRetryPeriod)
      })
      .fail(() => {
        failureCallback()
        // switch to rapid retry mode when the connection is down and prevent
        // user from interacting with the application until it's over
        setTimeout(tryReachability, failedRetryPeriod)
      })
  }

  // try to reach our servers every x secs to validate connection health
  setTimeout(tryReachability, normalRetryPeriod)
}

export function isProductionEnv(): boolean {
  return getEnvironment(window.location.hostname) === 'production'
}

export function isStaging(): boolean {
  return getEnvironment(window.location.hostname) === 'staging'
}

export function isTestEnv(): boolean {
  return getEnvironment(window.location.hostname) === 'test'
}

export function isLocalhost(): boolean {
  return getEnvironment(window.location.hostname) === 'localhost'
}

export default CommonApiHelper
