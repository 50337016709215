/* IE Polyfill for Custom Event
 *
 * IE doesn't support the `Event` constructor, so we are using `CustomEvent` API.
 * This file create the Polyfill for us to use the CustomEvent constructor across
 * all browsers
 *
 * Source
 * https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
 */

;(function () {
  if (typeof window.CustomEvent === 'function') {
    return false
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function CustomEvent(event: any, params: any): CustomEvent<any> {
    params = params || {bubbles: false, cancelable: false, detail: undefined}
    const evt = document.createEvent('CustomEvent')
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail)
    return evt
  }

  CustomEvent.prototype = window.Event.prototype

  //@ts-ignore: TS doesn't allow adding to `window`
  window.CustomEvent = CustomEvent
})()

export {}
