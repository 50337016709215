/*
The queueMicrotask() method, which is exposed on the Window or Worker interface,
queues a microtask to be executed at a safe time prior to control returning to the browser's event loop.
We need this polyfill for Safari 12, and queueMicrotask requires 12.1
*/

;(function () {
  if (typeof window.queueMicrotask !== 'function') {
    window.queueMicrotask = function (callback) {
      Promise.resolve()
        .then(callback)
        .catch(e =>
          setTimeout(() => {
            throw e
          })
        )
    }
  }
})()
