import {type ResourceStatusT} from '@freckle/resource-status'
import {type LanguageT} from '@freckle/student-entities/ts/common/models/language'
import {type StoreState, type LanguagesStateT} from '@freckle/student/ts/reducers/types'
import {type LanguagesActionT} from '@freckle/student/ts/actions/languages'

const defaultLanguagesState: LanguagesStateT = {
  status: 'idle'
}

export function languagesReducer(
  state: LanguagesStateT = defaultLanguagesState,
  action: LanguagesActionT
): LanguagesStateT {
  switch (action.type) {
    case 'LANGUAGES_GET_REQUEST':
      return state.status === 'complete'
        ? {status: 'updating', data: state.data}
        : {status: 'loading'}
    case 'LANGUAGES_GET_RESPONSE': {
      const {languages} = action
      return {status: 'complete', data: languages, hasUpdated: false}
    }
    case 'LANGUAGES_GET_ERROR':
      return {status: 'error', error: action.error}
    default:
      return state
  }
}

export function getLanguages(storeState: StoreState): ResourceStatusT<LanguageT[]> {
  return storeState.languages
}
