import * as React from 'react'
import {addMaybeClassName} from '@freckle/student-materials/src/helpers/classnames'
import {useSafeEffect} from '@freckle/react-hooks'

import {h1} from './typography.module.scss'
import {type HeadingProps} from './heading-props'

type Props = HeadingProps

// TODO: Props used in createElement do not match Props defined above
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function H1(props: Props): React.ReactElement<any> {
  const {children, isFocused = false, addClass, dataTest = 'page-title', ariaLabel} = props
  const className = addMaybeClassName(h1, addClass)
  const headingRef = React.useRef<HTMLElement>()
  useSafeEffect(() => {
    if (isFocused && headingRef.current) {
      headingRef.current.tabIndex = -1
      headingRef.current.focus()
    }
  }, [isFocused])
  return React.createElement(
    props.as ?? 'h1',
    {
      className,
      'data-test': dataTest,
      'aria-label': ariaLabel,
      ref: headingRef
    },
    children
  )
}

export default H1
