import * as React from 'react'
import {Link as RouterLink} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {type IconProp} from '@fortawesome/fontawesome-svg-core'
import {asHTMLAttributeValue} from '@freckle/maybe'
import {exhaustive} from '@freckle/exhaustive'
import {type SyntheticEvent} from 'react'

import {type ButtonSizeT} from './../button'
import {
  disabled as disabledStyle,
  fullWidth as fullWidthClass,
  leftIconContainer,
  rightIconContainer,
  btnPositionalStyle,
  link,
  sm,
  md,
  lg,
  xlg,
  btnPrimary,
  btnSecondary,
  btnTertiary,
  btnDanger,
  btnWarning,
  btnEducatorPrimary
} from './link.module.scss'

type LinkStyleT =
  | 'normal'
  | 'btn-primary'
  | 'btn-secondary'
  | 'btn-tertiary'
  | 'btn-danger'
  | 'btn-warning'
  | 'btn-educator-primary'

type LinkSizeT = ButtonSizeT

export type HrefProp = string | Location

export type Props = {
  /** `sm` or `md` or `lg` or `xlg` */
  size?: LinkSizeT
  /** Action of the button */
  onClick?: ((e: SyntheticEvent<Element, Event>) => void) | undefined
  /** Content of the link */
  children?: React.ReactNode
  /** Link to direct to */
  href: HrefProp
  /** Additional CSS Class to add to the link */
  addClass?: string
  /** Define the style of the Link */
  style?: LinkStyleT
  disabled?: boolean
  fullWidth?: boolean
  target?: string
  rel?: string
  autoFocus?: boolean
  dataTest?: string | null
  reactRouter?: boolean
  title?: string
  ariaLabel?: string
  leftIcon?: IconProp | null
  rightIcon?: IconProp | null
  tabIndex?: number
  screenReaderNode?: React.ReactNode
  role?: string
}

/**
 * This is the standard component used for links.
 * `Link` should be used when changing page, not when performing an action.
 * When performing an action used the component `Link`.
 *
 * It contains the most used attribute of the regular HTML `<a>` tag.
 */

function Link(props: Props): React.ReactElement<Props> {
  const {
    href,
    onClick,
    children,
    dataTest,
    disabled = false,
    autoFocus,
    addClass,
    style = 'normal',
    size = 'md',
    target,
    rel,
    reactRouter,
    title,
    ariaLabel,
    fullWidth,
    leftIcon,
    rightIcon,
    tabIndex,
    screenReaderNode,
    role
  } = props

  const addClassStr = addClass !== null && addClass !== undefined ? addClass : ''
  const disabledClass = disabled === true ? disabledStyle : ''

  const fullWidthClassName = fullWidth === true ? [fullWidthClass] : []

  const styleClass = () => {
    switch (style) {
      case null:
      case undefined:
      case 'normal':
        return null
      case 'btn-primary':
        return btnPrimary
      case 'btn-secondary':
        return btnSecondary
      case 'btn-tertiary':
        return btnTertiary
      case 'btn-danger':
        return btnDanger
      case 'btn-warning':
        return btnWarning
      case 'btn-educator-primary':
        return btnEducatorPrimary
      default:
        return exhaustive(style)
    }
  }
  const sizeClass = () => {
    switch (size) {
      case null:
      case undefined:
        return md
      case 'sm':
        return sm
      case 'md':
        return md
      case 'lg':
        return lg
      case 'xlg':
        return xlg
      default:
        return exhaustive(size)
    }
  }
  const className = [
    link,
    styleClass(),
    sizeClass(),
    disabledClass,
    addClassStr,
    ...fullWidthClassName
  ].join(' ')

  const dataTestObj = {'data-test': asHTMLAttributeValue(dataTest)}
  const autoFocusObj = {autoFocus: autoFocus ? true : undefined}
  const targetObj = {target: asHTMLAttributeValue(target)} as {target: typeof target | undefined}
  const relObj = {rel: asHTMLAttributeValue(rel)} as {rel: typeof rel | undefined}
  const onClickObj = {onClick: asHTMLAttributeValue(onClick)} as {
    onClick: typeof onClick | undefined
  }
  const titleObj = {title: asHTMLAttributeValue(title)} as {title: typeof title | undefined}
  const ariaLabelObj = {'aria-label': asHTMLAttributeValue(ariaLabel)} as {
    'aria-label': typeof ariaLabel | undefined
  }

  const roleObj = {role: asHTMLAttributeValue(role)} as {role: typeof role | undefined}

  const additionalProps = {
    ...autoFocusObj,
    ...dataTestObj,
    ...targetObj,
    ...relObj,
    ...onClickObj,
    ...titleObj,
    ...ariaLabelObj,
    ...roleObj
  }

  const leftIconNode =
    leftIcon !== null && leftIcon !== undefined ? (
      <span className={leftIconContainer}>
        <FontAwesomeIcon icon={leftIcon} />
      </span>
    ) : null

  const rightIconNode =
    rightIcon !== null && rightIcon !== undefined ? (
      <span className={rightIconContainer}>
        <FontAwesomeIcon icon={rightIcon} />
      </span>
    ) : null

  const linkContentInternal = (
    <>
      {leftIconNode}
      {children}
      {rightIconNode}
    </>
  )

  const linkContent = isButton(style) ? (
    <span className={btnPositionalStyle}>{linkContentInternal}</span>
  ) : (
    linkContentInternal
  )

  const content = (
    <>
      {screenReaderNode}
      {linkContent}
    </>
  )
  return reactRouter === true ? (
    <RouterLink to={href} className={className} {...additionalProps} tabIndex={tabIndex}>
      {content}
    </RouterLink>
  ) : (
    // TODO: Typecheck that href is a string when reactRouter is false
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <a href={href as any} className={className} {...additionalProps} tabIndex={tabIndex}>
      {content}
    </a>
  )
}

export default Link

function isButton(linkStyle?: LinkStyleT): boolean {
  switch (linkStyle) {
    case 'btn-primary':
    case 'btn-secondary':
    case 'btn-tertiary':
    case 'btn-danger':
    case 'btn-warning':
    case 'btn-educator-primary':
      return true
    case 'normal':
    case null:
    case undefined:
      return false
    default:
      return exhaustive(linkStyle, 'LinkStyleT')
  }
}
