import map from 'lodash/map'
import {type NonEmptyArray} from '@freckle/non-empty'
import {fromJust, mmap} from '@freckle/maybe'
import {
  type ParserT,
  Parser,
  array,
  record,
  number,
  string,
  boolean,
  mapStatic,
  field
} from '@freckle/parser'
import {humanReadableCommaJoin} from '@freckle/student-entities/ts/common/helpers/string-helper'

export type MathFactPracticeOperationTypeT = string

export const toMathFactPracticeOperationType = (
  operation: string
): MathFactPracticeOperationTypeT => operation
export const fromMathFactPracticeOperationType = (
  operation: MathFactPracticeOperationTypeT
): string => operation

export const mathFactPracticeOperationTypeParser: ParserT<MathFactPracticeOperationTypeT> =
  mapStatic(string(), toMathFactPracticeOperationType)

export type MathFactPracticeOperationT = {
  operation: MathFactPracticeOperationTypeT
  enabled: boolean
  lowestGrade: number
  highestGrade: number
  description: string
  sort: number
}

const parseAttrs: ParserT<MathFactPracticeOperationT> = record({
  operation: mathFactPracticeOperationTypeParser,
  enabled: boolean(),
  lowestGrade: number(),
  highestGrade: number(),
  description: string(),
  //TODO: Use field from the backend when it's present
  sort: field(mapStatic(string(), legacySortOrder), 'operation')
})

function legacySortOrder(operation: string): number {
  switch (operation) {
    case 'addition':
      return 0
    case 'subtraction':
      return 1
    case 'multiplication':
      return 2
    case 'division':
      return 3
    case 'integer_addition_subtraction':
      return 4
    case 'integer_multiplication_division':
      return 5
    default:
      return 6
  }
}

export const parseMathFactPracticeOperations = Parser.mkRun<Array<MathFactPracticeOperationT>>(
  array(parseAttrs)
)

export function formatOperations(
  allOperations: Array<MathFactPracticeOperationT>,
  operations: NonEmptyArray<MathFactPracticeOperationTypeT>
): string {
  const opStrings = map(operations, operation =>
    fromJust(
      mmap(
        fullOp => fullOp.description,
        allOperations.find(fullOp => fullOp.operation === operation)
      ),
      `Can't find ${fromMathFactPracticeOperationType(operation)} in all operations`
    )
  )
  return fromJust(
    humanReadableCommaJoin(opStrings),
    `Fact Practice assignment with empty operations array`
  )
}
