import {type Moment} from 'moment-timezone'
import {ajaxJsonCall} from '@freckle/ajax'
import {type ParserT, Parser, date, number, array, record, nullable} from '@freckle/parser'
import CApiHelper from '@freckle/student-entities/ts/common/helpers/common-api-helper'

import {mathFactPracticeOperationTypeParser} from './math-fact-practice-operation'
import {type MathFactPracticeOperationTypeT} from './math-fact-practice-operation'

export type FactPracticeSnapshotAttrs = {
  id: number
  studentId: number
  assignmentSessionId: number
  createdAt: Moment
  operation: MathFactPracticeOperationTypeT
  level: number
  accuracy: number | undefined | null
  numQuestionsAnswered: number | undefined | null
}

export const parseFactPracticeSnapshot: ParserT<FactPracticeSnapshotAttrs> = record({
  id: number(),
  studentId: number(),
  assignmentSessionId: number(),
  createdAt: date(),
  operation: mathFactPracticeOperationTypeParser,
  level: number(),
  accuracy: nullable(number()),
  numQuestionsAnswered: nullable(number())
})

const parseMathFactPracticeSnapshots = Parser.mkRun<Array<FactPracticeSnapshotAttrs>>(
  array(parseFactPracticeSnapshot)
)

export async function fetchLast3FactPracticeSnapshotsForStudent(): Promise<
  Array<FactPracticeSnapshotAttrs>
> {
  const url = CApiHelper.fancyPaths.v2.math_fact_practice_snapshots.latest3()
  const response = await ajaxJsonCall({url, method: 'GET'})
  return parseMathFactPracticeSnapshots(response)
}
