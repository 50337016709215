type GlobalsT = {
  hasAnalytics: boolean
  routing: StudentRoutingGlobalsT
}

type StudentRoutingGlobalsT = {
  studentLoggedIn: boolean
  isFirstPostLoginMount: boolean
  hasSeenDailyBonus: boolean
  hasSeenAssignmentRecommendation: boolean
}

const t: GlobalsT = {
  routing: {
    studentLoggedIn: false,
    isFirstPostLoginMount: false,
    hasSeenDailyBonus: false,
    hasSeenAssignmentRecommendation: false
  },
  hasAnalytics: false
}

export default t
