import {type CurrentTeacherStateT} from '@freckle/student/ts/reducers/types'
import {type CurrentTeacherActionT} from '@freckle/student/ts/actions/current-teacher'
import {type CurrentCourseActionT} from '@freckle/student/ts/actions/current-course'

const defaultCurrentTeacherState: CurrentTeacherStateT = {
  status: 'idle'
}

export function currentTeacherReducer(
  state: CurrentTeacherStateT = defaultCurrentTeacherState,
  action: CurrentTeacherActionT | CurrentCourseActionT
): CurrentTeacherStateT {
  switch (action.type) {
    case 'CURRENT_TEACHER_GET_REQUEST':
      return {status: 'loading'}
    case 'CURRENT_TEACHER_GET_REFETCH_REQUEST':
      return {status: 'reloading', data: action.currentTeacher}
    case 'CURRENT_TEACHER_GET_RESPONSE': {
      const {currentTeacher} = action
      return {status: 'complete', data: currentTeacher, hasUpdated: false}
    }
    case 'CURRENT_TEACHER_GET_ERROR':
      return {status: 'error', error: action.error}
    case 'CURRENT_COURSE_GET_RESPONSE':
    case 'CURRENT_COURSE_GET_REFETCH_REQUEST':
      // Reset currentTeacher on currentCourse load, to be reloaded by hook
      return {status: 'idle'}
    default:
      return state
  }
}
