import {useTranslation, Translation, Trans} from 'react-i18next'
import * as React from 'react'
import type {LangT} from '@freckle/student-materials/src/helpers/languages'

export type TranslateT = (msg: string, vars?: Record<string, string | number | boolean>) => string

// Returns a `t` function that more closely aligns with our usage
//
// This lets us avoid unsafely sending `HTMLText` to Locize without first unwrapping it.
// See the `useHtmlText` hook for a `t` function that can translate with `HTMLText`
//
export function useSafeTranslation(ns?: string): {
  t: TranslateT
  i18n: {language: LangT; changeLanguage: (lang: string) => void}
} {
  // eslint-disable-next-line no-restricted-syntax
  return useTranslation(ns)
}

// Returns a `Translation` that more closely aligns with our usage
export const SafeTranslation = (props: {
  children: (t: TranslateT) => React.ReactNode
  ns?: string
}): React.ReactElement => (
  // eslint-disable-next-line no-restricted-syntax
  <Translation {...props} />
)

// Returns a `Trans` that more closely aligns with our usage
export const SafeTrans = (props: {
  i18nKey: string
  components?: readonly React.ReactElement[] | {readonly [tagName: string]: React.ReactElement}
  values?: Record<string, string | number | boolean>
  // eslint-disable-next-line no-restricted-syntax
}): React.ReactElement => <Trans {...props} />
