import {isFetching} from '@freckle/resource-status'
import type {ThunkAction} from 'redux-thunk'
import {
  type ItemOwnershipT,
  fetchItemOwnerships
} from '@freckle/student/ts/common/models/item-ownership'
import {type StoreState} from '@freckle/student/ts/reducers/types'

export type ItemOwnershipsActionT =
  | ItemOwnershipsGetRequestActionT
  | ItemOwnershipsGetResponseActionT
  | ItemOwnershipsGetErrorActionT

type ItemOwnershipsGetRequestActionT = {
  type: 'ITEM_OWNERSHIPS_GET_REQUEST'
}

function itemOwnershipsGetRequestAction(): ItemOwnershipsGetRequestActionT {
  return {type: 'ITEM_OWNERSHIPS_GET_REQUEST'}
}

type ItemOwnershipsGetResponseActionT = {
  type: 'ITEM_OWNERSHIPS_GET_RESPONSE'
  itemOwnerships: ItemOwnershipT[]
}

function itemOwnershipsGetResponseAction(
  itemOwnerships: ItemOwnershipT[]
): ItemOwnershipsGetResponseActionT {
  return {
    type: 'ITEM_OWNERSHIPS_GET_RESPONSE',
    itemOwnerships
  }
}

type ItemOwnershipsGetErrorActionT = {
  type: 'ITEM_OWNERSHIPS_GET_ERROR'
  error: unknown
}

function itemOwnershipsGetErrorAction(error: unknown): ItemOwnershipsGetErrorActionT {
  return {type: 'ITEM_OWNERSHIPS_GET_ERROR', error}
}

export function loadItemOwnerships(): ThunkAction<
  void,
  StoreState,
  unknown,
  ItemOwnershipsActionT
> {
  return async (dispatch, getState) => {
    const {itemOwnerships} = getState()
    if (isFetching(itemOwnerships)) {
      return
    }

    dispatch(itemOwnershipsGetRequestAction())
    try {
      const itemOwnerships = await fetchItemOwnerships()
      dispatch(itemOwnershipsGetResponseAction(itemOwnerships))
    } catch (error) {
      dispatch(itemOwnershipsGetErrorAction(error))
    }
  }
}
