import {type Moment} from 'moment-timezone'
import sortBy from 'lodash/sortBy'
import {ajaxJsonCall} from '@freckle/ajax'
import {
  type ParserT,
  record,
  Parser,
  string,
  number,
  date,
  array,
  boolean,
  nullable
} from '@freckle/parser'
import {
  type IdentityProviderT,
  parser as identityProviderParser
} from '@freckle/student-entities/ts/roster/identity-provider'
import {apiFetch} from '@freckle/student-entities/ts/common/helpers/api-fetch'
import CApiHelper, {
  legacyWithCache
} from '@freckle/student-entities/ts/common/helpers/common-api-helper'

export type CourseAttrs = {
  id: number
  name: string
  teacherId: number
  code: string
  createdAt: Moment
  updatedAt: Moment
  archived: boolean
  cleverSectionId: string | undefined | null
  classLinkCourseId: string | undefined | null
  sisId: string | undefined | null
  passwordsEnabled: boolean
  idpSubject: string | undefined | null
  renaissanceRPIdentifier: string | undefined | null
  hidden: boolean
  identityProvider: IdentityProviderT | undefined | null
  idpManaged: boolean
}

const parseAttrs: ParserT<CourseAttrs> = record({
  id: number(),
  name: string(),
  teacherId: number(),
  code: string(),
  createdAt: date(),
  updatedAt: date(),
  archived: boolean(),
  cleverSectionId: nullable(string()),
  classLinkCourseId: nullable(string()),
  sisId: nullable(string()),
  passwordsEnabled: boolean(),
  idpSubject: nullable(string()),
  renaissanceRPIdentifier: nullable(string()),
  hidden: boolean(),
  identityProvider: nullable(identityProviderParser),
  idpManaged: boolean()
})

export const parseCourse = Parser.mkRun<CourseAttrs>(parseAttrs)

const parseCourses = Parser.mkRun<Array<CourseAttrs>>(array(parseAttrs))

/**
 * Utilities
 */

export function isCourseHidden(course: CourseAttrs): boolean {
  return course.hidden === true
}

export function isCourseArchived(course: CourseAttrs): boolean {
  return course.archived === true
}

async function fetchCourses(): Promise<Array<CourseAttrs>> {
  const url = CApiHelper.fancyPaths.v2.courses._0()
  const response = await ajaxJsonCall({url, method: 'GET'})
  const courses = parseCourses(response)
  return sortBy(courses, course => course.id)
}

export const fetchCoursesWithCache = legacyWithCache(fetchCourses)

export async function fetchCurrentCourse(): Promise<CourseAttrs> {
  const url = CApiHelper.fancyPaths.v2.courses.current()
  const res = await apiFetch({url, method: 'GET'})
  return parseCourse(res)
}
