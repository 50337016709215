import sortBy from 'lodash/sortBy'
import {ajaxJsonCall} from '@freckle/ajax'
import {urlWithQueryParams} from '@freckle/query-params'
import {
  type ParserT,
  Parser,
  string,
  record,
  array,
  number,
  nullable,
  boolean
} from '@freckle/parser'
import {type ContentAreaT} from '@freckle/student-entities/ts/common/helpers/content-area'
import {type RlStandardIdT} from '@freckle/student-entities/ts/common/types/rl-standard-id'
import * as RlStandardId from '@freckle/student-entities/ts/common/types/rl-standard-id'
import {type RlSkillUspIdT} from '@freckle/student-entities/ts/common/types/rl-skill-usp-id'
import * as RlSkillUspId from '@freckle/student-entities/ts/common/types/rl-skill-usp-id'
import * as RlSubSkillUspId from '@freckle/student-entities/ts/common/types/rl-subskill-usp-id'
import CApiHelper from '@freckle/student-entities/ts/common/helpers/common-api-helper'
import find from 'lodash/find'
import {maybe} from '@freckle/maybe'

import {keepOnlySkillSetSkillsAttachedToStandard} from './skill-set'
import {RlSkillT, RlSubSkillT} from '../types/rl-skill'
import {SkillSetSkillT} from '../types/skill-set'

const parseRlSubSkillAttrs: ParserT<RlSubSkillT> = record({
  uspId: RlSubSkillUspId.parse,
  parentUspId: string(),
  standardSetId: string(),
  name: string(),
  shortName: string(),
  progressionOrder: number()
})

const commonParserAtts = {
  uspId: RlSkillUspId.parse,
  name: string(),
  shortName: string(),
  progressionOrder: number(),
  grade: number(),
  standardSetId: string(),
  standardId: RlStandardId.parse,
  isFocus: boolean()
}

export const parseRlSkillAttrs: ParserT<RlSkillT> = record({
  ...commonParserAtts,
  subSkills: nullable(array(parseRlSubSkillAttrs))
})

export const parseRlSkills = Parser.mkRun<Array<RlSkillT>>(array(parseRlSkillAttrs))

export async function fetchRlSkills(
  contentArea: ContentAreaT,
  standardSetId: string,
  standardId?: Array<RlStandardIdT> | null
): Promise<Array<RlSkillT>> {
  const queryParams = {
    standardSetId,
    standardId
  }

  const url = urlWithQueryParams(
    CApiHelper.fancyPaths.v3.content_area.content_area_skills._(contentArea),
    queryParams
  )
  const response = await ajaxJsonCall({url, method: 'GET'})
  const parsedSkills = parseRlSkills(response)
  return sortBy(parsedSkills, s => s.progressionOrder)
}

export function calcSkillsCompleted(startingSkill: RlSkillT, currentSkill: RlSkillT): number {
  const currentSkillIndex = currentSkill.progressionOrder
  const startingSkillIndex = startingSkill.progressionOrder
  const diffLevel = currentSkillIndex - startingSkillIndex
  return Math.max(diffLevel, 0)
}

export function calcSkillsCompletedBySkillId(
  startingSkill: RlSkillUspIdT,
  currentSkill: RlSkillUspIdT,
  wordStudySkillSet: Array<SkillSetSkillT>
): number {
  const skills: Array<RlSkillT> = keepOnlySkillSetSkillsAttachedToStandard(wordStudySkillSet)

  const getProgressionOrderOfSkill = (skillId: RlSkillUspIdT) =>
    maybe(
      () => 0,
      p => p.progressionOrder,
      find(skills, s => s.uspId === skillId)
    )

  const currentProgressionOrder = getProgressionOrderOfSkill(currentSkill)
  const startingProgressionOrder = getProgressionOrderOfSkill(startingSkill)

  const diffLevel = currentProgressionOrder - startingProgressionOrder
  return Math.max(diffLevel, 0)
}
