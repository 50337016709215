export type PiggyStoreStatusActionT = PiggyStoreStatusAddCoinsT | PiggyStoreStatusResetCoinsT

type PiggyStoreStatusAddCoinsT = {
  type: 'PIGGY_STORE_STATUS_ADD_COINS'
  coins: number
}

type PiggyStoreStatusResetCoinsT = {
  type: 'PIGGY_STORE_STATUS_RESET_COINS'
}

export function piggyStoreStatusAddCoinsAction(coins: number): PiggyStoreStatusAddCoinsT {
  return {type: 'PIGGY_STORE_STATUS_ADD_COINS', coins}
}

export const piggyStoreStatusResetCoinsAction = (): PiggyStoreStatusResetCoinsT => ({
  type: 'PIGGY_STORE_STATUS_RESET_COINS'
})
