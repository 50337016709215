type ParsedTarget =
  | {
      tag: 'link'
      el: HTMLLinkElement
    }
  | {
      tag: 'element'
      el: HTMLElement
    }
  | {
      tag: 'unknown'
    }

// N.B.: EventTarget seems identical to HTMLElement but is a distinct type
type ParsableEvent = Inexact<{
  target: EventTarget
}>

// Events do not carry well-typed target elements, so this attempts to parse and centralize
// any unavoidable casting
export const parseElementFromEvent = (e: ParsableEvent): ParsedTarget => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const el = e.target as any

    if (
      el.tagName === 'LINK' &&
      el.getAttribute('rel') === 'stylesheet' &&
      typeof el.href === 'string'
    ) {
      return {
        tag: 'link',
        el
      }
    } else {
      return {
        tag: 'element',
        el
      }
    }
  } catch (e) {
    return {
      tag: 'unknown'
    }
  }
}
