import * as React from 'react'
import {useSafeTranslation} from '@freckle/student-materials/src/helpers/translate'

import {ScreenAlert} from './screen-alert'
import {centered} from './narrow-screen-alert.module.scss'
import IconTiltDevice from './icon-tilt-device.svg'

type Props = {
  noImage?: boolean
}

export function PotraitScreenAlert(props: Props): React.ReactElement {
  const {t} = useSafeTranslation()
  const {noImage} = props
  const imageUrl = noImage ? null : IconTiltDevice

  return (
    <div className={centered}>
      <ScreenAlert
        imageSrc={imageUrl}
        headingText={t('Oops! Your screen is not wide enough')}
        bodyText={t('Try tilting your device to go back to Freckle')}
      />
    </div>
  )
}
