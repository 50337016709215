import map from 'lodash/map'
import StorageHelper from '@freckle/student-entities/ts/common/helpers/storage-helper'
import {Routes} from '@freckle/student/ts/common/routers/routes'
import {resetAfterSignout} from '@freckle/student/ts/common/routers/init'

const finalDestinationCookieKeyword = 'studentLoginRedirectDest'

type StreamlinedLocationObj = {
  readonly hash: string
  readonly pathname: string
}

export function getFinalDestinationPath(
  locationObj: StreamlinedLocationObj,
  noRedirectPaths: Array<string>
): string | null {
  const isHashPathRoute = locationObj.hash.startsWith('#/') && locationObj.pathname === '/'
  const locationProp = isHashPathRoute ? 'hash' : 'pathname'

  // In case `location.prop` is `hash`, we need to remove the hash symbol
  const destPathWithOrWithoutQueryStr = toBrowserRoute(locationObj[locationProp])
  const isInvalidPath =
    destPathWithOrWithoutQueryStr === '/' ||
    destPathWithOrWithoutQueryStr === '' ||
    !destPathWithOrWithoutQueryStr.startsWith('/')

  if (isInvalidPath) {
    return null
  }

  const queryStringStartIndex = destPathWithOrWithoutQueryStr.indexOf('?')
  const finalDestPath =
    queryStringStartIndex === -1
      ? destPathWithOrWithoutQueryStr
      : destPathWithOrWithoutQueryStr.substring(0, queryStringStartIndex)

  return noRedirectPaths.includes(finalDestPath) ? null : finalDestPath
}

export function toBrowserRoute(path: string): string {
  if (path.startsWith('#/')) {
    return path.replace('#/', '/')
  }

  if (path.includes('#/')) {
    return path.replace('#/', '')
  }

  return path
}

export default {
  finalDestinationCookieKeyword,

  checkAuthStatus: function (
    studentLoggedIn: boolean,
    checkFunction: () => Promise<void>
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      if (studentLoggedIn) {
        return resolve()
      } else {
        // If student is not logged, store the final destination for future redirect
        const doFailure = () => {
          const routeWeShouldNotRedirectTo = [
            Routes.directLogin(),
            Routes.login(),
            Routes.loginQr(),
            Routes.signout()
          ]

          const routeWithoutHash = map(routeWeShouldNotRedirectTo, s => s.substring(1))
          // React Router adds a slash
          const routeWithLeadingSlash = map(routeWithoutHash, s => `/${s}`)
          const routes = [...routeWithoutHash, ...routeWithLeadingSlash]
          const finalDestinationPath = getFinalDestinationPath(location, routes)

          if (finalDestinationPath) {
            StorageHelper.setSecureCookie(finalDestinationCookieKeyword, finalDestinationPath)
          }

          resetAfterSignout()
          reject('Unauthorized')
        }

        return checkFunction().then(resolve, doFailure)
      }
    })
  }
}
