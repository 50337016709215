import find from 'lodash/find'

export type AutomatedAudio = 'default' | 'always' | 'never'

export const AutomatedAudios = {
  Default: 'default' as AutomatedAudio,
  Always: 'always' as AutomatedAudio,
  Never: 'never' as AutomatedAudio,

  all(): Array<AutomatedAudio> {
    return [AutomatedAudios.Default, AutomatedAudios.Always, AutomatedAudios.Never]
  },

  parse(text: string): AutomatedAudio | undefined | null {
    return find(AutomatedAudios.all(), value => value === text)
  }
}
