import isNil from 'lodash/isNil'
import {saferStringify} from '@freckle/parser'
import {HandledError} from '@freckle/student-entities/ts/common/exceptions/handled-error'
import {GetSelfStudentR200ResponseRenaissanceKeepalive} from '@freckle/fancy-api'

import BugsnagHelper from './exception-handlers/bugsnag-helper'

export interface RenaissanceKeepaliveT extends GetSelfStudentR200ResponseRenaissanceKeepalive {}

class RLKeepAliveError extends HandledError {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(e: any) {
    super(saferStringify(e))
    this.name = 'RLKeepAliveError'
  }
}

let intervalId: ReturnType<typeof setInterval> | null = null
export function setupRenaissanceKeepaliveIfNecessary(
  keepAlive?: RenaissanceKeepaliveT | null
): ReturnType<typeof setInterval> | null {
  if (intervalId !== null) {
    return intervalId
  }

  if (!isNil(keepAlive)) {
    const {url, intervalMinutes} = keepAlive

    intervalId = setInterval(() => {
      sendKeepalive(url)
    }, intervalMinutes * 60000)
  }

  return intervalId
}

async function sendKeepalive(keepaliveUrl: string) {
  const init: RequestInit = {
    method: 'GET',
    mode: 'no-cors'
  }
  try {
    await fetch(keepaliveUrl, init)
  } catch (e) {
    // fetching the keepalive url is a best effort task, so log it as handled
    BugsnagHelper.logError(new RLKeepAliveError(e))
  }
}

export function getRenaissanceKeepaliveIntervalId(): ReturnType<typeof setInterval> | null {
  return intervalId
}
