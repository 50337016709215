import * as React from 'react'
import {addMaybeClassName} from '@freckle/student-materials/src/helpers/classnames'

import {h2} from './typography.module.scss'
import {type HeadingProps} from './heading-props'

type Props = HeadingProps

export default function H2({as = 'h2', ...restProps}: Props): React.ReactElement<Props> {
  const {children, addClass, dataTest, id, ariaLabel, dangerouslySetInnerHTML} = restProps
  const className = addMaybeClassName(h2, addClass)
  const mDangerouslySetInnerHTML =
    dangerouslySetInnerHTML !== null && dangerouslySetInnerHTML !== undefined
      ? {dangerouslySetInnerHTML}
      : {}

  return React.createElement(
    as,
    {
      className,
      id,
      'data-test': dataTest,
      'aria-label': ariaLabel,
      ...mDangerouslySetInnerHTML
    },
    children
  )
}
