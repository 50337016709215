import * as React from 'react'
import {type ResourceStatusT} from '@freckle/resource-status'
import {exhaustive} from '@freckle/exhaustive'
import {mkNonEmpty, headOnNonEmpty, type NonEmptyArray} from '@freckle/non-empty'

import {WithResourcesError} from './with-resources-error'

type WithResourcesProps = {
  error: (a: Error) => React.ReactElement
  initialLoading: () => React.ReactElement | null
  reloading: () => React.ReactElement | null
}

type WithResourcesFetchState<T> =
  | {tag: 'initial-load'}
  | {tag: 'errors'; errors: NonEmptyArray<Error>}
  | {tag: 'data'; data: {[Property in keyof T]: T[Property]}; updatingOrReloading: boolean}

export function WithResources<T extends {[key: string]: unknown}>(
  props: {
    render: (props: {data: {[Property in keyof T]: T[Property]}}) => React.ReactElement | null
    resources: {[Property in keyof T]: ResourceStatusT<T[Property]>}
  } & WithResourcesProps
): React.ReactElement | null {
  const {resources, render, error, initialLoading, reloading} = props

  const fetchState: WithResourcesFetchState<T> = React.useMemo(() => {
    const resourceKeys = Object.keys(resources) as [keyof typeof resources]
    let errors: Error[] = []
    let updatingOrReloading = false

    const data = {} as {[Property in keyof T]: T[Property]}

    for (let i = 0; i < resourceKeys.length; i++) {
      const modelKey = resourceKeys[i]
      const resource = resources[modelKey]
      switch (resource.status) {
        case 'idle':
          break
        case 'loading':
          break
        case 'error':
          if (resource.error instanceof Error) {
            errors = [resource.error, ...errors]
          } else {
            errors = [new WithResourcesError(resource.error, modelKey), ...errors]
          }
          break
        case 'reloading':
          updatingOrReloading = true
          data[modelKey] = resource.data
          break
        case 'complete':
          data[modelKey] = resource.data
          break
        case 'updating':
          updatingOrReloading = true
          data[modelKey] = resource.data
          break
        case 'updating-error':
          //We depend on the component that caused the `updating-error` to handle this
          data[modelKey] = resource.data
          break
        default:
          return exhaustive(resource)
      }
    }

    const hasFetchedAllData = Object.keys(data).length === resourceKeys.length
    const nonEmptyErrors = mkNonEmpty(errors)

    return nonEmptyErrors !== null && nonEmptyErrors !== undefined
      ? {tag: 'errors', errors: nonEmptyErrors}
      : hasFetchedAllData
      ? {tag: 'data', data, updatingOrReloading}
      : {tag: 'initial-load'}
  }, [resources])

  switch (fetchState.tag) {
    case 'errors':
      return error(headOnNonEmpty(fetchState.errors))
    case 'initial-load':
      return initialLoading()
    case 'data': {
      const {data, updatingOrReloading} = fetchState
      return (
        <>
          {
            // We would still like to keep the child component rendered while refetching resources
            // So render the loading screen and the child component
            updatingOrReloading && reloading()
          }
          {render({data})}
        </>
      )
    }
    default:
      return exhaustive(fetchState)
  }
}
