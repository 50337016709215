/* eslint-disable react-refresh/only-export-components */
import * as React from 'react'
import {LoggedRoute} from '@freckle/student/ts/common/routers/logged-route'
import {ContentAreas} from '@freckle/student-entities/ts/common/helpers/content-area'
import type {LangT} from '@freckle/student-materials/src/helpers/languages'
import type {SelfAssignedTargetedPracticeTypeT} from '@freckle/student-entities/ts/math/common/models/targeted-practice-type/self-assigned-targeted-practice-type'

import type {PathData} from './path-data'

const NumberFactsProgressHandler = React.lazy(
  () => import('@freckle/student/ts/math/number-facts/components/progress-page')
)
const NumberBasicsQuestionDebuggerHandler = React.lazy(
  () => import('@freckle/student/ts/math/number-basics/components/debugger')
)
const MathPracticeHintDebugHandler = React.lazy(
  () =>
    import(
      '@freckle/student/ts/math/adaptive/components/hint-debug/math-practice-hint-debug-handler'
    )
)
const NumberBasicsBriefingPageHandler = React.lazy(
  () => import('@freckle/student/ts/math/number-basics/components/briefing')
)
const NumberBasicsSessionHandler = React.lazy(
  () => import('@freckle/student/ts/math/number-basics/components/session')
)
const MathMenuHandler = React.lazy(
  () => import('@freckle/student/ts/math/menu/components/math-menu')
)
const MathAssessmentQuestionSessionHandler = React.lazy(
  () => import('@freckle/student/ts/math/assessment/components/index')
)
const MathIblVideoHandler = React.lazy(() => import('@freckle/student/ts/math/ibl/components'))
const MathTargetedHandler = React.lazy(
  () => import('./../../../math/targeted/components/math-targeted-handler')
)
const MathAdaptiveSessionOrDiagnosticHandler = React.lazy(
  () => import('@freckle/student/ts/math/adaptive/components/session-or-diagnostic-handler')
)
const MathAdaptiveTeacherAssignedSessionHandler = React.lazy(
  () => import('./../../../math/adaptive/components/teacher-assigned-session-handler')
)
const MathAdaptiveDebugHandler = React.lazy(
  () => import('@freckle/student/ts/math/adaptive/components/debug-handler')
)
const MathTargetedSelfAssignedHandler = React.lazy(
  () => import('@freckle/student/ts/math/targeted/components/self-assigned-handler')
)
const FactPracticeSelfAssignedSessionHandler = React.lazy(
  () => import('@freckle/student/ts/math/fact-practice/components/self-assigned-handler')
)
const FactPracticeTeacherAssignedSessionHandler = React.lazy(
  () => import('@freckle/student/ts/math/fact-practice/components/teacher-assigned-handler')
)
const NumberFactsSelfAssignedHandler = React.lazy(
  () => import('@freckle/student/ts/math/number-facts/components/self-assigned-handler')
)
const NumberFactsTeacherAssignedHandler = React.lazy(
  () => import('@freckle/student/ts/math/number-facts/components/teacher-assigned-handler')
)
const MathDomainSelectionHandler = React.lazy(
  () => import('@freckle/student/ts/math/adaptive/components/domain-selection')
)
const MathQuestionEditor = React.lazy(
  () => import('@freckle/student/ts/math/common/components/editor')
)
const DomainBriefingHandler = React.lazy(
  () => import('@freckle/student/ts/math/adaptive/components/briefing')
)
const MathDepthOfKnowledgeSessionHandler = React.lazy(
  () => import('@freckle/student/ts/math/depth-of-knowledge/components/adaptive')
)
const MathTargetedDepthOfKnowledgeSessionHandler = React.lazy(
  () => import('@freckle/student/ts/math/depth-of-knowledge/components/targeted')
)
const FocusSkillsPracticeHandler = React.lazy(
  () => import('@freckle/student/ts/focus-skills-practice/components/focus-skills-practice-handler')
)
const HighSchoolCoursesHandler = React.lazy(
  () => import('@freckle/student/ts/math/high-school-courses')
)
const CourseBriefingHandler = React.lazy(() => import('@freckle/student/ts/math/course-briefing'))
const DebugMathjaxHandler = React.lazy(
  () => import('@freckle/student/ts/math/common/components/debug/debug-mathjax')
)

const DebugWorkedExampleHandler = React.lazy(
  () => import('@freckle/student/ts/math/internal/debuggers/worked-example')
)

export type MathRoutesWithQueryParams = {
  '/math/menu': undefined
  '/math/number-facts': undefined
  '/math/number-facts/progress': undefined
  '/math/number-facts/assignments/:assignmentIds/session': undefined
  '/math/number-basics': undefined
  '/math/number-basics/session': undefined
  '/math/number-basics/questions/:questionUuid': undefined
  '/assignments/assessment/:assignmentIds/session': undefined
  '/assignments/ibl/:assignmentIds/session': undefined
  '/assignments/standard/:assignmentIds/session': undefined
  '/assignments/targeted/:assignmentIds/session':
    | (SelfAssignedTargetedPracticeTypeT & {alwaysCollectSentiment?: boolean})
    | {alwaysCollectSentiment?: boolean}
    | null

  '/questions/:rlDomainId': undefined
  '/assignments/adaptive/:assignmentIds/session': undefined
  '/debugquestions/:questionId?': undefined
  '/questions/:domainId/:standardId': SelfAssignedTargetedPracticeTypeT
  '/fact-practice': undefined
  '/assignments/fact-practice/:assignmentIds/session': undefined
  '/domains': undefined
  '/math/editor': undefined
  '/briefing/:rlDomainId/adaptive': undefined
  '/math/depth-of-knowledge/:standardId': undefined
  '/math/depth-of-knowledge/targeted/assignments/:assignmentIds': undefined
  '/math/focus-skills-remediation': undefined
  '/math/practice/hint/debug': undefined
  '/math/high-school-courses': undefined
  '/math/course/:skillSetId': undefined
  '/debuggers/mathjax': undefined
  '/debuggers/worked-example/:stemId': {lang?: LangT}
}

export const mathPathData: PathData<MathRoutesWithQueryParams> = {
  '/math/menu': {
    isBlockedByFocusMode: true,
    element: path => (
      <LoggedRoute component={MathMenuHandler} wrapperParams={{isResponsive: true}} path={path} />
    )
  },
  '/math/number-facts': {
    isBlockedByFocusMode: true,
    element: path => <LoggedRoute component={NumberFactsSelfAssignedHandler} path={path} />
  },
  '/math/number-facts/progress': {
    isBlockedByFocusMode: true,
    element: path => <LoggedRoute component={NumberFactsProgressHandler} path={path} />
  },
  '/math/number-facts/assignments/:assignmentIds/session': {
    isBlockedByFocusMode: false,
    element: path => <LoggedRoute component={NumberFactsTeacherAssignedHandler} path={path} />
  },
  '/math/number-basics': {
    isBlockedByFocusMode: true,
    element: path => <LoggedRoute component={NumberBasicsBriefingPageHandler} path={path} />
  },
  '/math/number-basics/session': {
    isBlockedByFocusMode: false,
    element: path => <LoggedRoute component={NumberBasicsSessionHandler} path={path} />
  },
  '/math/number-basics/questions/:questionUuid': {
    isBlockedByFocusMode: false,
    element: path => <LoggedRoute component={NumberBasicsQuestionDebuggerHandler} path={path} />
  },
  '/assignments/assessment/:assignmentIds/session': {
    isBlockedByFocusMode: false,
    element: path => (
      <LoggedRoute
        component={MathAssessmentQuestionSessionHandler}
        wrapperParams={{isResponsive: true}}
        path={path}
      />
    )
  },
  '/assignments/ibl/:assignmentIds/session': {
    isBlockedByFocusMode: false,
    element: path => <LoggedRoute component={MathIblVideoHandler} path={path} />
  },
  '/assignments/standard/:assignmentIds/session': {
    isBlockedByFocusMode: false,
    element: path => (
      <LoggedRoute
        component={MathTargetedHandler}
        wrapperParams={{isResponsive: true}}
        path={path}
      />
    )
  },
  '/assignments/targeted/:assignmentIds/session': {
    isBlockedByFocusMode: false,
    element: path => (
      <LoggedRoute
        component={MathTargetedHandler}
        wrapperParams={{isResponsive: true}}
        path={path}
      />
    )
  },
  '/questions/:rlDomainId': {
    isBlockedByFocusMode: false,
    element: path => (
      <LoggedRoute
        component={MathAdaptiveSessionOrDiagnosticHandler}
        wrapperParams={{isResponsive: true}}
        path={path}
      />
    )
  },
  '/assignments/adaptive/:assignmentIds/session': {
    isBlockedByFocusMode: false,
    element: path => (
      <LoggedRoute
        component={MathAdaptiveTeacherAssignedSessionHandler}
        wrapperParams={{isResponsive: true}}
        path={path}
      />
    )
  },
  '/debugquestions/:questionId?': {
    isBlockedByFocusMode: false,
    element: path => <LoggedRoute component={MathAdaptiveDebugHandler} path={path} />
  },
  '/questions/:domainId/:standardId': {
    isBlockedByFocusMode: false,
    element: path => (
      <LoggedRoute
        component={MathTargetedSelfAssignedHandler}
        wrapperParams={{isResponsive: true}}
        path={path}
      />
    )
  },
  '/fact-practice': {
    isBlockedByFocusMode: false,
    element: path => (
      <LoggedRoute
        component={FactPracticeSelfAssignedSessionHandler}
        wrapperParams={{isResponsive: true}}
        path={path}
      />
    )
  },
  '/assignments/fact-practice/:assignmentIds/session': {
    isBlockedByFocusMode: false,
    element: path => (
      <LoggedRoute
        component={FactPracticeTeacherAssignedSessionHandler}
        wrapperParams={{isResponsive: true}}
        path={path}
      />
    )
  },
  '/domains': {
    isBlockedByFocusMode: true,
    element: path => (
      <LoggedRoute
        component={MathDomainSelectionHandler}
        wrapperParams={{isResponsive: true}}
        path={path}
      />
    )
  },
  '/math/editor': {
    isBlockedByFocusMode: false,
    element: path => <LoggedRoute component={MathQuestionEditor} path={path} />
  },
  '/briefing/:rlDomainId/adaptive': {
    isBlockedByFocusMode: true,
    element: path => (
      <LoggedRoute
        component={DomainBriefingHandler}
        wrapperParams={{isResponsive: true}}
        path={path}
      />
    )
  },
  '/math/depth-of-knowledge/:standardId': {
    isBlockedByFocusMode: false,
    element: path => (
      <LoggedRoute
        component={MathDepthOfKnowledgeSessionHandler}
        wrapperParams={{isResponsive: true}}
        path={path}
      />
    )
  },
  '/math/depth-of-knowledge/targeted/assignments/:assignmentIds': {
    isBlockedByFocusMode: false,
    element: path => (
      <LoggedRoute
        component={MathTargetedDepthOfKnowledgeSessionHandler}
        wrapperParams={{isResponsive: true}}
        path={path}
      />
    )
  },
  '/math/focus-skills-remediation': {
    isBlockedByFocusMode: true,
    element: path => (
      <LoggedRoute
        component={() => <FocusSkillsPracticeHandler contentArea={ContentAreas.math} />}
        wrapperParams={{isResponsive: true}}
        path={path}
      />
    )
  },
  '/math/practice/hint/debug': {
    isBlockedByFocusMode: false,
    element: path => <LoggedRoute component={MathPracticeHintDebugHandler} path={path} />
  },
  '/math/high-school-courses': {
    isBlockedByFocusMode: true,
    element: path => <LoggedRoute component={HighSchoolCoursesHandler} path={path} />
  },
  '/math/course/:skillSetId': {
    isBlockedByFocusMode: true,
    element: path => <LoggedRoute component={CourseBriefingHandler} path={path} />
  },
  '/debuggers/mathjax': {
    isBlockedByFocusMode: false,
    element: path => <LoggedRoute component={DebugMathjaxHandler} path={path} />
  },
  '/debuggers/worked-example/:stemId': {
    isBlockedByFocusMode: false,
    element: path => <LoggedRoute component={DebugWorkedExampleHandler} path={path} />
  }
}
