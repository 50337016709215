import find from 'lodash/find'
import {Languages, type LangT} from '@freckle/student-materials/src/helpers/languages'
import {
  type ParserT,
  Parser,
  string,
  record,
  number,
  boolean,
  stringEnum,
  nullable
} from '@freckle/parser'
import StorageHelper from '@freckle/student-entities/ts/common/helpers/storage-helper'

export type LeaderboardSettingT = 'team' | 'individual' | 'no_leaderboard'

export const LeaderboardSettingField = {
  Team: 'team' as LeaderboardSettingT,
  Individual: 'individual' as LeaderboardSettingT,
  NoLeaderboard: 'no_leaderboard' as LeaderboardSettingT,
  all: function (): Array<LeaderboardSettingT> {
    return [
      LeaderboardSettingField.Team,
      LeaderboardSettingField.Individual,
      LeaderboardSettingField.NoLeaderboard
    ]
  },
  parse: function (s: string): LeaderboardSettingT {
    return (
      find(LeaderboardSettingField.all(), setting => s === setting) ||
      LeaderboardSettingField.NoLeaderboard
    )
  }
}

export type TeacherAttrs = {
  id: number
  email: string
  firstName: string
  lastName: string
  language: LangT
  schoolId: number | undefined | null
  leaderboard: LeaderboardSettingT
  focusMode: boolean
  mathStandardSetId: string // Teacher preference standard set id
  elaStandardSetId: string | undefined | null
}

export const parseTeacherAttrs: ParserT<TeacherAttrs> = Parser.map<TeacherAttrs, TeacherAttrs>(
  record({
    id: number(),
    email: string(),
    firstName: string(),
    lastName: string(),
    language: Languages.parseT(),
    schoolId: nullable(number()),
    leaderboard: stringEnum('LeaderboardSettingT', LeaderboardSettingField.parse),
    focusMode: boolean(),
    mathStandardSetId: string(),
    elaStandardSetId: nullable(string())
  }),
  'checkDemoMode',
  parsedResponse => {
    // Hide Teacher's Name and email when in demoMode
    const demoMode = StorageHelper.getLocalStoragePropertyDefault('demoMode', false)
    const updatedResponse = updateBaseResponseProperties(parsedResponse, demoMode)
    return updatedResponse
  }
)

export const parse = Parser.mkRun<TeacherAttrs>(parseTeacherAttrs)

function updateBaseResponseProperties(
  teacherAttrs: TeacherAttrs,
  isDemoMode: boolean
): TeacherAttrs {
  const updatedResponse = {
    email: isDemoMode ? 'demo@freckle.com' : teacherAttrs.email,
    firstName: isDemoMode ? 'Demo' : teacherAttrs.firstName,
    lastName: isDemoMode ? 'Account' : teacherAttrs.lastName
  }
  return {...teacherAttrs, ...updatedResponse}
}

export function shortName(teacher: Inexact<{firstName: string; lastName: string}>): string {
  return `${teacher.firstName.charAt(0)}. ${teacher.lastName}`
}

export function firstInitialAndLastName(
  teacher: Inexact<{firstName: string; lastName: string}>
): string {
  return teacher.firstName.length > 0 ? shortName(teacher) : teacher.lastName
}
