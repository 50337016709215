import {v4 as uuidv4} from 'uuid'
import {fromMaybe, mthen} from '@freckle/maybe'
import {PATHS} from '@freckle/student-materials/src/helpers/paths'
import {ajaxJsonCall} from '@freckle/ajax'

import {appendQueryStringToRoute} from './routers/query-params'
import {leaveAjaxErrorBreadcrumb} from './exception-handlers/bugsnag-helper'
import CommonApiHelper from './common-api-helper'

// An error class for invalid echo responses. This is intended to add more
// visibility to errors reported to bug snag.
export class EchoError extends Error {
  constructor(message: string, headers: string) {
    super(`${message}\n${headers}`)
    this.name = 'EchoError'
  }
}

export class FetchEchoError extends Error {
  constructor(message: string, response: Response) {
    super(`${message}\n${JSON.stringify(Object.fromEntries(response.headers.entries()))}`)
    this.name = 'EchoError'
  }
}

export function appendParams(
  urlString: string,
  params: {
    [x: string]: string | boolean | number
  }
): string {
  // The UrlSearchParams type isn't supported on iOS < 10
  const searchParams = []
  for (const [key, value] of Object.entries(params)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    searchParams.push(`${key}=${encodeURIComponent((value as any).toString())}`)
  }
  const url = new URL(urlString)
  const sep = url.search.trim() === '' ? '?' : '&'
  url.search += `${sep}${searchParams.join('&')}`
  return url.toString()
}

const requestEcho = uuidv4()

export async function genericFetchWithEcho(url: string, init?: RequestInit): Promise<Response> {
  const urlWithEcho = appendQueryStringToRoute(removeXEcho(url), {'x-echo': requestEcho})
  const response = await fetch(urlWithEcho, init)
  const responseXEcho = response.headers.get('x-echo')
  if (
    responseXEcho !== null &&
    responseXEcho !== undefined &&
    requestEcho !== responseXEcho &&
    response.status !== 503 &&
    response.status !== 504
  ) {
    throw new FetchEchoError(
      `X-Echo expected "${requestEcho}", but found "${responseXEcho}"`,
      response
    )
  }
  return response
}

//If the outgoing route already has an x-echo query param, we need to replace it with the new one.
//Example usecase: pagination link header URLs provided by backend include x-echo's that should be
//replaced with new ones.
export const addXEchoHeader = (url: string): string =>
  appendParams(removeXEcho(url), {'x-echo': requestEcho})

// Optional sides parameter to force the backend to randomly cause 503s
export function ajaxSettingsWithEcho(
  sides: string | undefined | null,
  onEchoError: (a: EchoError) => void,
  onError?: (xhr: JQueryXHR) => void
): JQueryAjaxSettings {
  return {
    dataType: 'json',
    xhrFields: {
      // pass cookies along, useful only when talking to the API, the other
      // $.ajax calls often don't need it and it makes CORS more stringent
      withCredentials: true
    },

    // Add x-echo to API routes and store it in the AJAX settings to
    // check in complete()
    beforeSend: function (_jqXHR: JQueryXHR, settings: JQueryAjaxSettings) {
      if (settings.url !== null && settings.url !== undefined) {
        if (sides !== null && sides !== undefined) {
          settings.url = appendParams(settings.url, {sides, 'expose-headers': true})
        }
        if (settings.method !== 'OPTIONS' && settings.url.indexOf(PATHS.unversionedAPIUrl) !== -1) {
          //Cast settings to any so that we can add an _echo field to it
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const settingsAsAny = settings as any
          settingsAsAny._echo = requestEcho
          settings.url = addXEchoHeader(settings.url)
        }
      }
      //Cast settings to any so that we can stash the time that we queued the request
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const settingsAsAny = settings as any
      settingsAsAny._requestStartTime = new Date()
      if (settings.xhr !== null && settings.xhr !== undefined) {
        const xhr = settings.xhr
        settings.xhr = () => {
          const output = xhr()
          output.onreadystatechange = function () {
            if (this.readyState === XMLHttpRequest.OPENED) {
              // Stash the time that the request was OPENED so that we can log how long the request
              // took on the server
              settingsAsAny._requestOpenedTime = new Date()
            }
          }
          return output
        }
      }
    },

    // If this._echo exists, check it against the X-Echo header
    complete: function (jqXHR: JQueryXHR) {
      const echo = this._echo
      if (echo !== null && echo !== undefined) {
        const respEcho = jqXHR.getResponseHeader('X-Echo')
        if (respEcho && echo !== respEcho) {
          onEchoError(
            new EchoError(
              `X-Echo expected "${echo}", but found "${respEcho}"`,
              jqXHR.getAllResponseHeaders()
            )
          )
        }
      }
    },

    error(jqXHR: JQueryXHR, textStatus: string, errorThrown: string) {
      const nowMs = new Date().getTime()
      const totalDurationMs = nowMs - this._requestStartTime
      const serverDurationMs = fromMaybe(
        () => 0,
        mthen(this._requestOpenedTime, t => nowMs - t)
      )
      leaveAjaxErrorBreadcrumb({
        reqType: this.type,
        reqUrl: this.url,
        reqStatus: {status: jqXHR.status.toString(), textStatus},
        totalDurationMs,
        serverDurationMs,
        errorThrown
      })
      mthen(onError, cb => cb(jqXHR))
    }
  }
}

export function removeXEcho(urlString: string): string {
  const url = new URL(urlString)

  if (url.searchParams.has('x-echo')) {
    url.searchParams.delete('x-echo')
  }

  return url.toString()
}

export function checkStudentCookie(): Promise<void> {
  return ajaxJsonCall({
    url: CommonApiHelper.fancyPaths.v2.students.sessions(),
    method: 'GET'
  })
}
