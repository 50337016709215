/**
 * A student is blocked from practicing if he/she has assignments and the
 * teacher has focus mode enabled
 */

export function getIsBlockedFromFocusMode(
  numIncompleteAssignments: number,
  isInFocusMode: boolean
): boolean {
  return numIncompleteAssignments > 0 && isInFocusMode
}
