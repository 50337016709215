import {type Moment} from 'moment-timezone'
import {ajaxJsonCall} from '@freckle/ajax'
import {type ParserT, record, Parser, number, string, nullable, array, date} from '@freckle/parser'
import CApiHelper from '@freckle/student-entities/ts/common/helpers/common-api-helper'
import {legacyWithCache} from '@freckle/student-entities/ts/common/helpers/common-api-helper'

export type DistrictAttrs = {
  id: number
  name: string
  city: string | undefined | null
  administrativeArea: string | undefined | null
  postalCode: string | undefined | null
  formattedAddressLines: Array<string> | undefined | null
  dioceseId: number | undefined | null
  domain: string | undefined | null
  cleverDistrictId: string | undefined | null
  classLinkDistrictId: string | undefined | null
  createdAt: Moment
  updatedAt: Moment
  ncesLeaId: string | undefined | null
  countryCode: string
}

const parseDistrictAttrs: ParserT<DistrictAttrs> = record({
  id: number(),
  name: string(),
  city: nullable(string()),
  administrativeArea: nullable(string()),
  postalCode: nullable(string()),
  formattedAddressLines: nullable(array(string())),
  dioceseId: nullable(number()),
  domain: nullable(string()),
  cleverDistrictId: nullable(string()),
  classLinkDistrictId: nullable(string()),
  createdAt: date(),
  updatedAt: date(),
  ncesLeaId: nullable(string()),
  countryCode: string()
})

const parseDistricts = Parser.mkRun<Array<DistrictAttrs>>(array(parseDistrictAttrs))

async function _fetchDistricts(): Promise<Array<DistrictAttrs>> {
  const url = CApiHelper.fancyPaths.v2.districts._()
  const response = await ajaxJsonCall({url, method: 'GET'})
  return parseDistricts(response)
}

export const fetchDistricts = legacyWithCache(_fetchDistricts)
