import General from '@freckle/student/ts/common/helpers/general'
import {type StoreState} from '@freckle/student/ts/reducers/types'
import {type PiggyStoreStatusStateT} from '@freckle/student/ts/reducers/types'
import {type PiggyStoreStatusActionT} from '@freckle/student/ts/actions/piggy-store-status'
import {type SelfStudentActionT} from '@freckle/student/ts/actions/self-student'
import {type PiggyStoreStatusT} from '@freckle/student/ts/reducers/types'

const MIN_COINS_GAINED = 50

const defaultPiggyStoreStatusState: PiggyStoreStatusT = {
  coinsGainedSinceLastVisit: 0,
  hasAccessToStore: false,
  shouldShowAccessToStore: false
}

export function piggyStoreStatusReducer(
  state: PiggyStoreStatusStateT = defaultPiggyStoreStatusState,
  action: PiggyStoreStatusActionT | SelfStudentActionT
): PiggyStoreStatusStateT {
  switch (action.type) {
    case 'PIGGY_STORE_STATUS_ADD_COINS': {
      const coinsGained = state.coinsGainedSinceLastVisit + action.coins
      const hasStoreAccess = coinsGained >= MIN_COINS_GAINED
      return {
        ...state,
        coinsGainedSinceLastVisit: coinsGained,
        hasAccessToStore: hasStoreAccess
      }
    }
    case 'PIGGY_STORE_STATUS_RESET_COINS': {
      return {
        ...state,
        coinsGainedSinceLastVisit: 0,
        hasAccessToStore: false
      }
    }
    case 'SELF_STUDENT_GET_RESPONSE': {
      const showAccess = action.selfStudent.grade < 9
      return {
        ...state,
        shouldShowAccessToStore: showAccess
      }
    }
    default:
      return state
  }
}

export function getAccessToPiggyStore(storeState: StoreState): boolean {
  return storeState.piggyStoreStatus.hasAccessToStore
}

export function shouldShowAccessToStore(storeState: StoreState): boolean {
  return storeState.piggyStoreStatus.shouldShowAccessToStore
}

export function getProgressToStore(storeState: StoreState): number {
  return General.calcPercentage(
    storeState.piggyStoreStatus.coinsGainedSinceLastVisit,
    MIN_COINS_GAINED
  )
}
