import * as React from 'react'
import {Routes, Route, Navigate, useLocation} from 'react-router-dom'

import {toBrowserRoute} from '../../helpers/router-helpers'
import {pathData, type RoutesWithQueryParams} from '../routes'

/**
 *
 * @deprecated
 * This component solely exists as a backwards compatibility layer between the old hash-based routing and the new path-based routing.
 * The goal will be to phase out hash-based routing entirely. So this component will need to be removed eventually.
 * The main thing keeping this component around is the fact that the classroom app still generates assignment links to the student app with hashes. This will need to change: TODO https://app.asana.com/0/1201335092255974/1207669096250807/f
 *
 * Once that has been resolved, we will need to wait at least one school year for this change in routing to percolate to all users
 * Note that this notice also applies to other pieces of logic that, in one way or another, bridge the gap between hash-based routing and path-based routing. When it comes time to remove this component, we will need to remove all those other pieces of logic as well, if any.
 * Task Link: https://app.asana.com/0/149473556304568/1207693321623476/f
 */
function BaseRedirect(): React.ReactElement {
  const location = useLocation()
  if (location.hash.length > 0) {
    return <Navigate to={toBrowserRoute(location.hash)} replace />
  }

  return <Navigate to="/login" replace />
}

export function RouterV2(): React.ReactElement {
  return (
    <>
      <Routes>
        <Route path="/" element={<BaseRedirect />} />
        {Object.entries(pathData).map(([path, {element}]) => (
          <Route key={path} path={path} element={element(path as keyof RoutesWithQueryParams)} />
        ))}
      </Routes>
    </>
  )
}
