import * as React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useAppDispatch} from '@freckle/student/ts/store'
import {useBaseModel} from '@freckle/student/ts/hooks/use-base-model'
import {type ResourceStatusT} from '@freckle/resource-status'
import {
  piggyStoreStatusAddCoinsAction,
  piggyStoreStatusResetCoinsAction
} from '@freckle/student/ts/actions/piggy-store-status'
import {
  getAccessToPiggyStore,
  shouldShowAccessToStore as getStoreAccessByGrade,
  getProgressToStore
} from '@freckle/student/ts/reducers/piggy-store-status/index'
import {useSafeEffectExtraDeps} from '@freckle/react-hooks'
import {exhaustive} from '@freckle/exhaustive'
import {
  type SelfStudentsStateT,
  type ItemOwnershipsStateT,
  type CurrentCourseStateT,
  type CurrentTeacherStateT,
  type ItemsStateT,
  type StoreState
} from '@freckle/student/ts/reducers/types'
import {loadSelfStudent} from '@freckle/student/ts/actions/self-student'
import {
  type CoinsUpdateT,
  selfStudentUpdateCoinsAction
} from '@freckle/student/ts/actions/self-student'
import {loadCurrentCourse} from '@freckle/student/ts/actions/current-course'
import {loadCurrentTeacher} from '@freckle/student/ts/actions/current-teacher'
import {loadItems} from '@freckle/student/ts/actions/items'
import {loadItemOwnerships} from '@freckle/student/ts/actions/item-ownerships'
import {type Dispatch} from 'redux'
import type {CourseAttrs} from '@freckle/student-entities/ts/common/models/course'

// N.B.: Use app/ts/users/models/self-student/use-self-student.ts
export function useSelfStudentDepricated(): {
  selfStudent: SelfStudentsStateT
  updateCoins: (a: CoinsUpdateT) => void
  reload: () => void
} {
  const selfStudent = useBaseModel(state => state.selfStudent, loadSelfStudent)
  const dispatch = useAppDispatch()
  const updateCoins = React.useCallback(
    (coinsStatus: CoinsUpdateT) => dispatch(selfStudentUpdateCoinsAction(coinsStatus)),
    [dispatch]
  )

  const reload = React.useCallback(() => dispatch(loadSelfStudent()), [dispatch])

  return {selfStudent, updateCoins, reload}
}

export function usePiggyStoreStatus(): {
  addCoinsToPiggyStore: (a: number) => void
  resetCoinsInPiggyStore: () => void
  hasAccessToPiggyStore: boolean
  shouldShowAccessToStore: boolean
  progressToStore: number
} {
  const dispatch = useDispatch()
  const hasAccessToPiggyStore = useSelector((state: StoreState) => getAccessToPiggyStore(state))
  const shouldShowAccessToStore = useSelector((state: StoreState) => getStoreAccessByGrade(state))
  const progressToStore = useSelector((state: StoreState) => getProgressToStore(state))
  const addCoinsToPiggyStore = React.useCallback(
    (coins: number) => dispatch(piggyStoreStatusAddCoinsAction(coins)),
    [dispatch]
  )
  const resetCoinsInPiggyStore = React.useCallback(
    () => dispatch(piggyStoreStatusResetCoinsAction()),
    [dispatch]
  )
  return {
    addCoinsToPiggyStore,
    resetCoinsInPiggyStore,
    hasAccessToPiggyStore,
    shouldShowAccessToStore,
    progressToStore
  }
}

export function useCurrentCourse(): {
  currentCourse: CurrentCourseStateT
  reload: () => void
} {
  const currentCourse = useBaseModel(state => state.currentCourse, loadCurrentCourse)

  const dispatch = useAppDispatch()
  const reload = React.useCallback(() => dispatch(loadCurrentCourse()), [dispatch])

  return {currentCourse, reload}
}

export function useCurrentTeacher(): {
  currentTeacher: CurrentTeacherStateT
} {
  const dispatch = useDispatch()
  const currentCourse = useBaseModel(state => state.currentCourse, loadCurrentCourse)

  // The below is essentially `useBaseModel` that supports a loader() argument
  const currentTeacher: CurrentTeacherStateT = useSelector(
    (state: StoreState) => state.currentTeacher
  )

  useSafeEffectExtraDeps<{
    currentCourse: ResourceStatusT<CourseAttrs>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: Dispatch<any>
  }>(
    ({currentCourse, dispatch}) => {
      if (currentTeacher.status === 'idle') {
        switch (currentCourse.status) {
          case 'idle':
          case 'loading':
          case 'reloading':
          case 'error':
          case 'updating':
          case 'updating-error':
            return
          case 'complete':
            dispatch(loadCurrentTeacher(currentCourse.data.id))
            return
          default:
            return exhaustive(currentCourse)
        }
      }
    },
    [currentTeacher.status],
    {
      dispatch: {value: dispatch, comparator: () => true},
      currentCourse: {
        value: currentCourse,
        comparator: ({status: status1}, {status: status2}) => status1 === status2
      }
    }
  )

  return {currentTeacher}
}

export function useItems(): {
  items: ItemsStateT
  reload: () => void
} {
  const items = useBaseModel(state => state.items, loadItems)

  const dispatch = useAppDispatch()
  const reload = React.useCallback(() => dispatch(loadItems()), [dispatch])

  return {items, reload}
}

export function useItemOwnerships(): {
  itemOwnerships: ItemOwnershipsStateT
  reload: () => void
} {
  const itemOwnerships = useBaseModel(state => state.itemOwnerships, loadItemOwnerships)

  const dispatch = useAppDispatch()
  const reload = React.useCallback(() => dispatch(loadItemOwnerships()), [dispatch])

  return {itemOwnerships, reload}
}
