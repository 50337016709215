import find from 'lodash/find'
import CApiHelper from '@freckle/student-entities/ts/common/helpers/common-api-helper'
import {ajaxJsonCall} from '@freckle/ajax'
import {
  type ItemT,
  isAnAvatar,
  getItemFromItemId
} from '@freckle/student-entities/ts/common/models/item'
import {
  type ParserT,
  Parser,
  array,
  boolean,
  number,
  record,
  nullable,
  string
} from '@freckle/parser'
import {apiFetch} from '@freckle/student-entities/ts/common/helpers/api-fetch'

export type ItemOwnershipT = {
  id: number
  studentId: number
  itemId: number
  equipped: boolean
  itemVariation: string | undefined | null
}

export const parse: ParserT<ItemOwnershipT> = record({
  id: number(),
  studentId: number(),
  itemId: number(),
  equipped: boolean(),
  itemVariation: nullable(string())
})

export const parseItemOwnership = Parser.mkRun<ItemOwnershipT>(parse)

export const parseItemOwnerships = Parser.mkRun<ItemOwnershipT[]>(array(parse))

/**
 * Helpers
 */

export function getOwnedItem(
  itemOwnerships: Array<ItemOwnershipT>,
  items: Array<ItemT>
): Array<{
  item: ItemT
  itemOwnership: ItemOwnershipT
}> {
  return itemOwnerships.map(itemOwnership => {
    const item = getItemFromItemId(items, itemOwnership.itemId)

    return {
      item,
      itemOwnership
    }
  })
}

export function getAvatarVariation(
  itemOwnerships: Array<ItemOwnershipT>,
  items: Array<ItemT>
): string | undefined | null {
  const avatarOwnership = find(itemOwnerships, itemOwnership => {
    const item = getItemFromItemId(items, itemOwnership.itemId)
    return itemOwnership.equipped && isAnAvatar(item)
  })

  return avatarOwnership !== null && avatarOwnership !== undefined
    ? avatarOwnership.itemVariation
    : null
}

const DEFAULT_AVATAR_NAME: string = 'piggy'

export function getAvatarName(itemOwnerships: Array<ItemOwnershipT>, items: Array<ItemT>): string {
  const avatarOwnership = getAvatarOwnership(itemOwnerships, items)

  return avatarOwnership
    ? getItemFromItemId(items, avatarOwnership.itemId).name
    : DEFAULT_AVATAR_NAME
}

export function getAvatarOwnership(
  itemOwnerships: Array<ItemOwnershipT>,
  items: Array<ItemT>
): ItemOwnershipT | undefined | null {
  return find(itemOwnerships, itemOwnership => {
    const item = getItemFromItemId(items, itemOwnership.itemId)
    return itemOwnership.equipped && isAnAvatar(item)
  })
}

export function getDefaultAvatarName(): string {
  return DEFAULT_AVATAR_NAME
}

/**
 * API Helpers
 */

export function saveNewItemOwnership(
  itemId: number,
  itemVariationName?: string | null
): Promise<ItemOwnershipT> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.items.item.item_ownership(itemId),
    method: 'POST',
    data: JSON.stringify({itemVariation: itemVariationName})
  }).then(parseItemOwnership)
}

export async function fetchItemOwnerships(): Promise<Array<ItemOwnershipT>> {
  const res = await apiFetch({
    url: CApiHelper.fancyPaths.v2.item_ownerships._(),
    method: 'GET'
  })
  return parseItemOwnerships(res)
}
