import * as React from 'react'
import {useSafeTranslation} from '@freckle/student-materials/src/helpers/translate'
import CApiHelper from '@freckle/student-entities/ts/common/helpers/common-api-helper'
import Link from '@freckle/student-materials/src/components/link'
import {H3} from '@freckle/student-materials/src/components/typography'

import {wrapper, teacherLogin, h3} from './teacher-login.module.scss'
import JtHat from './jt-hat.svg'

export function TeacherLogin(): React.ReactElement {
  const {t} = useSafeTranslation()

  const relogin = CApiHelper.fancyPaths.v2.teacher_relogin_redirect()
  const imgSource = JtHat

  return (
    <div className={wrapper}>
      <div className={teacherLogin}>
        <img src={imgSource} />
        <H3 addClass={h3}>{t('Finished exploring the student experience?')}</H3>
        <Link
          style="btn-educator-primary"
          size="lg"
          href={relogin}
          dataTest="redirect-to-classroom"
        >
          {t('Go to the Teacher App')}
        </Link>
      </div>
    </div>
  )
}
