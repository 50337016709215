import reduce from 'lodash/reduce'
import map from 'lodash/map'
import compact from 'lodash/compact'

export function addMaybeClassName(className: string, addClass?: string | null): string {
  return addClass !== null && addClass !== undefined ? `${className} ${addClass}` : className
}

export function addMaybeClassNames(
  className: string,
  addClasses: Array<string | undefined | null>
): string {
  return reduce(addClasses, addMaybeClassName, className)
}

export function addIfClassNames(
  tuple: Array<
    [when: boolean | undefined | null, then: string | undefined | null, otherwise?: string | null]
  >
): string {
  const maybeClassNames = map(tuple, ([when, then, otherwise]) =>
    when === true ? then : otherwise
  )
  return compact(maybeClassNames).join(' ')
}
