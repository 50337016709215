import {exhaustive} from '@freckle/exhaustive'

import Globals from '../../models/globals'
import {type AnalyticsEvent} from './types'
import {GoogleAnalyticsInternal, type CustomDimensionKey} from './google-analytics'

function shouldTrack(): boolean {
  return !localStorage || !localStorage.getItem('demoMode')
}

export const setup = (userId: number): Promise<void> => {
  if (Globals.hasAnalytics === true) {
    return Promise.resolve(undefined)
  }
  Globals.hasAnalytics = true
  return GoogleAnalyticsInternal.setup(userId)
}

export const setProperties = (
  userId: number,
  properties: Partial<{[k in CustomDimensionKey]: string | number}>
): Promise<void> => {
  if (shouldTrack()) {
    return GoogleAnalyticsInternal.setProperties(userId, properties)
  }
  return Promise.resolve(undefined)
}

export const trackEvent = function (event: AnalyticsEvent): Promise<void> {
  if (shouldTrack()) {
    return GoogleAnalyticsInternal.trackEvent(event)
  }
  return Promise.resolve(undefined)
}

export function trackAcademicVocabularyEvent(
  subject: 'math' | 'ela' | 'social-studies' | 'science'
) {
  switch (subject) {
    case 'math':
      trackEvent({tag: 'Clicked on a Academic Vocabulary term in Math'})
      break
    case 'ela':
      trackEvent({tag: 'Clicked on a Academic Vocabulary term in ELA'})
      break
    case 'science':
      trackEvent({tag: 'Clicked on a Math Academic Vocabulary term in Science'})
      break
    case 'social-studies':
      trackEvent({tag: 'Clicked on a Math Academic Vocabulary term in Social Studies'})
      break
    default:
      exhaustive(subject)
  }
}

export async function trackStudentRouteChanges(pathname: string): Promise<void> {
  try {
    return await GoogleAnalyticsInternal.trackRouteChange(pathname)
  } catch (_e) {}
}
