import * as React from 'react'
import {Navigate} from 'react-router-dom'
import {HandledError} from '@freckle/student-entities/ts/common/exceptions/handled-error'
import {NotFoundRedirect} from '@freckle/student-entities/ts/common/exceptions/not-found-redirect'
import General from '@freckle/student/ts/common/helpers/general'
import {Routes} from '@freckle/student/ts/common/routers/routes'

import {logErrorAsUnhandled, logError} from './bugsnag-client'

type Props = {
  error: Error
}

export function LogAndRedirectUnauthed({error}: Props): React.ReactElement {
  if (error instanceof HandledError) {
    logError(error)
  } else {
    logErrorAsUnhandled(error)
  }

  return <RedirectError error={error} fallback={Routes.errorPage()} />
}

type RedirectProps = {
  error: Error
  fallback: string
}

function RedirectError({error, fallback}: RedirectProps): React.ReactElement {
  if (error instanceof NotFoundRedirect) {
    General.displayNotification('error', error.message)
    return <Navigate to={error.redirectUrl} replace />
  } else {
    return <Navigate to={fallback} replace />
  }
}
