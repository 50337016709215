import Config from './config'

export type EnvironmentT = 'production' | 'staging' | 'localhost' | 'test' | 'demo'

export function getEnvironment(hostname?: string): EnvironmentT | undefined | null {
  const environmentUsingHostName =
    hostname !== null && hostname !== undefined ? getEnvironmentUsingHostname(hostname) : null

  if (environmentUsingHostName !== null && environmentUsingHostName !== undefined) {
    return environmentUsingHostName
  }

  //Currently only supporting NODE_ENV's test value.  All other values
  //are coming from whatever we derive from the hostname
  switch (Config.NODE_ENV) {
    case 'test':
      return 'test'

    default:
      return null
  }
}

function getEnvironmentUsingHostname(hostname: string): EnvironmentT | undefined | null {
  if (/.*-staging\.freckle.com/.test(hostname)) {
    return 'staging'
  } else if (/.*-demo\.freckle.com/.test(hostname)) {
    return 'demo'
  } else if (/.*\.freckle.com/.test(hostname)) {
    return 'production'
  } else if (/.*\.freckletest.com/.test(hostname)) {
    return 'test'
  } else if (/.*\.localhost.com/.test(hostname)) {
    return 'localhost'
  } else {
    return null
  }
}
