import {type CurrentCourseStateT} from '@freckle/student/ts/reducers/types'
import {type CurrentCourseActionT} from '@freckle/student/ts/actions/current-course'

const defaultCurrentCourseState: CurrentCourseStateT = {
  status: 'idle'
}

export function currentCourseReducer(
  state: CurrentCourseStateT = defaultCurrentCourseState,
  action: CurrentCourseActionT
): CurrentCourseStateT {
  switch (action.type) {
    case 'CURRENT_COURSE_GET_REQUEST':
      return {status: 'loading'}
    case 'CURRENT_COURSE_GET_REFETCH_REQUEST':
      return {status: 'reloading', data: action.currentCourse}
    case 'CURRENT_COURSE_GET_RESPONSE': {
      const {currentCourse} = action
      return {status: 'complete', data: currentCourse, hasUpdated: false}
    }
    case 'CURRENT_COURSE_GET_ERROR':
      return {status: 'error', error: action.error}
    default:
      return state
  }
}
