import * as React from 'react'
import {asHTMLAttributeValue, mmap} from '@freckle/maybe'
import {exhaustive} from '@freckle/exhaustive'
import {
  addMaybeClassNames,
  addMaybeClassName
} from '@freckle/student-materials/src/helpers/classnames'

import {type BoardSizeT} from './../../helpers/size'
import {
  container,
  fullHeightContainer,
  shadowStyle as shadowStyleClass,
  hideSlice as hideSliceClass,
  outerContainer as outerContainerClass,
  sliceStyle,
  dangerSliceStyle,
  lg,
  md,
  sm,
  xs,
  noPadding,
  rounded,
  square,
  roundedSliceStyle,
  squareSliceStyle
} from './container.module.scss'

type CornerStyleT = 'rounded' | 'square'
type ShadowStyleT = 'none' | 'regular'
type ContainerStyleT = 'primary' | 'danger'

type Props = {
  children: React.ReactNode
  addClass?: string | null
  size?: BoardSizeT
  dataTest?: string | null
  fullHeight?: boolean
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  corners?: CornerStyleT
  shadowStyle?: ShadowStyleT
  containerStyle?: ContainerStyleT
  hideSlice?: boolean
}

function Container(props: Props): React.ReactElement<Props> {
  const {
    children,
    addClass,
    size = 'lg',
    dataTest,
    onClick,
    onMouseEnter,
    onMouseLeave,
    corners = 'rounded',
    fullHeight = false,
    shadowStyle = 'none',
    containerStyle = 'primary',
    hideSlice = false
  } = props

  const addDataTest = {'data-test': asHTMLAttributeValue(dataTest)}
  const addOnClick =
    onClick !== null && onClick !== undefined
      ? {onClick, role: 'button'}
      : {onClick: undefined, role: undefined}
  const addOnMouseEnter = {
    onMouseEnter: asHTMLAttributeValue(onMouseEnter) as React.MouseEventHandler<HTMLDivElement>
  }
  const addOnMouseLeave = {
    onMouseLeave: asHTMLAttributeValue(onMouseLeave) as React.MouseEventHandler<HTMLDivElement>
  }

  const contentContainerClassName = addMaybeClassNames(container, [
    mmap(getCornerStyle, corners),
    getSizeStyle(size),
    addClass,
    fullHeight ? fullHeightContainer : null,
    shadowStyle !== 'none' ? shadowStyleClass : null,
    hideSlice ? hideSliceClass : null
  ])

  const outerContainer = addMaybeClassName(
    outerContainerClass,
    fullHeight ? fullHeightContainer : ''
  )

  const optionalProps = {
    ...addDataTest,
    ...addOnClick,
    ...addOnMouseEnter,
    ...addOnMouseLeave
  }

  const contentContainer = (
    <div className={contentContainerClassName} {...optionalProps}>
      {children}
    </div>
  )

  return hideSlice ? (
    contentContainer
  ) : (
    <div className={outerContainer} role="button" tabIndex={0}>
      <Slice corners={corners} containerStyle={containerStyle} />
      {contentContainer}
    </div>
  )
}

export {Container}

type SliceProps = {
  corners: CornerStyleT
  containerStyle: ContainerStyleT
}

function getSliceStyle(containerStyle: ContainerStyleT): string {
  switch (containerStyle) {
    case 'primary':
      return sliceStyle
    case 'danger':
      return dangerSliceStyle
    default:
      return exhaustive(containerStyle)
  }
}

function Slice(props: SliceProps): React.ReactElement<SliceProps> {
  const {corners, containerStyle} = props

  const cornerClassName = () => {
    switch (corners) {
      case 'rounded':
        return roundedSliceStyle
      case 'square':
        return squareSliceStyle
      default:
        return exhaustive(corners)
    }
  }
  return <div className={`${getSliceStyle(containerStyle)} ${cornerClassName()}`} />
}

function getSizeStyle(size: BoardSizeT): string {
  switch (size) {
    case 'lg':
      return lg

    case 'md':
      return md

    case 'sm':
      return sm

    case 'xs':
      return xs

    case 'empty':
      return noPadding

    default:
      return exhaustive(size, 'BoardSizeT')
  }
}

function getCornerStyle(corners: CornerStyleT): string {
  switch (corners) {
    case 'rounded':
      return rounded
    case 'square':
      return square
    default:
      return exhaustive(corners)
  }
}
