import includes from 'lodash/includes'
import filter from 'lodash/filter'
import find from 'lodash/find'
import {fromJust} from '@freckle/maybe'
import {PATHS} from '@freckle/student-materials/src/helpers/paths'
import {
  type ParserT,
  Parser,
  array,
  boolean,
  number,
  record,
  string,
  firstOf,
  literal
} from '@freckle/parser'
import {
  ImgReqSizingSearchParams,
  genImgReqUrlWithSizingSearchParams
} from '@freckle/student-entities/ts/common/types/imjax'
import {apiFetch} from '@freckle/student-entities/ts/common/helpers/api-fetch'
import CApiHelper from '@freckle/student-entities/ts/common/helpers/common-api-helper'

const MATURE_IMAGES_MIN_GRADE = 6

export type PoseT = 'normal'
export type StoreCategoryT = 'avatar' | 'clothes' | 'hats_and_wigs' | 'accessories' | 'scenes'
export type ItemT = {
  id: number
  name: string
  locizeKey: string
  price: number
  category: string
  storeCategory: StoreCategoryT
  isHidden: boolean
  minGrade: number
  maxGrade: number
}

export function isFree(item: ItemT): boolean {
  return item.price === 0
}

export function isAnAccessory(item: ItemT): boolean {
  return item.category !== 'avatar' && item.category !== 'background'
}

export function isABackground(item: ItemT): boolean {
  return item.category === 'background'
}

export function isAnAvatar(item: ItemT): boolean {
  return item.category === 'avatar'
}

export const parseStoreCategory: ParserT<StoreCategoryT> = firstOf(
  literal('avatar'),
  literal('clothes'),
  literal('hats_and_wigs'),
  literal('accessories'),
  literal('scenes')
)

const parse: ParserT<ItemT> = record({
  id: number(),
  name: string(),
  locizeKey: string(),
  price: number(),
  category: string(),
  storeCategory: firstOf(parseStoreCategory),
  isHidden: boolean(),
  minGrade: number(),
  maxGrade: number()
})

export const parseItem = Parser.mkRun<ItemT>(parse)

export const parseItems = Parser.mkRun<Array<ItemT>>(array(parse))

type ItemNameParamsT = {
  itemCategory: string
  avatarName: string
  itemName: string
  avatarPose: PoseT
  itemVariationName: string | undefined | null
}

function getItemImageName({
  itemCategory,
  avatarName,
  itemName,
  avatarPose,
  itemVariationName
}: ItemNameParamsT): string {
  switch (itemCategory) {
    case 'avatar':
      return itemVariationName !== null && itemVariationName !== undefined
        ? `${itemName}-${avatarPose}-${itemVariationName}`
        : `${itemName}-${avatarPose}`

    case 'background':
      return itemName

    default:
      return itemVariationName !== null && itemVariationName !== undefined
        ? `${avatarName}-${avatarPose}-${itemName}-${itemVariationName}`
        : `${avatarName}-${avatarPose}-${itemName}`
  }
}

type UrlConfigT = {
  itemName: string
  itemCategory: string
  avatarName: string
  avatarPose: PoseT
  studentGrade: number
  trimmedImage: boolean
  itemVariationName: string | undefined | null
  sizeParams?: ImgReqSizingSearchParams
}

export function getItemUrl(itemConfig: UrlConfigT): string {
  return getItemUrlAndName(itemConfig).itemUrl
}

//We don't support variations for grades below MATURE_IMAGES_MIN_GRADE
export function canViewItemVariations(studentGrade: number): boolean {
  return studentGrade >= MATURE_IMAGES_MIN_GRADE
}

export function getItemUrlAndName(itemConfig: UrlConfigT): {
  itemUrl: string
  itemImageName: string
} {
  const {trimmedImage, studentGrade, sizeParams, ...itemImageInfo} = itemConfig

  const updatedImageInfo = canViewItemVariations(studentGrade)
    ? itemImageInfo
    : {...itemImageInfo, itemVariationName: null}

  const itemSlug = getItemPathSlug(trimmedImage, studentGrade)
  const baseUrl = `${PATHS.imageAssetsUrl}/${itemSlug}/`
  const itemImageName = getItemImageName(updatedImageInfo)

  return {
    itemUrl: genImgReqUrlWithSizingSearchParams(`${baseUrl}${itemImageName}.png`, sizeParams),
    itemImageName
  }
}

export function getItemPathSlug(trimmed: boolean, grade: number): string {
  const itemSlug = trimmed ? 'items-trimmed' : 'items'
  const itemSlugAppend = canViewItemVariations(grade) ? '-mature' : ''

  return `${itemSlug}${itemSlugAppend}`
}

export function getItemFromItemId(itemCollection: Array<ItemT>, itemId: number): ItemT {
  return fromJust(
    find(itemCollection, item => item.id === itemId),
    `Could not find item with id ${itemId}`
  )
}

export function filterItemsByStudentGrade(
  items: Array<ItemT>,
  grade: number,
  itemOwnershipIds: Array<number>
): Array<ItemT> {
  return filter(
    items,
    x => includes(itemOwnershipIds, x.id) || (x.minGrade <= grade && grade <= x.maxGrade)
  )
}

export async function fetchItems(): Promise<Array<ItemT>> {
  const res = await apiFetch({
    url: CApiHelper.fancyPaths.v2.items._(),
    method: 'GET'
  })
  return parseItems(res)
}
