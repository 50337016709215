import {createStore, applyMiddleware, compose} from 'redux'
import type {ThunkDispatch} from 'redux-thunk'
import thunkMiddleware from 'redux-thunk'
import logger from 'redux-logger'
import {useDispatch} from 'react-redux'
import {type StoreState} from '@freckle/student/ts/reducers/types'
import rootReducer from '@freckle/student/ts/reducers'
import {type AllActionT} from '@freckle/student/ts/reducers'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const middleware = [thunkMiddleware, logger]

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)))

export const useAppDispatch: () => ThunkDispatch<StoreState, unknown, AllActionT> = useDispatch
