import * as React from 'react'
import Image from '@freckle/student-materials/src/components/img'
import {H3} from '@freckle/student-materials/src/components/typography'

import {heading} from './narrow-screen-alert.module.scss'

type Props = {
  headingText: string
  bodyText: string
  imageSrc?: string | null
}

export function ScreenAlert(props: Props): React.ReactElement {
  const {imageSrc, headingText, bodyText} = props

  return (
    <>
      {imageSrc !== null && imageSrc !== undefined ? <Image src={imageSrc} /> : null}
      <H3 addClass={heading}>{headingText}.</H3>
      <p>{bodyText}.</p>
    </>
  )
}
