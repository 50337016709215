import * as React from 'react'
import {NarrowScreenAlert} from '@freckle/student/ts/common/components/common/narrow-screen-alert/narrow-screen-alert'

import {mainWrapper} from './main-wrapper.module.scss'

type Props = {
  isResponsive?: boolean | null
  children?: React.ReactNode
}

export function MainWrapper(props: Props): React.ReactElement {
  const {isResponsive, children} = props
  return (
    <main className={mainWrapper}>
      {isResponsive === true ? null : <NarrowScreenAlert />}
      {children}
    </main>
  )
}
