import filter from 'lodash/filter'
import intersection from 'lodash/intersection'
import range from 'lodash/range'
import {
  type ParserT,
  Parser,
  number,
  string,
  record,
  boolean,
  nullableDefault,
  nullable
} from '@freckle/parser'
import {type RlDomainIdT} from '@freckle/student-entities/ts/common/types/rl-domain-id'
import * as RlDomainId from '@freckle/student-entities/ts/common/types/rl-domain-id'

export type RlDomainT = {
  name: string
  id: RlDomainIdT
  lowestGrade: number
  highestGrade: number
  k5ThumbnailImage: string
  k5PathwayImage: string
  msThumbnailImage: string
  msPathwayImage: string
  practiceBackgroundTop: string | null | undefined
  practiceBackgroundBottom: string | null | undefined
  hasElaGrammarQuestions: boolean
  hasElaPathwayContent: boolean
  isStudentVisible: boolean
}

export const parseDomainAttrs: ParserT<RlDomainT> = record({
  id: RlDomainId.parse,
  name: string(),
  lowestGrade: number(),
  highestGrade: number(),
  k5ThumbnailImage: string(),
  k5PathwayImage: string(),
  msThumbnailImage: string(),
  msPathwayImage: string(),
  practiceBackgroundTop: nullable(string()),
  practiceBackgroundBottom: nullable(string()),
  hasElaGrammarQuestions: nullableDefault(boolean(), false),
  hasElaPathwayContent: nullableDefault(boolean(), false),
  isStudentVisible: boolean()
})

export const parseRlDomain = Parser.mkRun<RlDomainT>(parseDomainAttrs)

export function filterDomainsByGrade(domains: Array<RlDomainT>, grade: number): Array<RlDomainT> {
  return filter(domains, d => domainHasGrade(d, grade))
}

export function domainRangeOverlaps(
  domain: RlDomainT,
  testRange: {
    min: number
    max: number
  }
): boolean {
  const domainRange = range(domain.lowestGrade, domain.highestGrade + 1)
  const tRange = range(testRange.min, testRange.max + 1)

  return intersection(domainRange, tRange).length > 0
}

export function rejectDomainsByRange(
  domains: Array<RlDomainT>,
  testRange: {
    min: number
    max: number
  }
): Array<RlDomainT> {
  return filter(domains, d => !domainRangeOverlaps(d, testRange))
}

export function domainHasGrade(domain: RlDomainT, grade: number): boolean {
  return grade >= domain.lowestGrade && grade <= domain.highestGrade
}

export function thumbnailImageUrl(domain: RlDomainT, grade: number): string {
  if (grade <= 5) {
    return domain.k5ThumbnailImage
  } else {
    return domain.msThumbnailImage
  }
}

export function iconImageUrl(domain: RlDomainT, grade: number): string {
  const url = thumbnailImageUrl(domain, grade)
  const params = '?width=152'
  return `${url}${params}`
}
