import * as React from 'react'

type Props = {
  src: string
  alt?: string
  title?: string
  dataTest?: string | null
  /** CSS Class to add to the button */
  className?: string | undefined
  height?: string | number | undefined
  width?: string | number | undefined
  onLoad?: (() => void) | undefined
  onError?: (() => void) | undefined
  draggable?: boolean | undefined
  tabIndex?: number
  style?: React.CSSProperties
}

const Image = React.forwardRef<HTMLImageElement, Props>(
  (props: Props, ref): React.ReactElement<Props> => {
    const {src, alt = '', title = '', dataTest, tabIndex, ...rest} = props
    const dataTestObj = dataTest !== null && dataTest !== undefined ? {'data-test': dataTest} : {}

    const additionalProps = {
      ...dataTestObj,
      ...(tabIndex !== null && tabIndex !== undefined ? {tabIndex} : tabIndex)
    }

    return (
      <img src={src} alt={alt} title={title} {...additionalProps} {...rest} ref={ref} key={src} />
    )
  }
)

Image.displayName = 'Image'

export default Image
