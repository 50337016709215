import * as React from 'react'
import {type AudioContextT} from '@freckle/student/ts/common/logic/web-audio'

const Context = window.AudioContext || window.webkitAudioContext

const supportsWebAudioApi = (): boolean => Context !== null && Context !== undefined

const useWebAudioContext = () => {
  const hasSetupRef = React.useRef(false)
  const [audioContext, setAudioContext] = React.useState<AudioContextT | null>(null)

  React.useEffect(() => {
    const unlockAudioContext = () => {
      setTimeout(function () {
        // We captured the user interaction and can now remove the user interaction
        // event handlers.
        window.document.body.removeEventListener('mousedown', unlockAudioContext, false)
        window.document.body.removeEventListener('touchend', unlockAudioContext, false)
      }, 1)

      if (hasSetupRef.current === true) {
        // setup already done
        return
      }

      if (supportsWebAudioApi() === false) {
        // web audio API not supported
        return
      }

      // The AudioContext can only be resumed (or created) AFTER a user interaction (click, touch, etc).
      // Once the AudioContext is unlocked, we can play any number of audio files without
      // needing any further user interaction.
      const context = new Context()

      // To unlock the AudioContext, we can create an empty sound buffer and play it
      // This way the user does not hear this sound and the AudioContext is unlocked
      const buffer = context.createBuffer(1, 1, 22050)
      const source = context.createBufferSource()
      source.buffer = buffer
      source.connect(context.destination)
      source.start(context.currentTime)

      // Setup done
      hasSetupRef.current = true
      setAudioContext(context)
    }

    window.document.body.addEventListener('mousedown', unlockAudioContext, false)
    window.document.body.addEventListener('touchend', unlockAudioContext, false)

    return () => {
      try {
        window.document.body.removeEventListener('mousedown', unlockAudioContext, false)
        window.document.body.removeEventListener('touchend', unlockAudioContext, false)
      } catch (e) {}
    }
  }, [])

  return audioContext
}

type Props = {
  children: React.ReactNode
}

export const AudioContext: React.Context<AudioContextT | null | undefined> = React.createContext<
  AudioContextT | null | undefined
>(null)

AudioContext.displayName = 'AudioContext'

export const AudioContextProvider = (props: Props): React.ReactElement => {
  const audioContext = useWebAudioContext()

  return <AudioContext.Provider value={audioContext}>{props.children}</AudioContext.Provider>
}
