import {useState, useEffect} from 'react'

import {getResponsiveBreakpoint} from './../../helpers/responsive-helper'
import type {ResponsiveBreakpointKey} from './../../helpers/responsive-helper'
import {useWindowSize} from '../use-window-size'

export function useResponsiveInfo(): ResponsiveBreakpointKey {
  const {width} = useWindowSize()
  const responsiveBreakpoint = getResponsiveBreakpoint(width)
  const [breakpoint, setBreakpoint] = useState<ResponsiveBreakpointKey>(responsiveBreakpoint)
  useEffect(() => setBreakpoint(responsiveBreakpoint), [responsiveBreakpoint])

  return breakpoint
}
