import {
  Configuration,
  DefaultApi,
  type RequestContext,
  type ResponseContext,
  type FetchParams,
  FetchError as FancyApiFetchError,
  ResponseError
} from '@freckle/fancy-api'
import {PATHS} from '@freckle/student-materials/src/helpers/paths'
import {
  EchoError,
  addXEchoHeader,
  checkStudentCookie
} from '@freckle/student-entities/ts/common/helpers/api-helper'

const config = (onEchoError: (echoError: EchoError) => void) =>
  new Configuration({
    basePath: PATHS.unversionedAPIUrl,
    credentials: 'include', // c.f. XMLHttpRequest.withCredentials = true
    middleware: [
      {
        pre: async (context: RequestContext): Promise<FetchParams> => {
          const {url, init} = context
          if (init.method !== 'OPTIONS' && url.indexOf(PATHS.unversionedAPIUrl) !== -1) {
            return {
              url: addXEchoHeader(context.url),
              init
            }
          }
          return {url, init}
        },
        post: async (context: ResponseContext): Promise<Response | void> => {
          const {response, url: urlString} = context
          const url = new URL(urlString)
          const echo = url.searchParams.get('x-echo')
          if (echo !== null && echo !== undefined) {
            const respEcho = response.headers.get('X-Echo')
            if (respEcho && echo !== respEcho) {
              onEchoError(
                new EchoError(
                  `X-Echo expected "${echo}", but found "${respEcho}"`,
                  response.headers.toString()
                )
              )
            }
          }
        }
      }
    ]
  })

export let api: DefaultApi
export const initializeApi = (onEchoError: (echoError: EchoError) => void) => {
  api = new DefaultApi(config(onEchoError))
}

export const fetch = async <ApiType, DomainType>(
  route: (initOverrides?: RequestInit) => Promise<ApiType>,
  mapperFn: (a: ApiType) => DomainType,
  body?: unknown
): Promise<DomainType> => {
  // Caught error will have new error stack, so preserve the current stack
  const preservedStack = new Error().stack
    ?.split('\n')
    .slice(2) // Remove first 2 lines, which is a generic "Error" and this fetch function
    .join('\n')
  let url = 'Unknown URL'
  try {
    const res = await route.bind(
      //Add some middleware to get the url for logging purposes
      api.withPreMiddleware(async (context: RequestContext): Promise<FetchParams> => {
        url = context.url
        return {url: context.url, init: context.init}
      })
    )(
      body === undefined
        ? undefined
        : {
            body: JSON.stringify(body),
            headers: {'Content-Type': 'application/json; charset=UTF-8'}
          }
    )
    return mapperFn(res)
  } catch (err) {
    if (err instanceof ResponseError) {
      if (err.response.status === 403) {
        try {
          await checkStudentCookie()
        } catch (e) {
          // student session expired due to inactivity
          const msg = `You have been disconnected from Freckle. Please login again.`
          alert(msg)
          // appease typescript
          // returning a promise that never resolves because we are reloading
          document.location.reload()
          return new Promise(() => {})
        }
      }
      const apiClientHttpError = new ApiClientHttpError(err, url, {
        response: err.response,
        text: await err.response.text()
      })
      apiClientHttpError.stack = `${preservedStack}\n${apiClientHttpError.stack}`
      throw apiClientHttpError
    } else if (err instanceof FancyApiFetchError) {
      // Wrap the underlying error of the FancyApiFetchError in our own FetchError to record the url
      const fetchErr = new FetchError(err.cause, url)
      fetchErr.stack = `${preservedStack}\n${fetchErr.stack}`
      throw fetchErr
    } else {
      throw err
    }
  }
}

// Gets the url from the request
//
// You may expect this function to return a plain string instead of a Promise, but
// unfortunately the generated api has no functionality to return the url.
//
// This function adds some local middleware that stores the url and then aborts the
// request prior to sending it. This means that we must "send" the async request which
// forces this function to return Promise<string>
//
export const getUrl = async (route: (initOverrides?: RequestInit) => unknown): Promise<string> => {
  let url
  try {
    console.log('Adding getUrl middleware and initiating request')
    await route.call(
      api.withPreMiddleware(async (context: RequestContext): Promise<FetchParams> => {
        console.log('Began getUrl middleware')
        const controller = new AbortController()
        controller.abort()
        context.init.signal = controller.signal
        url = context.url
        console.log(`Set url in getUrl middleware: ${url}`)
        return {url: context.url, init: context.init}
      })
    )
  } catch (err) {
    // Aborting the request causes an error to be thrown, so just catch and ignore it.
    console.log(`Error from getUrl aborted request: ${err}`)
  }

  if (url) {
    return url
  } else {
    throw new Error('url should have been set by local middleware')
  }
}

export class ApiClientHttpError extends Error {
  constructor(
    public cause: unknown,
    public url: string,
    public response: {response: Response; text: string}
  ) {
    super(`Unexpected HTTP error for ${url}: ${response.response.status} - ${response.text}`)
    this.name = 'ApiClientHttpError'
  }
}

// Used when something went wrong with the underlying fetch
// This can happen when the browser refuses to make the request for various reasons like ad-blockers
// Here is a list of other potential reasons: https://developer.mozilla.org/en-US/docs/Web/API/fetch#exceptions
export class FetchError extends Error {
  constructor(public cause: unknown, public url: string) {
    super(`Failed to fetch ${url}`)
    this.name = 'FetchError'
  }
}
