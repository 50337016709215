import find from 'lodash/find'
import {type ParserT, stringEnum} from '@freckle/parser'

export type IdentityProviderT = 'clever' | 'csv' | 'class-link' | 'renaissance' | 'class-link-raw'
const IdentityProvider = {
  all: () => [
    IdentityProvider.Clever,
    IdentityProvider.CSV,
    IdentityProvider.ClassLink,
    IdentityProvider.Renaissance
  ],
  Clever: 'clever' as IdentityProviderT,
  CSV: 'csv' as IdentityProviderT,
  ClassLink: 'class-link' as IdentityProviderT,
  Renaissance: 'renaissance' as IdentityProviderT
}

type IdpEntity = Inexact<{
  identityProvider: IdentityProviderT | undefined | null
  idpManaged: boolean
}>

export const isIdpManaged = (entity: IdpEntity) => entity.idpManaged === true

export const parse = (s: string): IdentityProviderT | undefined | null =>
  find(IdentityProvider.all(), role => s === role) || null

export const parser: ParserT<IdentityProviderT | undefined | null> = stringEnum(
  'IdentityProviderT',
  parse
)
