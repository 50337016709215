import {exhaustive} from '@freckle/exhaustive'

type GroupNameT = 'EarlyLearner' | 'MidGrader' | 'UpperGrader'

const EARLY_LEARNER_GRADES = [0, 1, 2]
const MID_GRADER_GRADES = [3, 4, 5]
const HIGH_SCHOOL_GRADES = [9, 10, 11, 12]
const UPPER_GRADER_GRADES = [6, 7, 8, ...HIGH_SCHOOL_GRADES]

export function isEarlyLearner(grade: number): boolean {
  return EARLY_LEARNER_GRADES.includes(grade)
}

export function isMidGrader(grade: number): boolean {
  return MID_GRADER_GRADES.includes(grade)
}

export function isUpperGrader(grade: number): boolean {
  return UPPER_GRADER_GRADES.includes(grade)
}

export function isHighSchoolGrader(grade: number): boolean {
  return HIGH_SCHOOL_GRADES.includes(grade)
}

export function isInElementarySchool(grade: number): boolean {
  return grade < 6
}

export function gradeToGroupName(grade: number): GroupNameT {
  if (isEarlyLearner(grade)) {
    return 'EarlyLearner'
  } else if (isMidGrader(grade)) {
    return 'MidGrader'
  } else if (isUpperGrader(grade)) {
    return 'UpperGrader'
  } else {
    throw new Error(`gradeToGroup failed with invalid grade: ${grade}`)
  }
}

type GroupName = 'early-learner' | 'mid-grader' | 'upper-grader'
export function getGroupNameString(grade: number): GroupName {
  const groupName = gradeToGroupName(grade)
  switch (groupName) {
    case 'EarlyLearner':
      return 'early-learner'
    case 'MidGrader':
      return 'mid-grader'
    case 'UpperGrader':
      return 'upper-grader'
    default:
      return exhaustive(groupName, 'GroupNameT')
  }
}
