import find from 'lodash/find'

export type ReadAloudSupportT = 'default' | 'always' | 'never'

export const ReadAloudSupports = {
  Default: 'default' as ReadAloudSupportT,
  Always: 'always' as ReadAloudSupportT,
  Never: 'never' as ReadAloudSupportT,

  all(): Array<ReadAloudSupportT> {
    return [ReadAloudSupports.Default, ReadAloudSupports.Always, ReadAloudSupports.Never]
  },

  parse(text: string): ReadAloudSupportT | undefined | null {
    return find(ReadAloudSupports.all(), value => value === text)
  }
}
