import {type Moment} from 'moment-timezone'
import {type NonEmptyArray} from '@freckle/non-empty'
import {
  type ParserT,
  date,
  array,
  number,
  string,
  nullable,
  record,
  nonEmptyArray,
  tag,
  boolean,
  oneOf
} from '@freckle/parser'
import {type RlDomainT} from '@freckle/student-entities/ts/common/models/rl-domain'
import {parseDomainAttrs} from '@freckle/student-entities/ts/common/models/rl-domain'
import {type RlSkillT} from '@freckle/student-entities/ts/common/types/rl-skill'
import {parseRlSkillAttrs} from '@freckle/student-entities/ts/common/models/rl-skill'
import {
  type RlStandardT,
  type RlStandardWithDomainT,
  parseStandardWithDomainAttrs
} from '@freckle/student-entities/ts/common/models/rl-standard'
import {parseStandardAttrs} from '@freckle/student-entities/ts/common/models/rl-standard'
import {type MathFactPracticeOperationTypeT} from '@freckle/student-entities/ts/math/fact-practice/models/math-fact-practice-operation'
import {mathFactPracticeOperationTypeParser} from '@freckle/student-entities/ts/math/fact-practice/models/math-fact-practice-operation'
import {type MathAssessmentTopicT} from '@freckle/student-entities/ts/math/assessment/types/assessment-topic'
import * as MathAssessmentTopic from '@freckle/student-entities/ts/math/assessment/types/assessment-topic'
import {parseFactPracticeSnapshot} from '@freckle/student-entities/ts/math/fact-practice/models/math-fact-practice-snapshot'
import {type FactPracticeSnapshotAttrs} from '@freckle/student-entities/ts/math/fact-practice/models/math-fact-practice-snapshot'

import {type GenericAssignmentSessionMetadataT} from '.'

/**
 * Math Adaptive
 */

export type MathAdaptiveMetadataT = {
  domain: RlDomainT
  currentStandard: RlStandardT | undefined | null
  startsAt: Moment | undefined | null
}

export const parseMathAdaptiveMetadata: ParserT<GenericAssignmentSessionMetadataT> = record({
  tag: tag('math_adaptive'),
  contents: record({
    startsAt: nullable(date()),
    domain: parseDomainAttrs,
    currentStandard: nullable(parseStandardAttrs)
  }) as ParserT<MathAdaptiveMetadataT>
})

/**
 * Math Targeted
 */

export type MathTargetedAssignmentTypeT = 'practice' | 'prerequisite'

type MathTargetedAssignmentTypes = {
  Practice: MathTargetedAssignmentTypeT
  Prerequisite: MathTargetedAssignmentTypeT
}

export const MathTargetedAssignmentTypes: MathTargetedAssignmentTypes = {
  Practice: 'practice',
  Prerequisite: 'prerequisite'
}

export type MathTargetedMetadataT = {
  standard: RlStandardWithDomainT
  skill: RlSkillT | undefined | null
  startsAt: Moment | undefined | null
  originalAssignmentId: number | undefined | null
  numQuestionsAnswered: number | undefined | null
  totalWorth: number | undefined | null
  completedWorth: number | undefined | null
  isSupported: boolean | undefined | null
  numQuestions: number
  type: MathTargetedAssignmentTypeT
}

export const parseMathTargetedSessionType: ParserT<MathTargetedAssignmentTypeT> = oneOf(
  `MathTargetedAssignmentTypeT`,
  ['practice', 'prerequisite']
)

export const parseMathTargetedMetadata: ParserT<GenericAssignmentSessionMetadataT> = record({
  tag: tag('math_targeted'),
  contents: record({
    standard: parseStandardWithDomainAttrs,
    skill: nullable(parseRlSkillAttrs),
    startsAt: nullable(date()),
    originalAssignmentId: nullable(number()),
    numQuestionsAnswered: nullable(number()),
    totalWorth: nullable(number()),
    completedWorth: nullable(number()),
    isSupported: nullable(boolean()),
    numQuestions: number(),
    type: parseMathTargetedSessionType
  }) as ParserT<MathTargetedMetadataT>
})

/**
 * Math Assessments
 */

export type MathAssessmentMetadataT = {
  topic: MathAssessmentTopicT
  startsAt: Moment | undefined | null
}

const parseMathAssessmentsContents: ParserT<MathAssessmentMetadataT> = record({
  topic: MathAssessmentTopic.parse,
  startsAt: nullable(date())
})

export const parseMathAssessmentsMetadata: ParserT<GenericAssignmentSessionMetadataT> = record({
  tag: tag('math_assessments'),
  contents: parseMathAssessmentsContents
})

/**
 * Math Ibls
 */

export type MathIblMetadataT = {
  lessonId: number
  day: number
  title: string
  standard: RlStandardWithDomainT
}

export const parseMathIblsMetadata: ParserT<GenericAssignmentSessionMetadataT> = record({
  tag: tag('math_ibls'),
  contents: record({
    lessonId: number(),
    day: number(),
    title: string(),
    standard: parseStandardWithDomainAttrs
  }) as ParserT<MathIblMetadataT>
})

/**
 * Math Number Facts
 */

export const parseMathNumberFactsMetadata = record({tag: tag('math_number_facts')})

/**
 * Math Number Basics
 */

export type MathNumberBasicsMetadataT = {
  skillCode: string
}

export const parseMathNumberBasicsMetadata: ParserT<GenericAssignmentSessionMetadataT> = record({
  tag: tag('math_number_basics'),
  contents: record({skillCode: string()}) as ParserT<MathNumberBasicsMetadataT>
})

/**
 * Math Fact Practice
 */

type MathFactPracticeOperationSnapshotMetadataT = {
  operation: MathFactPracticeOperationTypeT
  snapshot: FactPracticeSnapshotAttrs | undefined | null
}

export type MathFactPracticeMetadataT = {
  operations: NonEmptyArray<MathFactPracticeOperationTypeT>
  snapshots: Array<MathFactPracticeOperationSnapshotMetadataT>
  startsAt: Moment | undefined | null
}

export const parseMathFactPracticeMetadata: ParserT<GenericAssignmentSessionMetadataT> = record({
  tag: tag('math_fact_practice'),
  contents: record({
    operations: nonEmptyArray(mathFactPracticeOperationTypeParser),
    snapshots: array(
      record({
        operation: mathFactPracticeOperationTypeParser,
        snapshot: nullable(parseFactPracticeSnapshot)
      })
    ),
    startsAt: nullable(date())
  }) as ParserT<MathFactPracticeMetadataT>
})

/**
 * Math Depth of Knowledge
 */

export type MathDepthOfKnowledgeMetadataT = {
  questionSetUuid: string
}

export const parseMathDepthOfKnowledgeMetadata: ParserT<GenericAssignmentSessionMetadataT> = record(
  {
    tag: tag('math_depth_of_knowledge_practice'),
    contents: record({questionSetUuid: string()}) as ParserT<MathDepthOfKnowledgeMetadataT>
  }
)
/**
 * Math Targeted Depth of Knowledge
 */

export type MathTargetedDepthOfKnowledgeMetadataT = {
  questionSetUuid: string
  standard: RlStandardWithDomainT
}

export const parseMathTargetedDepthOfKnowledgeMetadata: ParserT<GenericAssignmentSessionMetadataT> =
  record({
    tag: tag('math_targeted_depth_of_knowledge_practice'),
    contents: record({
      questionSetUuid: string(),
      standard: parseStandardWithDomainAttrs
    }) as ParserT<MathTargetedDepthOfKnowledgeMetadataT>
  })
