import * as React from 'react'
import {type SyntheticEvent} from 'react'
import {Button as AriakitButton} from '@ariakit/react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {type IconProp} from '@fortawesome/fontawesome-svg-core'
import {maybe} from '@freckle/maybe'
import {exhaustive} from '@freckle/exhaustive'
import {addIfClassNames} from '@freckle/student-materials/src/helpers/classnames'

import {
  leftIconContainer,
  rightIconContainer,
  button,
  fullWidth as fullWidthClass,
  noWrap as noWrapClass,
  selectedStyle,
  rounded as roundedClass,
  primary,
  secondary,
  tertiary,
  danger,
  warning,
  success,
  incorrect,
  link,
  sm,
  md,
  lg,
  xlg
} from './button.module.scss'

export type ButtonStyleT =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'danger'
  | 'warning'
  | 'success'
  | 'incorrect'
  | 'link'

export type ButtonSizeT = 'sm' | 'md' | 'lg' | 'xlg'
export type ButtonTypeT = 'button' | 'reset' | 'submit'

export type Props = {
  /** `sm` or `md` or `lg or `xlg` */
  size?: ButtonSizeT
  /** Action of the button */
  onClick?: ((e: SyntheticEvent<Element, Event>) => void) | null
  onKeyDown?: ((e: React.KeyboardEvent<HTMLElement>) => void) | null
  /** Content of the button */
  children?: React.ReactNode
  /** Accessibility information for symbolic buttons **/
  'aria-label'?: string
  'aria-haspopup'?:
    | boolean
    | 'dialog'
    | 'menu'
    | 'true'
    | 'grid'
    | 'listbox'
    | 'tree'
    | 'false'
    | undefined
  'aria-expanded'?: boolean
  'aria-controls'?: string
  'aria-checked'?: boolean | 'true' | 'false' | 'mixed' | undefined
  'aria-pressed'?: boolean | 'true' | 'false' | 'mixed' | undefined
  'aria-labelledby'?: string | null
  'aria-describedby'?: string | null
  role?: React.AriaRole
  /** Additional CSS Class to add to the button */
  addClass?: string | null
  /** Define the style of the Button */
  style?: ButtonStyleT
  fullWidth?: boolean
  noWrap?: boolean
  disabled?: boolean
  tabIndex?: number
  /** Defines HTML button type attribute in `button`, `reset`, `submit` */
  type?: ButtonTypeT
  dataTest?: string
  dataPendo?: string
  autoFocus?: boolean
  leftIcon?: IconProp
  rightIcon?: IconProp
  selected?: boolean
  rounded?: boolean
}

/**
 * This is the standard Button.
 *
 * It contains the most used attribute of the regular HTML `<button>` tag.
 *
 */

const Button = React.forwardRef<HTMLButtonElement, Props>(
  ({style = 'primary', size = 'md', disabled = false, type = 'button', ...restProps}, ref) => {
    const {
      onClick,
      onKeyDown,
      children,
      dataTest,
      dataPendo,
      tabIndex,
      autoFocus,
      role,
      leftIcon,
      rightIcon,
      addClass,
      fullWidth,
      noWrap,
      selected,
      rounded
    } = restProps

    const className = getClassName({
      addClass,
      fullWidth,
      noWrap,
      style,
      size,
      selected,
      rounded
    })

    const handleClick = disabled === true ? preventDefault : onClick
    const handleKeyDown = disabled === true ? preventDefault : onKeyDown

    const leftIconNode =
      leftIcon !== null && leftIcon !== undefined ? (
        <span className={leftIconContainer}>
          <FontAwesomeIcon icon={leftIcon} aria-hidden="true" />
        </span>
      ) : null

    const rightIconNode =
      rightIcon !== null && rightIcon !== undefined ? (
        <span className={rightIconContainer}>
          <FontAwesomeIcon icon={rightIcon} aria-hidden="true" />
        </span>
      ) : null

    const mAriaExpanded = maybe(
      () => ({}),
      a => ({'aria-expanded': a}),
      restProps['aria-expanded']
    )
    const mAriaDescribedBy = maybe(
      () => ({}),
      a => ({'aria-describedby': a}),
      restProps['aria-describedby']
    )
    const mAriaLabelledBy = maybe(
      () => ({}),
      a => ({'aria-labelledby': a}),
      restProps['aria-labelledby']
    )

    return (
      <AriakitButton
        ref={ref}
        type={type}
        className={className}
        data-test={dataTest}
        data-pendo={dataPendo}
        aria-label={restProps['aria-label']}
        aria-haspopup={restProps['aria-haspopup']}
        aria-controls={restProps['aria-controls']}
        aria-checked={restProps['aria-checked']}
        aria-pressed={restProps['aria-pressed']}
        tabIndex={tabIndex}
        role={role}
        disabled={disabled}
        autoFocus={autoFocus}
        {...maybe(
          () => ({}),
          handler => ({onClick: handler}),
          handleClick
        )}
        {...maybe(
          () => ({}),
          handler => ({onKeyDown: handler}),
          handleKeyDown
        )}
        {...mAriaExpanded}
        {...mAriaDescribedBy}
        {...mAriaLabelledBy}
      >
        {leftIconNode}
        {children}
        {rightIconNode}
      </AriakitButton>
    )
  }
)
Button.displayName = 'Button'

export default Button

function preventDefault(e: SyntheticEvent<Element, Event>) {
  e.preventDefault()
}

function getClassName(props: Props): string {
  const {addClass, fullWidth, noWrap, style, size, selected, rounded} = props

  return addIfClassNames([
    [true, `${button} ${getStyleClass(style)}`],
    [style !== 'link', getSizeClass(size)],
    [fullWidth, fullWidthClass],
    [noWrap, noWrapClass],
    [selected, selectedStyle],
    [rounded, roundedClass],
    [addClass !== null && addClass !== undefined, addClass]
  ])
}
function getSizeClass(size?: ButtonSizeT | null): string {
  switch (size) {
    case null:
    case undefined:
      return md
    case 'sm':
      return sm
    case 'md':
      return md
    case 'lg':
      return lg
    case 'xlg':
      return xlg
    default:
      return exhaustive(size)
  }
}
function getStyleClass(style?: ButtonStyleT | null): string {
  switch (style) {
    case 'primary':
      return primary
    case 'secondary':
      return secondary
    case 'tertiary':
      return tertiary
    case 'danger':
      return danger
    case 'warning':
      return warning
    case 'success':
      return success
    case 'incorrect':
      return incorrect
    case 'link':
      return link
    case null:
    case undefined:
      return primary

    default:
      return exhaustive(style, 'ButtonStyleT')
  }
}
