import * as React from 'react'
import {useSelector} from 'react-redux'
import {type StoreState} from '@freckle/student/ts/reducers/types'
import {type AllActionT} from '@freckle/student/ts/reducers'
import {useAppDispatch} from '@freckle/student/ts/store'
import {type ResourceStatusT} from '@freckle/resource-status'
import type {ThunkAction} from 'redux-thunk'

// This hook sets up a base model by calling the load function if the model is idle
// Its intended use is for creation of model specific hooks, eg:
//
// const useSelfStudent = useBaseModel(state => state.selfStudent, loadSelfStudent)
//
export function useBaseModel<M>(
  selector: (a: StoreState) => ResourceStatusT<M>,
  loader: () => ThunkAction<void, StoreState, unknown, AllActionT>
): ResourceStatusT<M> {
  const dispatch = useAppDispatch()
  const model: ResourceStatusT<M> = useSelector(selector)
  React.useEffect(() => {
    if (model.status === 'idle') {
      dispatch(loader())
    }
  }, [model.status, dispatch, loader])

  return model
}
