import find from 'lodash/find'
import {fromJust} from '@freckle/maybe'
import {exhaustive} from '@freckle/exhaustive'

export type RosterSourceT = 'upload' | 'idp_sync' | 'manual'

export const RosterSources = {
  upload: 'upload' as RosterSourceT,
  idpSync: 'idp_sync' as RosterSourceT,
  manual: 'manual' as RosterSourceT,

  all(): Array<RosterSourceT> {
    return [RosterSources.upload, RosterSources.idpSync, RosterSources.manual]
  },

  parse(text: string): RosterSourceT | undefined | null {
    return find(RosterSources.all(), value => value === text)
  },

  parseThrows(text: string): RosterSourceT {
    return fromJust(RosterSources.parse(text), `Invalid student source ${text}`)
  },

  forDisplay(rosterSource: RosterSourceT): string {
    switch (rosterSource) {
      case 'upload':
        return 'Upload'
      case 'idp_sync':
        return 'IDP Sync'
      case 'manual':
        return 'Manual'
      default:
        return exhaustive(rosterSource, 'RosterSourceT')
    }
  }
}
