import {isFetching} from '@freckle/resource-status'
import {type ItemT, fetchItems} from '@freckle/student-entities/ts/common/models/item'
import type {ThunkAction} from 'redux-thunk'
import {type StoreState} from '@freckle/student/ts/reducers/types'

export type ItemsActionT = ItemsGetRequestActionT | ItemsGetResponseActionT | ItemsGetErrorActionT

type ItemsGetRequestActionT = {
  type: 'ITEMS_GET_REQUEST'
}

function itemsGetRequestAction(): ItemsGetRequestActionT {
  return {type: 'ITEMS_GET_REQUEST'}
}

type ItemsGetResponseActionT = {
  type: 'ITEMS_GET_RESPONSE'
  items: ItemT[]
}

function itemsGetResponseAction(items: ItemT[]): ItemsGetResponseActionT {
  return {
    type: 'ITEMS_GET_RESPONSE',
    items
  }
}

type ItemsGetErrorActionT = {
  type: 'ITEMS_GET_ERROR'
  error: unknown
}

function itemsGetErrorAction(error: unknown): ItemsGetErrorActionT {
  return {type: 'ITEMS_GET_ERROR', error}
}

export function loadItems(): ThunkAction<void, StoreState, unknown, ItemsActionT> {
  return async (dispatch, getState) => {
    const {items} = getState()
    if (isFetching(items)) {
      return
    }

    dispatch(itemsGetRequestAction())
    try {
      const items = await fetchItems()
      dispatch(itemsGetResponseAction(items))
    } catch (error) {
      dispatch(itemsGetErrorAction(error))
    }
  }
}
