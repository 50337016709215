import * as React from 'react'
import {fromMaybe} from '@freckle/maybe'
import {LogAndRedirectUnauthed} from '@freckle/student/ts/common/helpers/exception-handlers'
import * as SharedWithResources from '@freckle/student-entities/ts/common/components/with-resources'
import DelayedSpinner from '@freckle/student-entities/ts/common/components/spinner-wrapper/delayed-spinner'
import {type ResourceStatusT} from '@freckle/resource-status'

type CustomErrorCallback = (error: Error) => void

const withResourcesProps = (
  onError: CustomErrorCallback,
  mOnInitialLoading?: (() => React.ReactElement) | null,
  mOnReloading?: (() => React.ReactElement) | null
) => ({
  error: function Error(error: Error): React.ReactElement {
    onError(error)
    return <LogAndRedirectUnauthed error={error} />
  },
  initialLoading: fromMaybe(
    () =>
      function Loading(): React.ReactElement {
        return <DelayedSpinner inline />
      },
    mOnInitialLoading
  ),
  reloading: fromMaybe(
    () =>
      function Loading(): React.ReactElement {
        return <DelayedSpinner />
      },
    mOnReloading
  )
})

export function WithResources<T extends {[key: string]: unknown}>(props: {
  render: (props: {data: {[Property in keyof T]: T[Property]}}) => React.ReactElement | null
  resources: {[Property in keyof T]: ResourceStatusT<T[Property]>}
  onError?: CustomErrorCallback
  onInitialLoading?: () => React.ReactElement
  onReloading?: () => React.ReactElement
}): React.ReactElement {
  const {
    onError: mOnError,
    onInitialLoading: mOnInitialLoading,
    onReloading: mOnReloading,
    ...restProps
  } = props

  const onError = fromMaybe(() => (_err: Error) => {}, mOnError)
  return (
    <SharedWithResources.WithResources
      {...restProps}
      {...withResourcesProps(onError, mOnInitialLoading, mOnReloading)}
    />
  )
}
