import min from 'lodash/min'
import isEmpty from 'lodash/isEmpty'
import {type ItemT} from '@freckle/student-entities/ts/common/models/item'
import {
  type ItemOwnershipT,
  getAvatarOwnership
} from '@freckle/student/ts/common/models/item-ownership'
import {fromJust} from '@freckle/maybe'
import {toast, Slide, type ToastOptions, type TypeOptions} from 'react-toastify'

export default {
  displayNotification: function (
    type: TypeOptions,
    message: string,
    waitSeconds?: number | undefined | null,
    callback?: (() => void) | undefined | null
  ) {
    const options: ToastOptions = {
      type,
      transition: Slide,
      position: 'top-right',
      autoClose: (waitSeconds ?? 2) * 1000,
      ...(callback ? {onClose: callback} : {})
    }

    toast(message, options)
  },

  calcPercentage: function (value: number, max: number): number {
    return fromJust(min([Math.round((value * 100) / max), 100]), 'Nonempty array should have min')
  },

  hasRevealedInterestInStore: function (
    itemOwnerships: Array<ItemOwnershipT>,
    items: Array<ItemT>
  ): boolean {
    return !isEmpty(getAvatarOwnership(itemOwnerships, items))
  },

  showConnectionIssueOverlay: function () {
    this.displayNotification('error', `Experiencing connection issues, retrying`, 2)
  },

  showOverlay: function () {
    // classList is not supported by IE < 10
    const retryLayerNode = document.getElementById('retry-layer')
    if (retryLayerNode) {
      retryLayerNode.classList.remove('hidden')
    }

    const frontrowappNode = document.getElementById('freckleapp')
    if (frontrowappNode) {
      frontrowappNode.classList.add('blurred')
    }
  },

  hideOverlay: function () {
    // classList is not supported by IE < 10
    const retryLayerNode = document.getElementById('retry-layer')
    if (retryLayerNode) {
      retryLayerNode.classList.add('hidden')
    }
    const frontrowappNode = document.getElementById('freckleapp')
    if (frontrowappNode) {
      frontrowappNode.classList.remove('blurred')
    }
  }
}
